import React, { useEffect, useState } from "react";
import DashboardContainer from "../../components/dashboard-container";
import OrderList from "../../components/order-management-list";
import { orderService } from "../../utils/services/orderService";
import { exportToCsv } from "../../utils/exportToCsv";
import { TablePagination } from "@mui/material";
import dayjs from "dayjs"; // Ensure dayjs is imported
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const OrderManagement = () => {
   const [clientFilter, setClientFilter] = useState(null);
  const changeDateFormat = (data) => {
    const dateStr = data;
    const date = new Date(dateStr);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([
    changeDateFormat(new Date(new Date().setMonth(new Date().getMonth() - 2))),
    changeDateFormat(new Date(new Date().setDate(new Date().getDate() + 2))),
  ]);
  const [orderData, setOrderData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const clientId = localStorage.getItem("client_id");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const fetchOrderData = async () => {
    setLoading(true);
    try {
      const skip = page * rowsPerPage;
      const [startDate, endDate] = dateRange || [];
      const response = await orderService.fetch(
        rowsPerPage,
        skip,
        clientId ? clientId : clientFilter?._id,
        searchValue,
        statusFilter,
        startDate,
        endDate
      );
      setDataCount(response.total);
      setOrderData(response.data);
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderData();
  }, [page, rowsPerPage, searchValue, dateRange, statusFilter,clientFilter]);

  const handleExport = () => {
    exportToCsv(orderData);
  };

  return (
    <DashboardContainer
      hideUploadButton={true}
      // hideFilterClient={true}
      hideAddButton={true}
      order
      header={"Order Management System"}
      onSelectDate={(x) =>
        setDateRange([
          changeDateFormat(x.startDate),
          changeDateFormat(x.endDate),
        ])
      }
      onSearchData={setSearchValue}
      onClickExport={handleExport}
      onClickRefresh={fetchOrderData}
      onSelectClient={(e) => {
        setClientFilter(e);
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <OrderList
          data={orderData}
          onStatusFilterChange={setStatusFilter}
          fetchOrderData={fetchOrderData}
        />
      )}

      <TablePagination
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#ffffff",
          padding: "2px",
          borderTop: "1px solid #dddddd",
        }}
        rowsPerPageOptions={[15, 25, 45, 75, 100]}
        component="div"
        count={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </DashboardContainer>
  );
};

export default OrderManagement;

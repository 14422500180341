import React, { useContext, useEffect, useState } from 'react'
import styles from './master-setup-client.module.css'
import FormField from './form-filed'
import { ToastContainer, toast } from 'react-toastify'
import { clientService } from '../../utils/services/clientService'
import { useNavigate } from 'react-router-dom'
import AppContext from '../../components/context/context'
import { forgotPasswordService } from '../../utils/services/loginService.js'

function OnBoardLogin() {
  const { isAuthenticated, setIsAuthenticated, setUser } = useContext(AppContext)
  const [password, setPassword] = useState({
    newPassword: '',
    confirmPassword: ''
  })
  const [email, setEmail] = useState('')
  const [otp, setOtp] = useState('')
  const [isOtpSent, setIsOtpSent] = useState(false)
  const [isOtpVerified, setIsOtpVerified] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const currentUrl = window.location.href
  // useEffect(() => {
  //   if (!JSON.parse(localStorage.getItem('user')).password_generation_pending) {
  //     navigate('/account-setting')
  //   }
  // }, [])
  useEffect(() => {
    const urlObj = new URL(currentUrl)
    const emailParam = urlObj.searchParams.get('email')
    setEmail(emailParam)
  }, [currentUrl])

  const handleSendOtp = async () => {
    try {
      setLoading(true)
      const response = await forgotPasswordService.sendOTP(email, true)
      if (response) {
        toast.success('OTP sent to your email!')
        setIsOtpSent(true)
      }
    } catch (error) {
      console.error(error)
      toast.error('Error sending OTP')
    } finally {
      setLoading(false)
    }
  }

  const handleVerifyOtp = async () => {
    try {
      setLoading(true)
      const response = await forgotPasswordService.validateOTP(email, otp)
      if (response) {
        toast.success('OTP verified successfully!')
        setIsOtpVerified(true)
      }
    } catch (error) {
      console.error(error)
      toast.error('Invalid OTP')
    } finally {
      setLoading(false)
    }
  }

  const onSave = async () => {
    if (password.newPassword !== password.confirmPassword) {
      return toast.warning('New password and Confirm Password do not match')
    }
    if (!isOtpVerified) {
      return toast.warning('Please verify your email first')
    }
    try {
      let response = await clientService.createPassowrd({
        email: email,
        password: password.newPassword,
        newPassword: password.newPassword
      })
      if (response) {
        localStorage.setItem('token', response.accessToken)
        localStorage.setItem('user', JSON.stringify(response.user))
        localStorage.setItem('client_id', response.client?._id)
        setUser(response.user)
        toast.success('Logged in successfully')
        localStorage.setItem('path', `/account-setting`)
        setIsAuthenticated(true)
        navigate('/account-setting')
      }
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  return (
    <main className={styles.masterSetupClient}>
      <div className={styles.container}>
        <div className={styles.contentWrapper}>
          <section className={styles.imageColumn}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/1cc701ad00b38582e5959ed3182bc3f944414263b24da9c106a4f99f405cc826?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
              alt="Welcome illustration"
              className={styles.mainImage}
            />
          </section>
          <section className={styles.formColumn}>
            <div className={styles.formWrapper}>
              <h1 className={styles.welcomeTitle}>Welcome aboard</h1>
              <p className={styles.instructionText}>
                Let's verify your email and set up personalized password for secured access to your own
                DropSkip portal.
              </p>
              <form>
                <FormField
                  textStyle={{ color: 'red', cursor: 'pointer' }}
                  text={isOtpVerified ? '' : isOtpSent ? 'Resend OTP' : 'Verify'}
                  onClickVerify={handleSendOtp}
                  disabled={loading}
                  value={email}
                  label="Email ID"
                  type="email"
                  placeholder="Enter Email ID"
                />

                {isOtpSent && (
                  <FormField
                    onChange={(e) => setOtp(e)}
                    textStyle={{ color: 'green', cursor: 'pointer' }}
                    text={isOtpVerified ? '' : 'Verify OTP'}
                    onClickVerify={handleVerifyOtp}
                    value={otp}
                    label="OTP"
                    type="phone"
                    placeholder="Enter OTP"
                    disabled={isOtpVerified}
                  />
                )}

                <FormField
                  onChange={(e) => setPassword({ ...password, newPassword: e })}
                  label="Password"
                  type="password"
                  placeholder="Enter password"
                  icon
                  disabled={!isOtpVerified}
                  info={!isOtpVerified && 'Please verify email first'}
                />
                <FormField
                  onChange={(e) => setPassword({ ...password, confirmPassword: e })}
                  label="Confirm Password"
                  type="password"
                  placeholder="Enter confirm password"
                  icon
                  disabled={!isOtpVerified}
                  info={!isOtpVerified && 'Please verify email first'}
                />
              </form>
              <div onClick={onSave} className={styles.loginButton}>
                Login
              </div>
            </div>
          </section>
        </div>
        <ToastContainer />
      </div>
    </main>
  )
}

export default OnBoardLogin

import React, { useEffect, useState, useContext, useCallback } from 'react'
import ServiceList from '../../components/service-management-list'
import DashboardContainer from '../../components/dashboard-container'
import AddModal from '../../components/add-modal'
import { carrierServiceManagement } from '../../utils/services/serviceManagement'
import { carrierService } from '../../utils/services/carrierService'
import TablePagination from '@mui/material/TablePagination'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { Autocomplete, Container, IconButton, TextField, Typography } from '@mui/material'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import AppContext from '../../components/context/context'
import { clientService } from '../../utils/services/clientService'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import debounce from 'lodash.debounce'

const ServiceManagement = () => {
  const [showAddModal, setShowAddModal] = useState(false)
  const [name, setName] = useState('')
  const [serviceData, setServiceData] = useState([])
  const [selectedService, setSelectedService] = useState(null)
  const [selectedCarrierService, setSelectedCarrierService] = useState({
    name: ''
  })
  const [carrierList, setCarrierList] = useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(15)
  const [isLoading, setIsLoading] = useState(false)
  const [dataCount, setDataCount] = useState(rowsPerPage)
  const [formErrors, setFormErrors] = useState({})
  const [selectedClient, setSelectedClient] = useState({
    name: ''
  })
  const [searchValue, setSearchValue] = useState('')
  const [clientData, setClientData] = useState([])
  const [clientFilter, setClientFilter] = useState(null)
  const [viewMode, setViewMode] = useState('list')
  const [loading, setLoading] = useState(false)
  const clientId = localStorage.getItem('client_id')
  const { user } = useContext(AppContext)
  const [carrierSearchValue, setCarrierSearchValue] = useState('')
  const [carrierPage, setCarrierPage] = useState(0)
  const [total, setTotal] = useState(0)

  const validateForm = () => {
    const errors = {}
    if (!name) {
      errors.name = 'Service name is required'
    }
    if (!selectedCarrierService?.name) {
      errors.carrier = 'Carrier is required'
    }
    // if (!clientId && !selectedClient?.name) {
    //   errors.selectedClient = 'Please select Client'
    // }
    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const fetchClientData = async () => {
    try {
      const response = await clientService.fetch()
      setClientData(response.data)
    } catch (error) {
      console.error('Fetch failed:', error)
      // Handle fetch failure
    }
  }
  useEffect(() => {
    fetchClientData()
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value
    setRowsPerPage(newRowsPerPage)
    setPage(0)
  }

  const fetchServiceData = async () => {
    setIsLoading(true)

    try {
      const skip = page * rowsPerPage
      const response = await carrierServiceManagement.fetch(
        rowsPerPage,
        skip,
        clientId ? clientId : clientFilter?._id,
        searchValue
      )

      setServiceData(response.data)
      setIsLoading(false)
      setDataCount(response.total)
    } catch (error) {
      console.error('Fetch failed:', error)
    } finally {
      setIsLoading(false)
    }
  }
  // const fetchCarrierData = async () => {
  //   setIsLoading(true)
  //   const response = await carrierService.fetch(15, 0, clientId)
  //   setCarrierList(response.data.map((e) => ({ ...e, label: e.name })))
  //   setIsLoading(false)
  // }
  const fetchCarrierData = useCallback(
    debounce(async (searchQuery, pageNumber) => {
      console.log('Fetching data with:', searchQuery, pageNumber)
      setLoading(true)
      try {
        const response = await carrierService.fetch(10, pageNumber * 10, clientId, searchQuery)
        console.log('Response data:', response.data)

        setCarrierList((prevData) => {
          if (pageNumber === 0) {
            return response.data.map((e) => ({ ...e, label: e.name }))
          } else {
            return [...prevData, ...response.data.map((e) => ({ ...e, label: e.name }))]
          }
        })
        setTotal(response.total || 0)
      } catch (error) {
        console.error('Fetch failed:', error)
      } finally {
        setLoading(false)
      }
    }, 300),
    []
  )

  const handleCarrierScroll = (event) => {
    if (event?.target) {
      const bottom =
        event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) ||
        event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) - 1 ||
        event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) + 1

      if (bottom && carrierList.length < total && !loading) {
        setCarrierPage((prevPage) => prevPage + 1)
      }
    }
  }

  useEffect(() => {
    fetchServiceData()
  }, [page, rowsPerPage, clientFilter, selectedService, searchValue])

  useEffect(() => {
    fetchCarrierData(carrierSearchValue, carrierPage)
  }, [carrierSearchValue, carrierPage, fetchCarrierData])

  const createService = async (e) => {
    e.preventDefault()
    const isValid = validateForm()
    if (isValid) {
      try {
        setIsLoading(true)
        const client_id = clientId ? clientId : selectedClient?._id
        const response = await carrierServiceManagement.create(name, selectedCarrierService._id, client_id)
        toast.success('Carrier Service Created successfully!')
        fetchServiceData()
        setName('')
        setSelectedCarrierService({})
        setSelectedClient({ name: '' })
        fetchCarrierData('', 0)
      } catch (error) {
        console.error('Create failed:', error)
        setIsLoading(false)
      } finally {
        setIsLoading(false)
      }
      setShowAddModal(false)
    }
  }
  useEffect(() => {
    if (showAddModal) {
      setCarrierList([])
      setCarrierPage(0)
      setCarrierSearchValue('')
      fetchCarrierData('', 0)
    }
  }, [showAddModal, fetchCarrierData])
  return (
    <DashboardContainer
      hideFilterClient
      onSearchData={(x) => {
        setSearchValue(x)
        setPage(0)
      }}
      onClickAddButton={() => {
        setShowAddModal(true)
        setFormErrors({})
        setSelectedClient({ name: '' })
      }}
      hideUploadButton={true}
      header={'Service List'}
      onSelectClient={(e) => {
        setClientFilter(e)
      }}
      selectedClient={selectedClient?.name}
      title={'+ Add Service'}
      setViewMode={setViewMode}
    >
      <AddModal
        title={'Service'}
        setSelectedCarrierService={(e) => setSelectedCarrierService(e)}
        selectedCarrierService={selectedCarrierService?.name}
        service
        selectedService={selectedService}
        setSelectedService={setSelectedService}
        carrierList={carrierList}
        onClickAdd={(e) => {
          createService(e)
        }}
        onChangeName={(e) => setName(e)}
        handleClose={() => setShowAddModal(false)}
        onSelectClient={(newData) => {
          setSelectedClient(newData)
        }}
        open={showAddModal}
        formErrors={formErrors}
        setCarrierSearchValue={setCarrierSearchValue}
        setPage={setCarrierPage}
        setCarrier={setCarrierList}
        handleScroll={handleCarrierScroll}
        fetchCarrierData={fetchCarrierData}
        carrierSearchValue={carrierSearchValue}
      ></AddModal>
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
            color: 'white'
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <ServiceList
        carrierList={carrierList}
        onEditData={() => fetchServiceData()}
        onDeleteData={() => fetchServiceData()}
        data={serviceData}
        viewMode={viewMode}
        deleteTitle={'Delete Service'}
        handleScroll={handleCarrierScroll}
        setCarrierSearchValue={setCarrierSearchValue}
        setPage={setCarrierPage}
        setCarrierList={setCarrierList}
        fetchCarrierData={fetchCarrierData}
      />
      <TablePagination
        slotProps={{
          actions: {
            nextButton: {
              disabled: dataCount < rowsPerPage ? true : false
            }
          }
        }}
        nextIconButton={
          <IconButton>
            <KeyboardArrowRight />
          </IconButton>
        }
        backIconButton={
          <IconButton>
            <KeyboardArrowLeft />
          </IconButton>
        }
        rowsPerPageOptions={[15, 25, 45, 75, 100]}
        component="div"
        count={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#ffffff',
          padding: '2px',
          borderTop: '1px solid #dddddd'
        }}
      />
      <ToastContainer />
    </DashboardContainer>
  )
}

export default ServiceManagement

import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Card,
  CardContent,
  useMediaQuery,
  Button,
  IconButton,
  TextField,
  Tooltip,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CustomTypography from "../../globalStyles/fonts";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { asnRequestService } from "../../utils/services/asnRequestService";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import config from "../../config";
import { getRequestDetailsForASN } from "../../utils/getRequestDetailsForAsn";
import DeleteConfirmationModal from "../deleteConfirmationModal";

const ASNDataModal = ({ open, handleClose, handleEdit, selectedProduct }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [responseModalOpen, setResponseModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [editData, setEditData] = useState({});
  const [responseData, setResponseData] = useState({});
  const [asnData, setAsnData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const fetchAsnData = async () => {
    try {
      const response = await asnRequestService.fetch(100, selectedProduct?._id);
      setAsnData(
        response.data.map((data) => ({
          ...data,
          request: getRequestDetailsForASN(data.request),
        }))
      );
    } catch (error) {
      console.error("Fetch failed:", error);
      // Handle fetch failure
    }
  };
  const editAsnData = async (item) => {
    try {
      const response = await axios.patch(
        `${config.warehouseBaseURL}/stock-in`,
        {
          stockData: editData,
          client_id: selectedProduct?.client_id?._id,
          provider: editData.provider.provider,
        }
      );
      console.log(response);
      fetchAsnData();
      setEditModalOpen(false);
    } catch (error) {
      console.error("Edit failed:", error);
      // Handle fetch failure
    }
  };
  const deleteAsnData = async () => {
    try {
      const response = await axios.delete(
        `${config.warehouseBaseURL}/stock-in?client_id=${selectedProduct?.client_id?._id}&provider=${selectedData.provider.provider}&ItemNumber=${selectedData.request.ItemNumber}`
      );
      fetchAsnData();
      setOpenDeleteModal(false);
    } catch (error) {
      console.error("Edit failed:", error);
      // Handle fetch failure
    }
  };
  useEffect(() => {
    if (open && selectedProduct?._id) {
      fetchAsnData();
    }
  }, [open]);

  // Handler for opening the edit modal with pre-filled data
  const onEditClick = (item) => {
    setEditData(item); // Set the current item data in state
    setEditModalOpen(true); // Open the edit modal
  };

  // Handler for closing the edit modal
  const handleEditClose = () => {
    setEditModalOpen(false);
  };
  const onResponseClick = (response) => {
    setResponseData(response);
    setResponseModalOpen(true);
  };

  // Handler for editing the input fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle the form submission for editing
  const onEditSubmit = () => {
    editAsnData();
    // handleEdit(editData) // Pass the edited data to the parent handler
    // setEditModalOpen(false) // Close the modal after submission
  };
  const handleResponseClose = () => setResponseModalOpen(false);
  console.log(responseData);
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: isMobile ? "90%" : "70%",
            maxHeight: "60vh",
            overflow: "auto",
          }}
        >
          <CustomTypography
            style={{ fontWeight: "500", fontSize: 23 }}
            id="modal-title"
            variant="h6"
            component="h2"
            mb={2}
          >
            ASN Request List
          </CustomTypography>
          {/* {asnData?.length ? <CustomTypography>No data found</CustomTypography> : <></>} */}
          {isMobile ? (
            asnData.map((item) => (
              <Card>
                <CardContent>
                  <CustomTypography variant="h6">
                    Item Number: {item.request.ItemNumber}
                  </CustomTypography>
                  <CustomTypography>
                    Package: {item.request.PKG}
                  </CustomTypography>
                  <CustomTypography>Unit: {item.request.Unit}</CustomTypography>
                  <CustomTypography>
                    Inner Package: {item.request.InnerPKG}
                  </CustomTypography>
                  <CustomTypography>
                    Inner Unit: {item.request.InnerUnit}
                  </CustomTypography>
                  <CustomTypography>
                    Currency: {item.request.UnitCurrency}
                  </CustomTypography>
                  <CustomTypography>
                    Provider: {item.provider.provider}
                  </CustomTypography>
                  <CustomTypography>
                    Description:{" "}
                    {item.request.Description?.replace(/<[^>]+>/g, "")}
                  </CustomTypography>
                  <Box
                    sx={{
                      minWidth: 100,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Tooltip title="Response">
                      <IconButton
                        style={{
                          color: "#007DFF",
                          padding: 0,
                          background: "#C3E1FF",
                          height: 30,
                          width: 30,
                          borderRadius: 5,
                          marginTop: 5,

                          boxShadow: "0px 0px 4px 0px #00000040",
                        }}
                        aria-label="view"
                        onClick={() => {
                          onResponseClick(item.response);
                        }}
                      >
                        <img
                          style={{ height: 14, width: 20 }}
                          alt="delete"
                          src="/eye.png"
                        ></img>
                      </IconButton>
                    </Tooltip>
                    <IconButton
                      style={{
                        color: "#FEC50F",
                        padding: 0,
                        background: "#FEC50F33",
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        boxShadow: "0px 0px 4px 0px #00000040",
                        marginTop: 5,
                        marginLeft: 10,
                      }}
                      aria-label="edit"
                      onClick={() => {
                        onEditClick({
                          ...item.request,
                          provider: item.provider,
                        });
                        setSelectedData(item);
                      }}
                    >
                      <BorderColorIcon sx={{ height: 20, width: 20 }} />
                    </IconButton>
                    <IconButton
                      style={{
                        color: "#D13438",
                        padding: 0,
                        background: "#D1343833",
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 10,
                        boxShadow: "0px 0px 4px 0px #00000040",
                        marginTop: 5,
                      }}
                      aria-label="delete"
                      onClick={() => {
                        setOpenDeleteModal(true);
                        setSelectedData(item);
                      }}
                    >
                      <img
                        style={{ height: 15, width: 15 }}
                        alt="delete"
                        src="/delete.png"
                      ></img>
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            ))
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <CustomTypography
                        style={{ fontWeight: "500", fontSize: 13 }}
                      >
                        Item Number
                      </CustomTypography>
                    </TableCell>
                    <TableCell>
                      <CustomTypography
                        style={{ fontWeight: "500", fontSize: 13 }}
                      >
                        Package
                      </CustomTypography>
                    </TableCell>
                    <TableCell>
                      <CustomTypography
                        style={{ fontWeight: "500", fontSize: 13 }}
                      >
                        Unit
                      </CustomTypography>
                    </TableCell>
                    <TableCell>
                      <CustomTypography
                        style={{ fontWeight: "500", fontSize: 13 }}
                      >
                        Inner Package
                      </CustomTypography>
                    </TableCell>
                    <TableCell>
                      <CustomTypography
                        style={{ fontWeight: "500", fontSize: 13 }}
                      >
                        Inner Unit
                      </CustomTypography>
                    </TableCell>
                    <TableCell>
                      <CustomTypography
                        style={{ fontWeight: "500", fontSize: 13 }}
                      >
                        Currency
                      </CustomTypography>
                    </TableCell>
                    <TableCell>
                      <CustomTypography
                        style={{ fontWeight: "500", fontSize: 13 }}
                      >
                        Provider
                      </CustomTypography>
                    </TableCell>
                    <TableCell sx={{ maxWidth: 100 }}>
                      <CustomTypography
                        style={{ fontWeight: "500", fontSize: 13 }}
                      >
                        Description
                      </CustomTypography>
                    </TableCell>
                    <TableCell>
                      <CustomTypography
                        style={{ fontWeight: "500", fontSize: 13 }}
                      >
                        Action
                      </CustomTypography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {asnData.map((item) => (
                    <TableRow key={item.request.ItemNumber}>
                      <TableCell>
                        <CustomTypography
                          style={{ fontWeight: "400", fontSize: 13 }}
                        >
                          {item.request.ItemNumber}
                        </CustomTypography>
                      </TableCell>
                      <TableCell>
                        <CustomTypography
                          style={{ fontWeight: "400", fontSize: 13 }}
                        >
                          {item.request.PKG}
                        </CustomTypography>
                      </TableCell>
                      <TableCell>
                        <CustomTypography
                          style={{ fontWeight: "400", fontSize: 13 }}
                        >
                          {item.request.Unit}
                        </CustomTypography>
                      </TableCell>
                      <TableCell>
                        <CustomTypography
                          style={{ fontWeight: "400", fontSize: 13 }}
                        >
                          {item.request.InnerPKG}
                        </CustomTypography>
                      </TableCell>
                      <TableCell>
                        <CustomTypography
                          style={{ fontWeight: "400", fontSize: 13 }}
                        >
                          {item.request.InnerUnit}
                        </CustomTypography>
                      </TableCell>
                      <TableCell>
                        <CustomTypography
                          style={{ fontWeight: "400", fontSize: 13 }}
                        >
                          {item.request.UnitCurrency}
                        </CustomTypography>
                      </TableCell>
                      <TableCell>
                        <CustomTypography
                          style={{ fontWeight: "400", fontSize: 13 }}
                        >
                          {item.provider.provider}
                        </CustomTypography>
                      </TableCell>

                      <Tooltip
                        title={`${item.request.Description?.replace(
                          /<[^>]+>/g,
                          ""
                        )}`}
                      >
                        <TableCell sx={{ maxWidth: 100 }}>
                          <CustomTypography
                            style={{ fontWeight: "400", fontSize: 13 }}
                          >
                            {item.request.Description === "undefined"
                              ? ""
                              : item.request.Description?.replace(
                                  /<[^>]+>/g,
                                  ""
                                )}
                          </CustomTypography>
                        </TableCell>
                      </Tooltip>
                      <TableCell>
                        <Box
                          sx={{
                            minWidth: 100,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Tooltip title="Response">
                            <IconButton
                              style={{
                                color: "#007DFF",
                                padding: 0,
                                background: "#C3E1FF",
                                height: 30,
                                width: 30,
                                borderRadius: 5,
                                marginTop: 5,

                                boxShadow: "0px 0px 4px 0px #00000040",
                              }}
                              aria-label="view"
                              onClick={() => {
                                onResponseClick(item.response);
                              }}
                            >
                              <img
                                style={{ height: 14, width: 20 }}
                                alt="delete"
                                src="/eye.png"
                              ></img>
                            </IconButton>
                          </Tooltip>
                          <IconButton
                            style={{
                              color: "#FEC50F",
                              padding: 0,
                              background: "#FEC50F33",
                              height: 30,
                              width: 30,
                              borderRadius: 5,
                              boxShadow: "0px 0px 4px 0px #00000040",
                              marginTop: 5,
                              marginLeft: 10,
                            }}
                            aria-label="edit"
                            onClick={() => {
                              onEditClick({
                                ...item.request,
                                provider: item.provider,
                              });
                              setSelectedData(item);
                            }}
                          >
                            <BorderColorIcon sx={{ height: 20, width: 20 }} />
                          </IconButton>
                          <IconButton
                            style={{
                              color: "#D13438",
                              padding: 0,
                              background: "#D1343833",
                              height: 30,
                              width: 30,
                              borderRadius: 5,
                              marginLeft: 10,
                              boxShadow: "0px 0px 4px 0px #00000040",
                              marginTop: 5,
                            }}
                            aria-label="delete"
                            onClick={() => {
                              setOpenDeleteModal(true);
                              setSelectedData(item);
                            }}
                          >
                            <img
                              style={{ height: 15, width: 15 }}
                              alt="delete"
                              src="/delete.png"
                            ></img>
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{ mt: 2, backgroundColor: "#D13438" }}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Edit Modal */}
      <Modal
        open={editModalOpen}
        onClose={handleEditClose}
        aria-labelledby="edit-modal-title"
        aria-describedby="edit-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: isMobile ? "90%" : "60%",
            overflow: "auto",
            maxHeight: "70vh",
          }}
        >
          <Typography variant="h6" id="edit-modal-title" mb={2}>
            Edit Item
          </Typography>

          <TextField
            size="small"
            fullWidth
            label="Item Number"
            name="ItemNumber"
            value={editData?.ItemNumber}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            size="small"
            fullWidth
            label="Package"
            name="PKG"
            value={editData?.PKG}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            size="small"
            fullWidth
            label="Unit"
            name="Unit"
            value={editData?.Unit}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            size="small"
            fullWidth
            label="Inner Package"
            name="InnerPKG"
            value={editData?.InnerPKG}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            size="small"
            fullWidth
            label="Inner Unit"
            name="InnerUnit"
            value={editData?.InnerUnit}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            size="small"
            fullWidth
            label="Currency"
            name="UnitCurrency"
            value={editData?.UnitCurrency}
            onChange={handleInputChange}
            margin="normal"
          />
          <ReactQuill
            theme="snow"
            value={editData?.Description}
            onChange={(e) => setEditData({ ...editData, Description: e })}
            placeholder="Enter product description"
            style={{
              height: isMobile ? 70 : "100px",
              marginBottom: "50px",
            }}
          />
          {/* <TextField
            size="small"
            fullWidth
            label="Description"
            name="Description"
            value={editData?.request?.Description}
            onChange={handleInputChange}
            margin="normal"
          /> */}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              onClick={() => setEditModalOpen(false)}
              variant="contained"
              sx={{ mt: 2, backgroundColor: "#D13438", marginRight: 1 }}
            >
              Cancel
            </Button>
            <Button onClick={onEditSubmit} variant="contained" sx={{ mt: 2 }}>
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal open={responseModalOpen} onClose={handleResponseClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: isMobile ? "90%" : "50%",
          }}
        >
          <Typography variant="h6" mb={2}>
            Response Details
          </Typography>
          <Grid container spacing={2}>
            {Array.isArray(responseData) && responseData.length > 0 ? (
              responseData.map((item, index) => (
                <React.Fragment key={index}>
                  {Object.entries(item)
                    .filter(
                      ([key]) =>
                        ![
                          "_id",
                          "client_id",
                          "createdAt",
                          "updatedAt",
                          "__v",
                        ].includes(key)
                    ) // Exclude specified fields
                    .map(([key, value]) => {
                      let cleanValue;

                      // Clean HTML if the value is a string
                      if (typeof value === "string") {
                        cleanValue = value.replace(/<[^>]+>/g, "");
                      } else if (Array.isArray(value)) {
                        // Handle arrays (convert to string or render in a custom way)
                        cleanValue = value.join(", ");
                      } else if (typeof value === "object" && value !== null) {
                        // Handle objects (convert to string or JSON)
                        cleanValue = JSON.stringify(value, null, 2);
                      } else {
                        cleanValue = value;
                      }

                      // Only render if value is not null, undefined, or an empty string
                      if (
                        cleanValue !== null &&
                        cleanValue !== undefined &&
                        cleanValue !== ""
                      ) {
                        return (
                          <React.Fragment key={key}>
                            <Grid item xs={6}>
                              <CustomTypography>
                                <strong>{`${key}`}:</strong>
                              </CustomTypography>
                            </Grid>
                            <Grid item xs={6}>
                              <Tooltip
                                title={cleanValue.length > 30 ? cleanValue : ""}
                              >
                                <CustomTypography>
                                  {cleanValue}
                                </CustomTypography>
                              </Tooltip>
                            </Grid>
                          </React.Fragment>
                        );
                      }
                      return null;
                    })}
                </React.Fragment>
              ))
            ) : (
              <Grid item xs={12}>
                <CustomTypography>No data available</CustomTypography>
              </Grid>
            )}
          </Grid>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={handleResponseClose}
              variant="contained"
              sx={{ mt: 2, backgroundColor: "#D13438" }}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
      <DeleteConfirmationModal
        title={`Are you sure you want to delete the ASN request for SKU ${selectedData?.request.ItemNumber}? This action cannot be undone.`}
        handleDelete={() => deleteAsnData()}
        handleClose={() => {
          setOpenDeleteModal(false);
          setSelectedData(null);
        }}
        open={openDeleteModal}
      ></DeleteConfirmationModal>
    </>
  );
};

export default ASNDataModal;

import React, { useState, useEffect, useCallback } from 'react'
import { Autocomplete, TextField, CircularProgress } from '@mui/material'
import { clientService } from '../../utils/services/clientService'
import debounce from 'lodash.debounce'

const ClientAutocomplete = ({ selectedClient, onClientChange,width=250,label="Select Client"}) => {
  const [allClients, setAllClients] = useState([])
  const [clientSearchValue, setClientSearchValue] = useState('')
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const fetchClientData = useCallback(
    debounce(async (searchQuery, pageNumber) => {
      setLoading(true)
      try {
        const response = await clientService.fetch(10, pageNumber * 10, searchQuery)
        if (pageNumber === 0) {
          setAllClients(response.data)
        } else {
          setAllClients((prevClients) => [...prevClients, ...response.data])
        }
        setTotal(response.total)
      } catch (error) {
        console.error('Fetch failed:', error)
      } finally {
        setLoading(false)
      }
    }, 300),
    []
  )

  useEffect(() => {
    fetchClientData(clientSearchValue, page)
  }, [clientSearchValue, page, fetchClientData])

  useEffect(() => {
    if (clientSearchValue === '') {
      fetchClientData('', 0) // Fetch all clients when search value is empty
    }
  }, [clientSearchValue, fetchClientData])

  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) ||
      event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) - 1 ||
      event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) + 1
    if (bottom && allClients.length < total && !loading) {
      setPage((prevPage) => prevPage + 1)
    }
  }

  const handleClientChange = (event, value) => {
    if (onClientChange) {
      onClientChange(value)
    }
    // Reset the search value to show all clients
    setClientSearchValue('')
  }

  const handleSearchChange = (event) => {
    const value = event.target.value
    setClientSearchValue(value)
    setPage(0)
    setAllClients([])
  }

  return (
    <Autocomplete
      ListboxProps={{
        onScroll: handleScroll
      }}
      size="small"
      id="client-select"
      options={allClients}
      getOptionLabel={(option) => option.business_name || option.name}
      value={selectedClient || null}
      onChange={handleClientChange}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="dense"
          label={label}
          variant="outlined"
          onChange={handleSearchChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
      style={{ minWidth: width, marginRight: 10 }}
    />
  )
}

export default ClientAutocomplete

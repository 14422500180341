import * as React from 'react'
import Header from '../header'

function DashboardContainer({
  children,
  onClickUpload,
  hideUploadButton,
  hideAddButton,
  hideFilterClient,
  onClickAddButton,
  onHandleUploadButton,
  zipZone,
  simulation,
  onUploadSimulationData,
  rateCard,
  searchBarPlaceholder,
  onSearchData,
  title,
  onSelectClient,
  selectedClient,
  product,
  setViewMode,
  order,
  onClickExport,
  header,
  onSelectDate,
  showDownloadIcon,
  shipmentPrice,
  onClickRefresh,
  onClickEstimate,
  shipmentCost,
  client,
  showUploadStatus,
  onClickStatus,
  syncProduct,
  onClickSyncProduct,
  importProduct,
  onClickDiscardAll
}) {
  return (
    <div
      className="dashboard-container"
      style={{
        // marginTop: 20,
        overflow: 'hidden'
      }}
    >
      <Header
        onClickStatus={onClickStatus}
        showUploadStatus={showUploadStatus}
        showDownloadIcon={showDownloadIcon}
        onSearchData={onSearchData}
        searchBarPlaceholder={searchBarPlaceholder}
        rateCard={rateCard}
        onUploadSimulationData={onUploadSimulationData}
        simulation={simulation}
        zipZone={zipZone}
        onHandleUploadButton={onHandleUploadButton}
        onClickAddButton={onClickAddButton}
        hideAddButton={hideAddButton}
        hideUploadButton={hideUploadButton}
        title={title}
        onClickUpload={onClickUpload}
        onSelectClient={onSelectClient}
        selectedClient={selectedClient}
        hideFilterClient={hideFilterClient}
        product={product}
        setViewMode={setViewMode}
        order={order}
        onClickExport={onClickExport}
        header={header}
        onSelectDate={onSelectDate}
        shipmentPrice={shipmentPrice}
        onClickRefresh={onClickRefresh}
        onClickEstimate={onClickEstimate}
        shipmentCost={shipmentCost}
        client={client}
        onClickSyncProduct={onClickSyncProduct}
        importProduct={importProduct}
        onClickDiscardAll={onClickDiscardAll}
      />
      {children}
    </div>
  )
}

export default DashboardContainer

import React from 'react'
import { Card, CardContent, Box, IconButton } from '@mui/material'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import CustomTypography from '../../globalStyles/fonts'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { isMobile } from '../../globalStyles/mobile'

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend, TimeScale)

const dummyData = {
  labels: ['2024-07-01', '2024-07-02', '2024-07-03', '2024-07-04', '2024-07-05', '2024-07-06', '2024-07-07'],
  datasets: [
    {
      data: [89, 91, 92, 93, 94, 95, 96],
      fill: false,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)'
    }
  ]
}

const options = {
  plugins: {
    legend: {
      display: false
    }
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day'
      },
      grid: {
        display: false
      }
    },
    y: {
      beginAtZero: true,
      max: 100,
      ticks: {
        callback: (value) => `${value}%`
      },
      grid: {
        display: false
      }
    }
  }
}

const GraphCard = ({ title, minWidth, backgroundColor, smallRoundBoxBg }) => (
  <Card
    sx={{
      width: isMobile() ? '95%' : 400,
      height: 250,
      padding: 1,
      backgroundColor: backgroundColor || '#F3E8FF',
      borderRadius: '16px',
      position: 'relative',
      margin: 1
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        top: -30,
        right: -10,
        width: '80px',
        height: '80px',
        backgroundColor: smallRoundBoxBg || '#BF82FF',
        borderRadius: '100%'
      }}
    />
    <CardContent>
      <div
        style={{
          width: '95%',
          justifyContent: 'flex-start'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <CustomTypography fontSize={30} sx={{ fontWeight: '600', color: '#151D48', textAlign: 'left' }}>
            {title || 'SLA'}
          </CustomTypography>
          <IconButton>
            <MoreVertIcon color="primary" />
          </IconButton>
        </div>
      </div>

      <Line data={dummyData} options={options} />
    </CardContent>
  </Card>
)

export default GraphCard

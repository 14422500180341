import * as React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import AppContext from '../context/context'
import { loginService } from '../../utils/services/loginService.js'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { IconButton } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { clientService } from '../../utils/services/clientService'

function Logo() {
  return (
    <svg
      alt="Company Logo"
      className="self-center max-w-full aspect-[1.22] w-[119px]"
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      // width="512.000000pt"
      // height="512.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#FFFFFF" stroke="none">
        <path
          d="M529 4306 c-49 -34 -73 -86 -67 -148 6 -61 31 -96 88 -125 l44 -23
1278 0 1278 0 0 -589 0 -588 22 -33 c13 -18 36 -43 51 -54 28 -21 37 -21 685
-24 l657 -3 117 -152 118 -152 0 -412 0 -413 -154 0 -154 0 -21 63 c-56 167
-221 329 -396 387 -333 111 -704 -68 -814 -392 l-18 -53 -477 0 -477 0 -28 72
c-56 142 -163 260 -301 332 -97 51 -184 73 -290 73 -113 1 -188 -16 -285 -62
-157 -76 -285 -217 -332 -367 l-16 -52 -231 -3 c-254 -3 -272 -7 -320 -71 -31
-42 -30 -140 2 -185 45 -63 62 -67 319 -70 l231 -4 22 -58 c48 -127 176 -273
297 -338 129 -68 319 -92 458 -57 203 51 373 201 446 392 l24 63 481 0 480 0
18 -55 c23 -74 104 -193 174 -255 65 -58 209 -133 290 -149 84 -17 219 -13
307 9 188 48 354 195 428 380 l26 65 258 5 c247 5 260 6 295 28 21 13 45 40
57 66 20 41 21 56 21 587 0 326 -4 558 -10 579 -6 20 -77 121 -166 235 l-156
200 -240 480 c-132 264 -251 495 -265 514 -46 60 -55 61 -452 61 l-359 0 -4
109 c-3 121 -13 144 -78 189 l-33 22 -1396 0 -1396 0 -36 -24z m3691 -936 c88
-176 160 -322 160 -325 0 -3 -205 -5 -455 -5 l-455 0 0 325 0 325 295 0 295 0
160 -320z m-2443 -1641 c76 -29 143 -93 178 -171 24 -54 27 -69 23 -142 -6
-99 -36 -165 -104 -224 -146 -129 -371 -101 -476 60 -90 137 -73 296 44 410
88 85 216 111 335 67z m2227 -8 c199 -97 245 -366 88 -518 -155 -151 -412
-110 -509 80 -91 181 0 394 197 458 50 16 172 5 224 -20z"
        />
        <path
          d="M354 3656 c-61 -27 -101 -108 -85 -177 12 -53 38 -86 85 -109 43 -20
53 -20 774 -18 723 3 731 3 759 24 15 11 38 36 51 54 46 68 18 176 -58 219
-37 21 -42 21 -766 21 -588 -1 -735 -3 -760 -14z"
        />
        <path
          d="M90 3018 c-61 -31 -92 -86 -87 -151 5 -54 27 -91 77 -124 l33 -23
737 0 737 0 33 23 c48 32 72 69 77 118 6 64 -28 127 -87 157 l-44 22 -716 0
-716 0 -44 -22z"
        />
        <path
          d="M343 2380 c-85 -52 -103 -171 -36 -242 54 -58 56 -58 491 -58 398 0
399 0 443 23 88 47 114 164 52 237 -51 61 -43 60 -500 60 -397 0 -420 -1 -450
-20z"
        />
      </g>
    </svg>
  )
}

function InputField({ icon, placeholder, onChange, type, value, endAdornment }) {
  return (
    <div className="flex gap-5 px-3 py-3.5 mt-5 uppercase whitespace-nowrap rounded border border-white border-solid leading-[143%]">
      <img loading="lazy" src={icon} alt="" className="shrink-0 w-5 aspect-square" />
      <input
        onChange={(e) => onChange(e.target.value)}
        type={type}
        placeholder={placeholder}
        value={value}
        className="flex-auto my-auto bg-transparent border-none text-white placeholder-white focus:outline-none"
      />
      {endAdornment}
    </div>
  )
}

function LoginForm({ setIsAuthenticated }) {
  const navigate = useNavigate()
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [showPassword, setShowPassword] = React.useState(false)
  const { user, setUser } = React.useContext(AppContext)
  // const fetchClientId = async (email) => {
  //   const response = await clientService.fetch(15, 0, '', email)
  //   localStorage.setItem('client_id', response?.data[0]?._id)
  // }

  const fetchClientByUser = async (user) => {
    console.log('user ===>', user)
    let clientUser = await clientService.fetchClientByUser(user._id)
    console.log(clientUser)
    localStorage.setItem('client_id', clientUser?.data[0]?.client)
  }

  const handleLogin = async (e) => {
    e.preventDefault()
    try {
      if (!email || !password) {
        toast.error('Please enter email and password')
        return
      }
      const response = await loginService.login(email, password)
      setUser(response.user)
      if (response.accessToken) {
        setIsAuthenticated(true)
        localStorage.setItem('token', response.accessToken)
        localStorage.setItem('user', JSON.stringify(response.user))
        if (response.user?.role === 'client') {
          fetchClientByUser(response?.user)
          navigate('/')
        } else {
          navigate('/')
        }
      }
    } catch (error) {
      console.error('Login failed:', error)
      toast.error('Login failed. Please check your credentials.')
    }
  }

  return (
    <form className="flex relative flex-col max-w-full w-[300px] max-md:my-10">
      <InputField
        onChange={setEmail}
        type="text"
        icon={
          'https://cdn.builder.io/api/v1/image/assets/TEMP/57504037d7e6b3838ed793dd36d04799dd38f02f8e5105ccabc9e9c37e72edb4?apiKey=9630cf04d98747ca9cd138c94d8172e2&'
        }
        placeholder="Email"
        value={email}
      />
      <InputField
        onChange={setPassword}
        type={showPassword ? 'text' : 'password'}
        icon={
          'https://cdn.builder.io/api/v1/image/assets/TEMP/47e1946ab5690657a70c7581182fa78d733a9e0928efd9d0c229f2aaa93d4f82?apiKey=9630cf04d98747ca9cd138c94d8172e2&'
        }
        placeholder="Password"
        value={password}
        endAdornment={
          <IconButton
            style={{ color: 'white', height: 10 }}
            onClick={() => setShowPassword(!showPassword)}
            aria-label="Toggle password visibility"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        }
      />

      <button
        onClick={handleLogin}
        type="submit"
        className="justify-center items-center px-16 py-4 mt-11 text-base font-semibold text-blue-800 uppercase whitespace-nowrap bg-white rounded shadow-sm max-md:px-5 max-md:mt-10"
      >
        Login
      </button>

      <Link to="/forgot-password" className="mt-4 text-base font-medium">
        Forgot password ?
      </Link>
    </form>
  )
}

const Login = () => {
  const { setIsAuthenticated, setUser } = React.useContext(AppContext)
  const navigate = useNavigate()
  const { state } = useLocation()
  // const fetchClientId = async (email) => {
  //   const clientDetails = await clientService.fetch(15, 0, '', email)
  //   localStorage.setItem('client_id', clientDetails[0]?.id)
  // }

  React.useEffect(() => {
    if (localStorage.getItem('token')) {
      setIsAuthenticated(true)
      setUser(JSON.parse(localStorage.getItem('user')))
      const user = JSON.parse(localStorage.getItem('user'))
      const path = localStorage.getItem('path')
      if (path && path !== 'undefined') {
        navigate(localStorage.getItem('path'))
      } else if (user?.role === 'client') {
        // fetchClientId(user.email)
        navigate('/')
      } else {
        navigate('/')
      }
    } else {
      console.log('***********')
    }
  }, [])

  React.useEffect(() => {
    if (state?._id) {
      toast.success('Account Created Successfully, Please Login', {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'colored'
      })
    }
  }, [state?._id])

  return (
    <main
      style={{ backgroundColor: '#324786' }}
      className="flex flex-col justify-center text-sm font-light text-center text-white"
    >
      <section className="flex overflow-hidden relative flex-col justify-center items-center px-16 py-20 w-full min-h-[100vh] max-md:px-5 max-md:max-w-full">
        <img
          src="BG.png"
          loading="lazy"
          alt="Background"
          className="object-cover absolute inset-0 size-full"
        />
        <LoginForm setIsAuthenticated={setIsAuthenticated} />
        <ToastContainer />
      </section>
    </main>
  )
}

export default Login

import { Backdrop, CircularProgress } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import AppContext from './components/context/context'
import Layout from './components/layout'
import { Data } from './components/sidebar/sidebarData'
import useCurrentPage from './utils/getCurrentpath'
import { getObjectByPath } from './utils/getObjectByPath'
const ProtectedRoute = () => {
  const { isAuthenticated, setCurrentMenu, setCurrentSubMenu } = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  const location = useLocation()

  const extractPathName = (url) => {
    const parts = url.split('/')
    if (parts === 'track-order' || parts === 'order-tracking-history') {
      return '/' + parts[1]
    } else {
      return url
    } //
  }
  // Create a URLSearchParams object based on the current URL's query string
  const data = useCurrentPage(Data, location.pathname)
  const currentUrl = getObjectByPath(data?.path)
  console.log(data)
  console.log(location.pathname)
  // useEffect(() => {
  //   if (currentUrl) {
  //     if (currentUrl?.subNav) {
  //       setCurrentSubMenu(currentUrl.subNav)
  //     } else if (currentUrl?.parentNav) {
  //       setCurrentMenu(currentUrl.parentNav)
  //     } else {
  //       setCurrentMenu(Data[0])
  //     }
  //   }
  // }, [currentUrl])
  useEffect(() => {
    // Simulate async authentication check
    const timer = setTimeout(() => {
      setLoading(false)
    }, 1000)

    return () => clearTimeout(timer)
  }, []) // Run only once on component mount
  if (loading) {
    // Show loading spinner or splash screen while checking authentication status
    return (
      <Backdrop
        open={true}
        style={{
          zIndex: 999,
          color: '#fff',
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
            flexDirection: 'column'
          }}
        >
          <CircularProgress color="primary" />
          <p
            style={{
              marginTop: 10,
              animation: 'loadingAnim 1s infinite alternate'
            }}
          >
            Loading...
          </p>
          {/* Added animation */}
        </div>
      </Backdrop>
    )
  }

  console.log('isAuthenticated', isAuthenticated)
  return isAuthenticated ? (
    <div>
      <Layout>
        <Outlet />
      </Layout>
    </div>
  ) : (
    <Navigate replace={true} to="/home-page" />
  )
}

export default ProtectedRoute

import * as React from 'react'
import styles from './landing-page.module.css'
import DropSkipScreen from '../../components/drop-skip-screen'
import LogisticsScreen from '../../components/logistics-screen'
import CostAndInventory from '../../components/cost-screen-landing-page'
import AIDrivenAgile from '../../components/AI-drive-agile-screen'
import PartnerComponent from '../../components/partner-screen-landing-page'
import Footer from '../../components/landing-page-footer'
import { Link, useNavigate } from 'react-router-dom'
import AppContext from '../../components/context/context'
import Cost from '../../components/cost-screen-landing-page'
import Inventory from '../../components/inventory-landing-page'

const LandingPage = () => {
  const [menuOpen, setMenuOpen] = React.useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const logisticsRef = React.useRef(null)
  const costRef = React.useRef(null)
  const aiAgileRef = React.useRef(null)
  const partnerRef = React.useRef(null)
  const inventoryRef = React.useRef(null)
  const scrollToLogistics = () => {
    if (logisticsRef.current) {
      console.log(logisticsRef.current)

      logisticsRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }
  const scrollToCost = () => {
    if (costRef.current) {
      console.log(costRef.current)

      costRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }
  const scrollToInventory = () => {
    if (inventoryRef.current) {
      console.log(inventoryRef.current)

      inventoryRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }
  const scrollToAIAgile = () => {
    if (aiAgileRef.current) {
      console.log(aiAgileRef.current)

      aiAgileRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }
  const scrollToPartner = () => {
    if (partnerRef.current) {
      console.log(partnerRef.current)

      partnerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }
  const { setIsAuthenticated, setUser } = React.useContext(AppContext)
  const navigate = useNavigate()

  React.useEffect(() => {
    if (localStorage.getItem('token')) {
      setIsAuthenticated(true)
      setUser(JSON.parse(localStorage.getItem('user')))
      const user = JSON.parse(localStorage.getItem('user'))
      const path = localStorage.getItem('path')
      if (path && path !== 'undefined') {
        navigate(localStorage.getItem('path'))
      } else if (user?.role === 'client') {
        // fetchClientId(user.email)
        navigate('/')
      } else {
        navigate('/')
      }
    } else {
      return
    }
  }, [])

  return (
    <div className={styles.pageWrapper}>
      <header className={styles.header}>
        <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/22b3c1dd6981d54ea468af96a27727d3479a8b21e50fcd55e5a6cec2ebfb7226?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/22b3c1dd6981d54ea468af96a27727d3479a8b21e50fcd55e5a6cec2ebfb7226?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/22b3c1dd6981d54ea468af96a27727d3479a8b21e50fcd55e5a6cec2ebfb7226?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/22b3c1dd6981d54ea468af96a27727d3479a8b21e50fcd55e5a6cec2ebfb7226?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/22b3c1dd6981d54ea468af96a27727d3479a8b21e50fcd55e5a6cec2ebfb7226?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/22b3c1dd6981d54ea468af96a27727d3479a8b21e50fcd55e5a6cec2ebfb7226?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/22b3c1dd6981d54ea468af96a27727d3479a8b21e50fcd55e5a6cec2ebfb7226?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=2000 2000w"
          className={styles.logo}
          alt="DropSkip logo"
        />
        <button className={styles.menuToggle} onClick={toggleMenu}>
          ☰
        </button>
        <nav className={`${styles.nav} ${menuOpen ? styles.navOpen : ''}`}>
          <div className={styles.dropSkip}>DropSkip™️</div>
          {/* <div className={styles.aboutUs}>About Us</div>
          <div className={styles.technology}>Technology</div>
          <div className={styles.insights}>Insights</div>
          <div className={styles.contact}>Contact</div> */}
          <Link to={'/login'}>
            <div className={styles.contact}>Login</div>
          </Link>
        </nav>
      </header>
      <div className={styles.container}>
        <DropSkipScreen onClickNext={scrollToLogistics} />
        <div ref={logisticsRef}>
          <LogisticsScreen onClickNext={scrollToCost}></LogisticsScreen>
        </div>
        <div ref={costRef}>
          <Cost onClickNext={scrollToInventory} />
        </div>
        <div ref={inventoryRef}>
          <Inventory onClickNext={scrollToAIAgile} />
        </div>
        <div ref={aiAgileRef}>
          <AIDrivenAgile onClickNext={scrollToPartner} />
        </div>
        <div ref={partnerRef} style={{ minHeight: '100%' }}>
          <PartnerComponent></PartnerComponent>
          <Footer></Footer>
        </div>
      </div>
    </div>
  )
}

export default LandingPage

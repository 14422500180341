import React from "react";
import {
  Grid,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  IconButton,
  Dialog,
  Card,
  CardContent,
} from "@mui/material";
import "react-quill/dist/quill.snow.css";
import CloseIcon from "@mui/icons-material/Close";
import CustomTypography from "../../globalStyles/fonts";

const ShippingCostDetailsModal = ({ shippingCost, open, onClose }) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle>{"Shipping Cost Details"}</DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Divider style={{ marginBottom: "10px" }} />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomTypography
                color="#11193F"
                fontSize="14px"
                fontWeight={400}
              >
                Client
              </CustomTypography>
              <TextField
                type="text"
                size="small"
                margin="dense"
                placeholder="Client Name"
                value={shippingCost?.client_id?.name || ""}
                fullWidth
                InputProps={{
                  readOnly: true,
                  style: { color: "#000", backgroundColor: "#f0f0f0" },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTypography
                color={"#11193F"}
                fontSize={14}
                fontWeight={400}
              >
                Shipment Service
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={shippingCost?.shipment_service?.type || ""}
                InputProps={{
                  readOnly: true,
                  style: { color: "#000", backgroundColor: "#f0f0f0" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Card style={{ marginBottom: 16, backgroundColor: "#EEEEEE" }}>
                <CardContent>
                  <Grid
                    container
                    spacing={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Grid item xs={12} sm={4}>
                      <CustomTypography
                        color="#11193F"
                        fontSize="14px"
                        fontWeight={400}
                      >
                        Min Weight
                      </CustomTypography>
                      <TextField
                        size="small"
                        autoFocus
                        margin="dense"
                        variant="outlined"
                        type="text"
                        fullWidth
                        value={shippingCost?.pricing?.min_weight || ""}
                        InputProps={{
                          readOnly: true,
                          style: {
                            color: "#000",
                            backgroundColor: "#f0f0f0",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <CustomTypography
                        color="#11193F"
                        fontSize="14px"
                        fontWeight={400}
                      >
                        Max Weight
                      </CustomTypography>
                      <TextField
                        size="small"
                        autoFocus
                        margin="dense"
                        variant="outlined"
                        type="text"
                        fullWidth
                        value={shippingCost?.pricing?.max_weight || ""}
                        InputProps={{
                          readOnly: true,
                          style: {
                            color: "#000",
                            backgroundColor: "#f0f0f0",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <CustomTypography
                        color="#11193F"
                        fontSize="14px"
                        fontWeight={400}
                      >
                        Price
                      </CustomTypography>
                      <TextField
                        size="small"
                        autoFocus
                        margin="dense"
                        variant="outlined"
                        type="text"
                        fullWidth
                        value={shippingCost?.pricing?.price || ""}
                        InputProps={{
                          readOnly: true,
                          style: {
                            color: "#000",
                            backgroundColor: "#f0f0f0",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          <Button
            onClick={onClose}
            variant="outlined"
            style={{ textTransform: "none" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ShippingCostDetailsModal;

import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { productService } from "../../utils/services/productService";

const UploadStatusChartDialog = ({ open, onClose }) => {
  const [uploadStatusData, setUploadStatusData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedUploadData, setSelectedUploadData] = useState(null);
  const fetchUploadData = async () => {
    try {
      const response = await productService.fetchBulkUploadData();
      setUploadStatusData(response.data); // assuming response.data is an array of upload status objects
    } catch (error) {
      console.error("Fetch failed:", error);
    }
  };
  const fetchSelectedUploadData = async () => {
    try {
      const response = await productService.fetchBulkUploadResult(
        selectedData?._id
      );
      setSelectedUploadData(response.data); // assuming response.data is an array of upload status objects
    } catch (error) {
      console.error("Fetch failed:", error);
    }
  };
  useEffect(() => {
    if (open) {
      fetchUploadData();
    }
  }, [open]);
  useEffect(() => {
    if (selectedData) {
      fetchSelectedUploadData();
    }
  }, [selectedData]);
  console.log(selectedUploadData);
  const COLORS = ["#4CAF50", "#F44336"];

  const CustomTooltip = ({ active, payload, total }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white border border-gray-200 p-2 rounded shadow">
          <p className="font-semibold">{`${payload[0].name}: ${payload[0].value}`}</p>
          <p>{`(${((payload[0].value / total) * 100).toFixed(1)}%)`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Upload Status</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            {uploadStatusData.length > 0 ? (
              uploadStatusData.map((item, index) => {
                const success = item.total_records - item.error;
                const data = [
                  { name: "Success", value: success },
                  { name: "Error", value: item.error },
                ];
                const total = item.total_records;

                return (
                  <Grid item xs={12} sm={6} key={item._id}>
                    <div className="mb-6 w-full">
                      <h3
                        onClick={() => setSelectedData(item)}
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        className="text-center text-lg font-semibold mb-2"
                      >
                        {item.file_name} ({item.type})
                      </h3>
                      <ResponsiveContainer width="100%" height={200}>
                        <PieChart>
                          <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            innerRadius="20%"
                            outerRadius="50%"
                            fill="#8884d8"
                            dataKey="value"
                            labelLine={false}
                          >
                            {data.map((entry, idx) => (
                              <Cell
                                key={`cell-${idx}`}
                                fill={COLORS[idx % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip content={<CustomTooltip total={total} />} />
                          <Legend verticalAlign="bottom" height={36} />
                        </PieChart>
                      </ResponsiveContainer>
                      <div className="text-center mt-4">
                        <p className="text-xl font-bold">
                          Total Uploads: {total}
                        </p>
                        <div
                          style={{ justifyContent: "center" }}
                          className="flex w-full mt-2"
                        >
                          <p className="text-green-600">Success: {success}</p>
                          <p
                            style={{ marginLeft: 10 }}
                            className="text-red-600"
                          >
                            Error: {item.error}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                );
              })
            ) : (
              <p>Loading...</p>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {selectedData && (
        <Dialog
          open={!!selectedData}
          onClose={() => setSelectedData(null)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Details for {selectedData.file_name}</DialogTitle>
          <DialogContent>
            {selectedUploadData.length ? (
              selectedUploadData.map((item) => (
                <div
                  style={{
                    border: "1px  solid",
                    margin: 5,
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  <Typography sx={{ color: "red" }} variant="h6" gutterBottom>
                    Error Message: {item.errorObject.errorMessage}
                  </Typography>

                  <Typography variant="body1">
                    <strong>Product ID:</strong>{" "}
                    {item.errorObject.item.product_id}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Name:</strong> {item.errorObject.item.name}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Description:</strong>{" "}
                    {item.errorObject.item.description}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Supplier:</strong> {item.errorObject.item.vendor}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Status:</strong> {item.errorObject.item.status}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Product Type:</strong>{" "}
                    {item.errorObject.item.product_type}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Client:</strong> {item.errorObject.item.client}
                  </Typography>
                </div>
              ))
            ) : (
              <Typography>No error data available</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSelectedData(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default UploadStatusChartDialog;

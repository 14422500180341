import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  Typography,
  TextField,
  Grid,
  Autocomplete,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { simulationService } from "../../utils/services/simulationService";
import { clientService } from "../../utils/services/clientService";
import ClientAutocomplete from "../clientAutocomplete";

const SimulationUploadModal = ({
  open,
  onClose,
  selectedSimulationData,
  allXlHeaders,
  onAddData,
}) => {
  const [data, setData] = useState(null);
  const [client, setClient] = useState([]);
  const initialData = {
    zip_code: "",
    weight: "",
    price: "",
    weight_unit: "",
    client_id: "",
  };
  const [selectedValue, setSelectedValue] = useState(null);
  const [simulationData, setSimulationData] = useState(initialData);
  const [isClientValid, setIsClientValid] = useState(false);

  const onSubmitData = async () => {
    try {
      if (!isClientValid) {
        toast.error("Please select a client.", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }

      const formData = new FormData();
      formData.append("file", selectedSimulationData);
      formData.append("zip_code", simulationData.zip_code);
      formData.append("weight", simulationData.weight);
      formData.append("weight_unit", simulationData.weight_unit);
      formData.append("price", simulationData.price);
      formData.append("client_id", simulationData.client_id);
      const response = await simulationService.addSimulationData(formData);
      setData(response.data);
      onAddData && onAddData();
    } catch (error) {
      console.error(error);
    }
    setSimulationData(initialData);
    setSelectedValue(null);
    setIsClientValid(false);
    onClose && onClose();
  };
  const fetchClientData = async () => {
    const response = await clientService.fetch();
    setClient(response.data);
  };
  useEffect(() => {
    fetchClientData();
  }, []);

 const handleAutocompleteChange = (newValue) => {
   setSelectedValue(newValue); // Update the selected value
   if (newValue) {
     setSimulationData({
       ...simulationData,
       client_id: newValue._id, // Update the client_id in the simulationData
     });
     setIsClientValid(true); // Mark the client as valid
   } else {
     setSimulationData({
       ...simulationData,
       client_id: "", // Clear the client_id if no client is selected
     });
     setIsClientValid(false); // Mark the client as invalid
   }
 };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose && onClose();
        setSimulationData(initialData);
        setSelectedValue(null);
        setIsClientValid(false);
      }}
    >
      <DialogTitle sx={{ fontSize: 20, fontWeight: "bold" }}>
        {selectedSimulationData?.name}
      </DialogTitle>
      <DialogTitle sx={{ fontSize: 17, color: "grey", fontWeight: "bold" }}>
        Choose the respective value according to the key
      </DialogTitle>
      <DialogContent sx={{ minWidth: 500 }}>
        {[
          {
            label: "Zip Code",
            value: "zip_code",
            onChange: (e) =>
              setSimulationData({
                ...simulationData,
                zip_code: e.target.value,
              }),
          },
          {
            label: "Weight",
            value: "weight",
            onChange: (e) =>
              setSimulationData({ ...simulationData, weight: e.target.value }),
          },
          {
            label: "Weight Unit",
            value: "weight_unit",
            onChange: (e) =>
              setSimulationData({
                ...simulationData,
                weight_unit: e.target.value,
              }),
          },
          {
            label: "Price",
            value: "price",
            onChange: (e) =>
              setSimulationData({ ...simulationData, price: e.target.value }),
          },
        ].map((item, index) => (
          <Grid
            sx={{ padding: 1 }}
            key={index}
            container
            spacing={2}
            alignItems="center"
          >
            <Grid item xs={4}>
              <Typography
                fontWeight={"600"}
                color={"#4e7ef8"}
                variant="subtitle1"
              >
                {item.label}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Select
                defaultValue=""
                size="small"
                fullWidth
                value={simulationData?.[item.value]}
                onChange={(e) => {
                  if (Object.values(simulationData).includes(e.target.value)) {
                    toast.warning(`The item is already chosen`, {
                      position: "bottom-left",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                    });
                  } else {
                    item.onChange(e);
                  }
                }}
                displayEmpty
              >
                {allXlHeaders.map((data, index) => (
                  <MenuItem key={index} value={data}>
                    {data}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        ))}

        <Grid sx={{ padding: 1 }} container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <Typography
              fontWeight={"600"}
              color={"#4e7ef8"}
              variant="subtitle1"
            >
              Choose Client
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <ClientAutocomplete
              selectedClient={selectedValue}
              onClientChange={(newValue) => handleAutocompleteChange(newValue)}
              width={"100%"}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          onClick={() => {
            onClose && onClose();
            setSimulationData(initialData);
            setSelectedValue(null);
            setIsClientValid(false);
          }}
        >
          Cancel
        </Button>
        <Button onClick={onSubmitData} variant="contained">
          Submit
        </Button>
      </DialogActions>
      <ToastContainer />
    </Dialog>
  );
};

export default SimulationUploadModal;

import * as React from 'react'
import styles from './cost-inventory.module.css'

const Cost = ({ onClickNext }) => {
  return (
    <>
      <div className={styles.div}>
        <div className={styles.step1UnlockYourCashLowerFulfillmentCost}>
          Unlock your cash & lower fulfillment cost
        </div>
        <div className={styles.div2}>
          <div className={styles.div3}>
            <div className={styles.column}>
              <div className={styles.traditionalDistribution}>
                Traditional distribution keeps your cash tied up in inventory sitting on the ocean for months.
                That cash could be working harder to attract more customers—especially in today’s economic
                climate. <br />
                <br />
                DropSkip offers a smarter approach, shipping directly from managed fulfillment centers
                strategically located near your suppliers. By leveraging a highly optimized mix of air freight
                and localized ground services, DropSkip delivers your customer orders much faster while
                cutting costs by 25% or more compared to standard domestic fulfillment.
                <br />
                <br />
                <span style={{ fontWeight: 'bold' }}>More cash flow. Lower costs.</span>
                <br />
                <span style={{ fontWeight: 'bold' }}>And there’s more...</span>
              </div>
            </div>
            <div className={styles.column2}>
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a93de1a133b95873ed43871d505e521b815cc8907f3d04a3b8f61ca09ed298de?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a93de1a133b95873ed43871d505e521b815cc8907f3d04a3b8f61ca09ed298de?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a93de1a133b95873ed43871d505e521b815cc8907f3d04a3b8f61ca09ed298de?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a93de1a133b95873ed43871d505e521b815cc8907f3d04a3b8f61ca09ed298de?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a93de1a133b95873ed43871d505e521b815cc8907f3d04a3b8f61ca09ed298de?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a93de1a133b95873ed43871d505e521b815cc8907f3d04a3b8f61ca09ed298de?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a93de1a133b95873ed43871d505e521b815cc8907f3d04a3b8f61ca09ed298de?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a93de1a133b95873ed43871d505e521b815cc8907f3d04a3b8f61ca09ed298de?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
                className={styles.img}
              />
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <img
            onClick={onClickNext}
            style={{ cursor: 'pointer' }}
            className={styles.img3}
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b4cdc6f67dccd27b0c7ac88e7d7c45a88d945beddef7f5e1a5087490baafe24f?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
          />
        </div>
      </div>
    </>
  )
}

export default Cost

import React, { useEffect, useState, useContext } from "react";
import DashboardContainer from "../../components/dashboard-container";
import AddModal from "../../components/add-modal";
import TablePagination from "@mui/material/TablePagination";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Autocomplete,
  Box,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { IconButton } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { productService } from "../../utils/services/productService";
import ProductList from "../../components/product-list";
import AppContext from "../../components/context/context";
import { clientService } from "../../utils/services/clientService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProductUploadModal from "../../components/file-upload-modal";
import CustomTypography from "../../globalStyles/fonts";
import UploadStatusChartDialog from "../../components/upload-status";
import ImportProductModal from "../../components/import-product-modal";
import { useNavigate } from "react-router-dom";

export default function Product() {
  const initialData = {
    name: "",
    description: "",
    images: [],
    status: "",
    product_type: "",
    variants: [],
    //vendor: null,
  };
  const navigate = useNavigate();

  const [showAddModal, setShowAddModal] = useState(false);
  const [productDetails, setProductDetails] = useState(initialData);
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [dataCount, setDataCount] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const [selectedClient, setSelectedClient] = useState({ name: "" });
  const [searchValue, setSearchValue] = useState("");
  const [variants, setVariants] = useState([]);
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [clientFilter, setClientFilter] = useState(null);
  const [viewMode, setViewMode] = useState("list");
  const [filter, setFilter] = useState("All"); // New state for filter
  const clientId = localStorage.getItem("client_id");
  const { user } = useContext(AppContext);
  const [vendor, setVendor] = useState(null);
  const [showUploadStatus, setShowUploadStatus] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);
  const [tempProduct, setTempProduct] = useState([]);
  const fetchClientData = async () => {
    try {
      const response = await clientService.fetch();
      setClientData(response.data);
    } catch (error) {
      console.error("Fetch failed:", error);
    }
  };

  const fetchTempProductData = async () => {
    try {
      const response = await productService.fetchTempProduct(
        rowsPerPage,
        0,
        clientId
      );
      setTempProduct(response.data);
      if (response.data.length) {
        setShowImportModal(true);
      }
    } catch (error) {
      console.error("Fetch failed:", error);
    }
  };

  useEffect(() => {
    fetchClientData();
    if (clientId) fetchTempProductData();
  }, []);

  const handleClientFilterChange = (event, newValue) => {
    setClientFilter(newValue);
    setPage(0);
  };

  const validateForm = () => {
    const errors = {};
    if (!productDetails.name) {
      errors.name = "Product name is required";
    }
    if (!productDetails.currency) {
      errors.currency = "Currency is required";
    }
    if (!productDetails.status) {
      errors.status = "Product status is required";
    }
    let hasValidVariant = false;

    for (let index = 0; index < variants.length; index++) {
      const variant = variants[index];

      // Check if this variant has all the necessary fields
      const isVariantValid =
        variant.option &&
        variant.price &&
        variant.weight_unit &&
        variant.weight &&
        variant.height_unit &&
        variant.height &&
        variant.width_unit &&
        variant.width &&
        variant.length_unit &&
        variant.length &&
        variant.sku &&
        variant.price >= 0 &&
        variant.weight >= 0 &&
        variant.height >= 0 &&
        variant.width >= 0 &&
        variant.length >= 0;

      // If a valid variant is found, set flag to true and break the loop
      if (isVariantValid) {
        hasValidVariant = true;
        break; // Exit the loop completely when a valid variant is found
      }

      // If no valid variant yet, continue setting errors
      if (!variant.option) {
        errors[`option-${index}`] = "Option is required";
      }
      if (!variant.price) {
        errors[`price-${index}`] = "Price is required";
      }
      if (!variant.weight_unit) {
        errors[`weight_unit-${index}`] = "Weight unit is required";
      }
      if (!variant.weight) {
        errors[`weight-${index}`] = "Weight is required";
      }
      if (!variant.height_unit) {
        errors[`height_unit-${index}`] = "Height unit is required";
      }
      if (!variant.height) {
        errors[`height-${index}`] = "Height is required";
      }
      if (!variant.width_unit) {
        errors[`width_unit-${index}`] = "Width unit is required";
      }
      if (!variant.width) {
        errors[`width-${index}`] = "Width is required";
      }
      if (!variant.length_unit) {
        errors[`length_unit-${index}`] = "Length unit is required";
      }
      if (!variant.length) {
        errors[`length-${index}`] = "Length is required";
      }
      if (!variant.sku) {
        errors[`sku-${index}`] = "SKU is required";
      }
      if (variant.price < 0) {
        errors[`price-${index}`] = "Price must be greater than 0";
      }
      if (variant.weight < 0) {
        errors[`weight-${index}`] = "Weight must be greater than 0";
      }
      if (variant.height < 0) {
        errors[`height-${index}`] = "Height must be greater than 0";
      }
      if (variant.width < 0) {
        errors[`width-${index}`] = "Width must be greater than 0";
      }
      if (variant.length < 0) {
        errors[`length-${index}`] = "Length must be greater than 0";
      }
    }

    // If no valid variant is found, show the errors
    if (!hasValidVariant) {
      console.log("Errors:", errors);
    } else {
      console.log("At least one valid variant exists, no errors.");
    }

    if (!selectedClient.name) {
      errors.client = "Client is required";
    }
    if (!vendor) {
      errors.vendor = "Supplier is required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeVendor = (supplier) => {
    console.log("Supplier changed", supplier);
    setVendor(supplier);
  };

  const fetchProductData = async () => {
    setLoading(true);
    try {
      const skip = page * rowsPerPage;
      const response = await productService.fetch(
        rowsPerPage,
        skip,
        clientId ? clientId : clientFilter?._id,
        searchValue,
        filter // Pass the filter to the fetch function
      );
      setProductData(response.data);
      setDataCount(response.total);
    } catch (error) {
      console.error("Fetch failed:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductData();
  }, [page, rowsPerPage, clientFilter, searchValue, filter]);

  const createProduct = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      setLoading(true);
      const client_id = clientId ? clientId : selectedClient?._id;
      await productService.create({
        ...productDetails,
        client_id: client_id,
        variants: variants,
        images: imageUrl,
        description: description,
        vendor: vendor._id,
      });
      toast.success("Product Created successfully!");
      fetchProductData();
      setShowAddModal(false);
      setImageUrl([]);
      setVariants([]);
      setProductDetails(initialData);
      setVendor(null);
      setSelectedClient({ name: "" });
    } catch (error) {
      console.error("Create failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchImportedCatalog = () => {
    let count = 0;
    const interval = setInterval(() => {
      count++;
      if (!tempProduct.length && count < 6) {
        fetchTempProductData();
      } else {
        clearInterval(interval);
      }
    }, 5000);
  };

  const syncProductData = async () => {
    fetchImportedCatalog();
    setLoading(true);
    try {
      const response = await productService.syncProduct({
        client_id: clientId,
        core: true,
      });
      console.log(response);
      toast.success("Synced Successfully");
    } catch (error) {
      console.error("Fetch failed:", error);
    } finally {
      setLoading(false);
    }
  };
  const importProduct = async () => {
    if (selectedOption == "all") {
      setLoading(true);
      try {
        const response = await productService.importAllProduct({
          ids: tempProduct.map((x) => x._id),
        });
        setShowImportModal(false);
        toast.success("Discarded Successfully");
      } catch (error) {
        console.error("Fetch failed:", error);
      } finally {
        setLoading(false);
      }
    } else {
      navigate(`/import-product/${clientId}`);
    }
  };

  const discardAll = async () => {
    setLoading(true);
    try {
      const response = await productService.discardAllTempProduct({
        ids: tempProduct.map((x) => x._id),
      });
      setShowImportModal(false);
      toast.success("Discarded Successfully");
    } catch (error) {
      console.error("Fetch failed:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <DashboardContainer
        showUploadStatus
        onSearchData={(x) => {
          // Check if there’s a search term
          if (x.trim()) {
            setSearchValue(x);
            setPage(0); // Reset to page 0 when there’s a search term
          } else {
            setSearchValue(""); // Clear the search value if empty search term
          }
        }}
        onClickAddButton={() => {
          setShowAddModal(true);
          setFormErrors({});
          setSelectedClient({ name: "" });
          setVariants([]);
          setImageUrl([]);
          setDescription("");
          setVendor(null);
        }}
        header={"Product List"}
        onSelectClient={(e) => {
          setClientFilter(e);
        }}
        selectedClient={clientFilter?.name}
        onHandleUploadButton={() => setOpenUploadModal(true)}
        product
        title={"+ Add Product"}
        setViewMode={setViewMode}
        onClickRefresh={fetchProductData}
        onClickSyncProduct={syncProductData}
      >
        <ImportProductModal
          open={showImportModal}
          setOpen={() => setShowImportModal(!showImportModal)}
          selectedOption={selectedOption}
          setSelectedOption={(e) => setSelectedOption(e)}
          handleConfirm={importProduct}
          handleDiscardAll={discardAll}
        />
        <AddModal
          nameHeader={"Title"}
          onImageUpload={(e) => setImageUrl(e)}
          onSelectClient={(e) => setSelectedClient(e)}
          modalHeader={"Add Product"}
          product
          onClickAdd={createProduct}
          onChangeName={(e) =>
            setProductDetails({ ...productDetails, name: e })
          }
          // onChangeDescription={(e) =>
          //   setProductDetails({ ...productDetails, description: e })
          // }
          onChangeType={(e) =>
            setProductDetails({ ...productDetails, product_type: e })
          }
          handleChangeStatus={(e) =>
            setProductDetails({ ...productDetails, status: e })
          }
          handleClose={() => {
            setShowAddModal(false);
            setSelectedClient({ name: "" });
            setVendor(null);
          }}
          variants={variants}
          setVariants={setVariants}
          open={showAddModal}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          description={description}
          setDescription={setDescription}
          handleChangeCurrency={(e) => {
            setProductDetails({ ...productDetails, currency: e });
          }}
          onChangeVendor={handleChangeVendor}
          title={"Product"}
          vendorValue={vendor}
          setVendorValue={setVendor}
          selectedClientData={selectedClient}
        ></AddModal>
        <ProductUploadModal
          onClickStatus={() => setShowUploadStatus(true)}
          showUploadStatus
          onSuccessUpload={() => fetchProductData()}
          product
          onSubmitUploadData={() => setOpenUploadModal(false)}
          onClose={() => setOpenUploadModal(false)}
          open={openUploadModal}
          title={"Product List"}
        ></ProductUploadModal>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
              color: "white",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        )}
        {/* {productData.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <NoDataImage />
          </div>
        ) : ( */}
        <>
          <ProductList
            data={productData}
            onEditData={fetchProductData}
            onDeleteData={fetchProductData}
            client={clientId}
            viewMode={viewMode}
            filter={filter}
            setFilter={setFilter}
            deleteTitle={"Delete Product"}
          />
          <TablePagination
            slotProps={{
              actions: {
                nextButton: {
                  disabled: dataCount <= rowsPerPage * (page + 1),
                },
              },
            }}
            nextIconButton={
              <IconButton>
                <KeyboardArrowRight />
              </IconButton>
            }
            backIconButton={
              <IconButton>
                <KeyboardArrowLeft />
              </IconButton>
            }
            rowsPerPageOptions={[15, 25, 45, 75, 100]}
            component="div"
            count={dataCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "#ffffff",
              padding: "2px",
              borderTop: "1px solid #dddddd",
            }}
          />
        </>
        {/* )} */}
        <ToastContainer />
        <UploadStatusChartDialog
          onClose={() => setShowUploadStatus(false)}
          open={showUploadStatus}
        ></UploadStatusChartDialog>
      </DashboardContainer>
    </>
  );
}

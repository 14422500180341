import React, { useEffect, useState } from "react";
import DashboardContainer from "../../components/dashboard-container";
import CustomClearanceModal from "../../components/custom-clearance-modal";
import CustomerClearanceList from "../../components/customer-clearance-list";
import { customCleranceService } from "../../utils/services/customCleranceService";
import { Box, CircularProgress } from "@mui/material";
import { ToastContainer } from "react-toastify";

const CustomClearance = () => {
  const [selectedCustomClearance, setSelectedCustomClearance] = useState(null);
  const [customClearanceList, setCustomClearancetList] = useState([]);
  const [viewMode, setViewMode] = useState("list");
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const clientId = localStorage.getItem("client_id");

  const fetchCustomClerance = async () => {
    setLoading(true);
    try {
      const response = await customCleranceService.fetch(
        10,
        0,
        clientId,
        searchValue
      );
      setCustomClearancetList(response.data);
      setLoading(false);
      setDataCount(response?.total);
    } catch (error) {
      console.error("Fetch failed:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCustomClerance();
  }, [page, rowsPerPage,  searchValue]);


  return (
    <DashboardContainer
      hideUploadButton={true}
      hideFilterClient={true}
      header={"Custom Clearance List"}
      title={"+ Pre-alert Mail"}
      setViewMode={setViewMode}
      onSearchData={(x) => {
        if (x.trim()) {
          setSearchValue(x);
          setPage(0);
        } else {
          setSearchValue("");
        }
      }}
      onClickAddButton={() => setOpenModal(true)}
      customClerance={true}
    >
      <CustomClearanceModal
        selectedCustomClearance={selectedCustomClearance}
        handleClose={() => {
          setOpenModal(false);
          setSelectedCustomClearance(null);
        }}
        open={openModal}
        onAddData={fetchCustomClerance}
      ></CustomClearanceModal>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <CustomerClearanceList
          dataCount={dataCount}
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          onDeleteData={fetchCustomClerance}
          onEditData={(e) => {
            setSelectedCustomClearance(e);
            setOpenModal(true);
          }}
          data={customClearanceList}
          viewMode={viewMode}
          selectedCustomClearance={selectedCustomClearance}
          setSelectedCustomClearance={setSelectedCustomClearance}
        />
      )}
      <ToastContainer/>
    </DashboardContainer>
  );
};

export default CustomClearance;

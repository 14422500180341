import React from 'react'
import {
  Grid,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  IconButton,
  Dialog
} from '@mui/material'
import 'react-quill/dist/quill.snow.css'
import CloseIcon from '@mui/icons-material/Close'
import CustomTypography from '../../globalStyles/fonts'

const SupplierDetailsModal = ({ supplier, open, onClose }) => {
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <DialogTitle>{'Carrier Details'}</DialogTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Divider style={{ marginBottom: '10px' }} />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CustomTypography color={'#11193F'} fontSize={14} fontWeight={400}>
                Supplier Name
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={supplier?.name || ''}
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTypography fontSize={15}>Client</CustomTypography>
              <TextField
                sx={{ marginTop: 1, fontSize: 13, fontStyle: 'Poppins' }}
                size="small"
                placeholder={`Client name`}
                //label="Name"
                variant="outlined"
                value={supplier?.client?.business_name || supplier?.client?.name}
                fullWidth
                autoFocus
                margin="dense"
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTypography fontSize={15}>Min Order Quantity</CustomTypography>
              <TextField
                sx={{ marginTop: 1, fontSize: 13, fontStyle: 'Poppins' }}
                size="small"
                placeholder={`Min order quantity`}
                //label="Name"
                variant="outlined"
                value={supplier?.min_order_qty}
                fullWidth
                autoFocus
                margin="dense"
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTypography fontSize={15}>Max Order Quantity</CustomTypography>
              <TextField
                sx={{ marginTop: 1, fontSize: 13, fontStyle: 'Poppins' }}
                size="small"
                placeholder={`Max order quantity`}
                //label="Name"
                variant="outlined"
                value={supplier?.max_order_qty}
                fullWidth
                autoFocus
                margin="dense"
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <CustomTypography fontSize={15}>Email</CustomTypography>
              <TextField
                sx={{ marginTop: 1 }}
                type="email"
                size="small"
                placeholder="Enter Email"
                variant="outlined"
                value={supplier?.email}
                fullWidth
                autoFocus
                margin="dense"
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          <Button onClick={onClose} variant="outlined" style={{ textTransform: 'none' }}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default SupplierDetailsModal

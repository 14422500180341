import React, { useEffect, useState } from 'react'
import { Grid, TextField, FormControl, Select, MenuItem } from '@mui/material'
import CustomTypography from '../../globalStyles/fonts'
import { weightUnits } from '../../utils/weightUnits'
import { distanceUnits } from '../../utils/distanceUnits'

const InputWithUnit = ({
  onChangeText,
  onChangeUnit,
  inputValue,
  fieldName,
  numberType,
  unitType,
  unitValue
}) => {
  const [unitData, setUnitData] = useState([])
  useEffect(() => {
    if (unitType.toLowerCase() === 'weight') {
      return setUnitData(weightUnits)
    }
    if (unitType.toLowerCase() === 'distance') {
      return setUnitData(distanceUnits)
    }
  }, [])
  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      <div style={{ width: '100%' }}>
        <CustomTypography color="#11193F" fontSize="14px" fontWeight={400}>
          {fieldName}
        </CustomTypography>
        <TextField
          size="small"
          placeholder={`Enter ${fieldName}`}
          type={numberType ? 'number' : 'text'}
          value={inputValue}
          onChange={(e) => onChangeText(e.target.value)}
          fullWidth
          margin="dense"
          inputProps={{ min: 0 }}
        />
      </div>

      <div style={{ marginLeft: 10 }}>
        <CustomTypography color="#11193F" fontSize="14px" fontWeight={400}>
          {`Unit`}
        </CustomTypography>
        <FormControl sx={{ minWidth: 70 }} fullWidth size="small" margin="dense">
          <Select value={unitValue} onChange={(e) => onChangeUnit(e.target.value)}>
            {unitData.map((x) => (
              <MenuItem value={`${x.abbreviation}`}>{x.abbreviation}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  )
}

export default InputWithUnit

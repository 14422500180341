import React, { useState, useEffect, useCallback } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { portService } from "../../utils/services/portService"; // Adjust the import according to your project structure
import debounce from "lodash.debounce";

const PortAutocomplete = ({
  selectedPort,
  onPortChange,
  clientId,
  width = 200,
  label
}) => {
  const [allPorts, setAllPorts] = useState([]);
  const [portSearchValue, setPortSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchPortData = useCallback(
    debounce(async (searchQuery, pageNumber) => {
      setLoading(true);
      try {
        const response = await portService.fetch(
          10,
          pageNumber * 10,
          clientId,
          searchQuery
        );

        if (pageNumber === 0) {
          setAllPorts(response.data);
        } else {
          setAllPorts((prevData) => [...prevData, ...response.data]);
        }
        setTotal(response.total || 0);
      } catch (error) {
        console.error("Fetch failed:", error);
      } finally {
        setLoading(false);
      }
    }, 300),
    [clientId]
  );

  useEffect(() => {
    fetchPortData(portSearchValue, page);
  }, [portSearchValue, page, fetchPortData]);

  useEffect(() => {
    if (portSearchValue === "") {
      fetchPortData("", 0); // Fetch all ports when search value is empty
    }
  }, [portSearchValue, fetchPortData]);

  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight ===
      Math.ceil(event.target.scrollTop + event.target.clientHeight);
    if (bottom && allPorts.length < total && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePortChange = (event, value) => {
    if (onPortChange) {
      onPortChange(value);
    }
    // Reset the search value to show all ports
    setPortSearchValue("");
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setPortSearchValue(value);
    setPage(0);
    setAllPorts([]);
  };

  return (
    <Autocomplete
      ListboxProps={{
        onScroll: handleScroll,
      }}
      size="small"
      id="port-select"
      options={allPorts}
      getOptionLabel={(option) => option.name || ""}
      value={selectedPort || null}
      onChange={handlePortChange}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="dense"
          label={label}
          variant="outlined"
          onChange={handleSearchChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      style={{ minWidth: width, marginRight: 10 }}
    />
  );
};

export default PortAutocomplete;

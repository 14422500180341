import React, { useCallback, useContext, useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import { zoneService } from '../../utils/services/zoneService'
import { portService } from '../../utils/services/portService'
import { zipZoneService } from '../../utils/services/zipZoneService'
import { allStates } from '../../utils/allStates.js'
import { clientService } from '../../utils/services/clientService'
import { Divider, Grid, IconButton, Typography, CircularProgress } from '@mui/material'
import AppContext from '../context/context'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import CloseIcon from '@mui/icons-material/Close'
import debounce from 'lodash.debounce'
const ZipZoneModal = ({ open, handleClose, onAddSuccess, selectedZipZoneData, onSelectClient }) => {
  const [source, setSource] = useState('')
  const [destination, setDestination] = useState('')
  const [serviceDay, setServiceDay] = useState('')
  const [zone, setZone] = useState({ zone_name: '' })
  const [port, setPort] = useState({ name: '' })
  const [zones, setZones] = useState([])
  const [ports, setPorts] = useState([])
  const [state, setState] = useState('')
  const [formErrors, setFormErrors] = useState({})
  const [clientData, setClientData] = useState([])
  const { user } = useContext(AppContext)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [portSearchValue, setPortSearchValue] = useState('')
  const [zoneSearchValue, setZoneSearchValue] = useState('')

  const fetchClientData = async () => {
    try {
      const response = await clientService.fetch()
      setClientData(response.data)
    } catch (error) {
      console.error('Fetch failed:', error)
      // Handle fetch failure
    }
  }
  useEffect(() => {
    fetchClientData()
  }, [])
  const initialdata = () => {
    setSource('')
    setDestination('')
    setServiceDay('')
    setZone()
    setPort()
    setFormErrors({})
    setState('')
    setPage(0)
    setZones([])
    setPorts([])
  }
  const clientId = localStorage.getItem('client_id')
  const validateForm = () => {
    const errors = {}
    if (!destination) {
      errors.destination = 'Destination is required'
    } else if (destination < 0) {
      errors.destination = 'Destination should not be nagative'
    }
    if (!serviceDay) {
      errors.serviceDay = 'Service Day is required'
    } else if (isNaN(serviceDay)) {
      errors.serviceDay = 'Service Day must be a number'
    } else if (serviceDay < 0) {
      errors.serviceDay = 'Service Day must be greater than 0'
    }
    if (!state) {
      errors.state = 'State is required'
    }
    if (!zone?.name) {
      errors.zone = 'Zone is required'
    }
    if (!port?.name) {
      errors.port = 'Port is required'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const fetchZoneData = useCallback(
    debounce(async (searchQuery = '') => {
      setLoading(true)
      try {
        let allZones = []
        let pageNumber = 0
        let hasMoreData = true

        while (hasMoreData) {
          const response = await zoneService.fetch(10, pageNumber * 10, clientId, searchQuery)

          if (response.data.length > 0) {
            allZones = [...allZones, ...response.data]
            pageNumber++
          } else {
            hasMoreData = false
          }
        }

        setZones(allZones)
        setTotal(allZones.length)
      } catch (error) {
        console.error('Fetch failed:', error)
      } finally {
        setLoading(false)
      }
    }, 300),
    [clientId]
  )

  useEffect(() => {
    fetchZoneData(zoneSearchValue, page)
  }, [zoneSearchValue, page, fetchZoneData])

  useEffect(() => {
    if (open) {
      setPage(0)
      setZones([])
      fetchZoneData(zoneSearchValue, 0)
    }
  }, [zoneSearchValue, open, fetchZoneData])

  const fetchPortData = useCallback(
    debounce(async (searchQuery = '') => {
      setLoading(true)
      try {
        let allPorts = []
        let pageNumber = 0
        let hasMoreData = true

        while (hasMoreData) {
          const response = await portService.fetch(10, pageNumber * 10, clientId, searchQuery)

          if (response.data.length > 0) {
            allPorts = [...allPorts, ...response.data]
            pageNumber++
          } else {
            hasMoreData = false
          }
        }

        setPorts(allPorts)
        setTotal(allPorts.length)
      } catch (error) {
        console.error('Fetch failed:', error)
      } finally {
        setLoading(false)
      }
    }, 300),
    [clientId]
  )
  useEffect(() => {
    fetchPortData(portSearchValue, page)
  }, [portSearchValue, page, fetchPortData])

  useEffect(() => {
    if (open) {
      setPage(0)
      setPorts([])
      fetchPortData(portSearchValue, 0)
    }
  }, [portSearchValue, open, fetchPortData])

  const handleScroll = (event, type) => {
    if (event?.target) {
      const bottom =
        event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) ||
        event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) - 1 ||
        event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) + 1
      if (bottom && (type === 'port' ? ports.length < total : zones.length < total) && !loading) {
        setPage((prevPage) => prevPage + 1)
      }
    }
  }

  useEffect(() => {
    if (selectedZipZoneData) {
      setSource(selectedZipZoneData.source || '')
      setDestination(selectedZipZoneData.destination || '')
      setServiceDay(selectedZipZoneData.service_day || '')
      setZone({ name: selectedZipZoneData.zone?.name || '' })
      setPort({ name: selectedZipZoneData.port?.name || '' })
      setState(selectedZipZoneData?.state || '')
    }
  }, [selectedZipZoneData])

  const handleSave = async (e) => {
    e.preventDefault()
    if (!validateForm()) return
    try {
      const response = await zipZoneService.create({
        destination: destination,
        state: state,
        service_day: Number(serviceDay),
        zone: zone._id,
        port: port._id
      })
      onAddSuccess && onAddSuccess()

      initialdata()
    } catch (error) {
      console.error('Delete failed:', error)
    }
    initialdata()
    handleClose()
  }

  const handleEditData = async (e) => {
    e.preventDefault()
    if (!validateForm()) return
    try {
      const response = await zipZoneService.edit(
        {
          state: state,
          destination: destination,
          service_day: Number(serviceDay),
          zone: zone._id ? zone._id : selectedZipZoneData.zone?._id,
          port: port._id ? port._id : selectedZipZoneData.port?._id
        },
        selectedZipZoneData._id
      )
      onAddSuccess && onAddSuccess()

      initialdata()
    } catch (error) {
      console.error('Delete failed:', error)
    }
    initialdata()
    handleClose()
  }
  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose && handleClose()
        initialdata()
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle
          id="form-dialog-title"
          style={{
            fontFamily: 'Poppins',
            fontSize: 24,
            fontWeight: 500,
            color: '#000000'
          }}
        >{`${selectedZipZoneData ? 'Edit' : 'Create'} Zip Zone Data`}</DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography className="form-lable-style">Destination</Typography>
            <TextField
              // sx={{ marginBottom: 2, marginTop: 1 }}
              type="number"
              inputProps={{ min: 0 }}
              size="small"
              placeholder="Enter Destination"
              value={destination}
              onChange={(e) => setDestination(e.target.value)}
              fullWidth
              error={!!formErrors.destination}
              helperText={formErrors.destination}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className="form-lable-style">Service Day</Typography>
            <TextField
              inputProps={{ min: 0 }}
              // sx={{ marginBottom: 2 }}
              size="small"
              placeholder="Enter Service Day"
              type="number" // Set type to "number" for numeric input
              value={serviceDay}
              onChange={(e) => setServiceDay(e.target.value)}
              fullWidth
              error={!!formErrors.serviceDay}
              helperText={formErrors.serviceDay}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className="form-lable-style">State</Typography>
            <TextField
              sx={{ marginBottom: 2 }}
              size="small"
              placeholder="Enter State"
              type="text" // Set type to "number" for numeric input
              value={state}
              onChange={(e) => setState(e.target.value)}
              fullWidth
              error={!!formErrors.state}
              helperText={formErrors.state}
            />
          </Grid>
          {/* <Autocomplete
          size="small"
          sx={{ marginBottom: 2 }}
          options={allStates}
          value={selectedZipZoneData ? state : state?.name}
          getOptionLabel={(option) => option.name} // Access the "name" key
          onChange={(event, newValue) => {
            setState(
              newValue ? (selectedZipZoneData ? newValue : newValue) : ""
            ); // Set the "name" property as value
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="State"
              fullWidth
              error={!!formErrors.state}
              helperText={formErrors.state}
            />
          )}
        /> */}
          <Grid item xs={12} sm={6}>
            <Typography className="form-lable-style">Choose Zone</Typography>
            <Autocomplete
              size="small"
              renderOption={(props, option) => <div {...props}>{option.name}</div>}
              options={zones}
              value={zone?.name ? zone : null} // Corrected value handling
              getOptionLabel={(option) => option.name || ''} // Ensure it handles empty values
              onChange={(event, newValue) => setZone(newValue)}
              ListboxProps={{
                onScroll: (event) => handleScroll(event, 'zone')
              }}
              onInputChange={(event, newInputValue) => {
                setZoneSearchValue(newInputValue)
                if (!newInputValue) {
                  fetchZoneData('', 0) // Fetch all zones when input is cleared
                }
              }}
              onOpen={() => {
                fetchZoneData('', 0)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Service"
                  error={!!formErrors.service_type}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className="form-lable-style">Choose Port</Typography>
            <Autocomplete
              size="small"
              renderOption={(props, option) => <div {...props}>{option.name}</div>}
              options={ports}
              value={port?.name ? port : null} // Corrected value handling
              getOptionLabel={(option) => option.name || ''} // Ensure it handles empty values
              onChange={(event, newValue) => setPort(newValue)}
              ListboxProps={{
                onScroll: (event) => handleScroll(event, 'port')
              }}
              onInputChange={(event, newInputValue) => {
                setPortSearchValue(newInputValue)
                if (!newInputValue) {
                  fetchPortData('', 0)
                }
              }}
              onOpen={() => {
                fetchPortData('', 0)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Port"
                  error={!!formErrors.port}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>

          {/* {user.role === "admin" ? (
            <Grid item xs={12} sm={6}>
              <Typography className="form-lable-style">
                Choose Client
              </Typography>
              <Autocomplete
                // sx={{ marginBottom: 2 }}
                size="small"
                value={selectedClient?.name ? selectedClient : { name: "" }}
                onChange={(event, newValue) => {
                  setSelectedClient(newValue);
                  onSelectClient && onSelectClient(newValue);
                }}
                options={clientData}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography variant="body1">{option.name}</Typography>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Client"
                    // margin="dense"
                    error={!!formErrors.selectedClient}
                    helperText={formErrors.selectedClient}
                  />
                )}
              />
            </Grid>
          ) : (
            <></>
          )} */}
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: 15 }}>
        <Button
          style={{
            fontFamily: 'Poppins',
            fontSize: 14,
            fontWeight: 600,
            color: '#007DFF',
            textTransform: 'none'
          }}
          variant="outlined"
          onClick={() => {
            handleClose && handleClose()
            initialdata()
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => (selectedZipZoneData ? handleEditData(e) : handleSave(e))}
          color="primary"
          variant="contained"
          style={{
            color: '#FFFFFF',
            fontFamily: 'Poppins',
            fontSize: 14,
            fontWeight: 600,
            width: 100,
            textTransform: 'none'
          }}
        >
          {selectedZipZoneData ? 'Save' : 'Add'}
        </Button>
      </DialogActions>
      <ToastContainer />
    </Dialog>
  )
}

export default ZipZoneModal

import React, { useEffect, useState, useContext } from 'react'
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
  Box,
  FormHelperText
} from '@mui/material'
import CustomTypography from '../../globalStyles/fonts'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { clientService } from '../../utils/services/clientService'
import { shippingPriceService } from '../../utils/services/shippingPriceService'
import AppContext from '../context/context'
import ClientAutocomplete from '../clientAutocomplete'

const ShipmentPriceModal = ({ open, handleClose, onAddData, selectedShipmentPrice, onSelectClient }) => {
  const [type, setType] = useState('')
  const [selectedClient, setSelectedClient] = useState({
    name: '',
    business_name: ''
  })
  const [priceDetails, setPriceDetails] = useState([
    { min_weight: '', max_weight: '', min_days: '', max_days: '', price: '' }
  ])
  const [clientData, setClientData] = useState([])
  const [formErrors, setFormErrors] = useState({})
  const { user } = useContext(AppContext)
  const clientId = localStorage.getItem('client_id')

  const fetchClientData = async () => {
    try {
      const response = await clientService.fetch()
      setClientData(response.data)
    } catch (error) {
      console.error('Fetch failed:', error)
      // Handle fetch failure
    }
  }

  useEffect(() => {
    fetchClientData()
    setPriceDetails([{ min_weight: '', max_weight: '', min_days: '', max_days: '', price: '' }])
    return () => {
      setFormErrors({})
      setPriceDetails([
        {
          min_weight: '',
          max_weight: '',
          min_days: '',
          max_days: '',
          price: ''
        }
      ])
    }
  }, [])
  const validateForm = () => {
    const errors = {}

    if (!type) {
      errors.type = 'Service Type is required'
    }

    priceDetails.forEach((detail, index) => {
      const minWeight = Number(detail.min_weight)
      const maxWeight = Number(detail.max_weight)
      const minDays = Number(detail.min_days)
      const maxDays = Number(detail.max_days)
      const price = detail.price

      if (minWeight === undefined || isNaN(minWeight)) {
        errors[`min_weight_${index}`] = 'Min Weight is required'
      } else if (minWeight < 0) {
        errors[`min_weight_${index}`] = 'Min Weight must be greater than 0'
      }

      if (maxWeight === undefined || isNaN(maxWeight)) {
        errors[`max_weight_${index}`] = 'Max Weight is required'
      } else if (maxWeight < 0) {
        errors[`max_weight_${index}`] = 'Max Weight must be greater than 0'
      }

      if (minWeight >= 0 && maxWeight >= 0 && minWeight > maxWeight) {
        errors[`min_weight_${index}`] = 'Min Weight must be less than Max Weight'
        errors[`max_weight_${index}`] = 'Max Weight must be greater than Min Weight'
      }

      if (minDays === undefined || isNaN(minDays)) {
        errors[`min_days_${index}`] = 'Min days is required'
      } else if (minDays < 0) {
        errors[`min_days_${index}`] = 'Min Days must be greater than 0'
      }

      if (maxDays === undefined || isNaN(maxDays)) {
        errors[`max_days_${index}`] = 'Max days is required'
      } else if (maxDays < 0) {
        errors[`max_days_${index}`] = 'Max Days must be greater than 0'
      }

      if (minDays >= 0 && maxDays >= 0 && minDays > maxDays) {
        errors[`min_days_${index}`] = 'Min days must be less than Max days'
        errors[`max_days_${index}`] = 'Max days must be greater than Min days'
      }

      if (price === undefined || price === '') {
        errors[`price_${index}`] = 'Price is required'
      } else if (isNaN(price)) {
        errors[`price_${index}`] = 'Price must be a number'
      } else if (Number(price) < 0) {
        errors[`price_${index}`] = 'Price must be greater than 0'
      }
    })

    if (!clientId && !selectedClient?.name) {
      errors.selectedClient = 'Please select Client'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  useEffect(() => {
    if (selectedShipmentPrice) {
      setType(selectedShipmentPrice?.type || '')
      setPriceDetails(
        selectedShipmentPrice?.pricing || [
          {
            min_weight: '',
            max_weight: '',
            min_days: '',
            max_days: '',
            price: ''
          }
        ]
      )
      setSelectedClient({
        name: selectedShipmentPrice?.client_id?.name || '',
        _id: selectedShipmentPrice?.client_id?._id || '',
        business_name: selectedShipmentPrice?.client_id?.business_name || ''
      })
    } else {
      initialData()
    }
  }, [selectedShipmentPrice])

  const initialData = () => {
    setType('')
    setPriceDetails([{ weight: '', price: '' }])
    setSelectedClient(null)
    setFormErrors({})
  }

  const addPriceDetail = () => {
    setPriceDetails([...priceDetails, { weight: '', price: '' }])
  }

  const handlePriceDetailChange = (index, field, value) => {
    const updatedPriceList = [...priceDetails]
    const errors = { ...formErrors }

    // Clear any existing errors for this index
    delete errors[`min_weight_${index}`]
    delete errors[`max_weight_${index}`]

    if (updatedPriceList[index]) {
      updatedPriceList[index][field] = value

      // If field is max_weight, check the next item's min_weight
      if (field === 'max_weight' && index < priceDetails.length - 1) {
        const nextIndex = index + 1
        const nextMinWeight = Number(updatedPriceList[nextIndex].min_weight)

        if (nextMinWeight <= Number(value)) {
          updatedPriceList[nextIndex].min_weight = (Number(value) + 0.1).toFixed(1) // Ensure next min_weight is greater than the current max_weight by 0.1
          errors[`min_weight_${nextIndex}`] = 'Min Weight must be greater than the previous Max Weight'
        } else {
          delete errors[`min_weight_${nextIndex}`]
        }
      }

      // If field is min_weight and it's not the first entry, ensure min_weight is greater than the previous max_weight
      if (field === 'min_weight' && index > 0) {
        const previousMaxWeight = Number(updatedPriceList[index - 1].max_weight)

        if (Number(value) <= previousMaxWeight) {
          updatedPriceList[index][field] = (previousMaxWeight + 0.1).toFixed(1) // Set min_weight to be greater than the previous max_weight by 0.1
          errors[`min_weight_${index}`] = 'Min Weight must be greater than the previous Max Weight'
        } else {
          delete errors[`min_weight_${index}`]
        }
      }

      setPriceDetails(updatedPriceList)
      setFormErrors(errors) // Update the form errors state
    }
  }

  const removePriceDetail = (index) => {
    const updatedPriceDetails = priceDetails.filter((_, i) => i !== index)
    setPriceDetails(updatedPriceDetails)
  }

  const handleEditData = async (e) => {
    e.preventDefault()
    if (!validateForm()) return
    try {
      await shippingPriceService.edit(selectedShipmentPrice._id, {
        type: type,
        pricing: priceDetails,
        client_id: clientId ? clientId : selectedClient?._id
      })
      onAddData && onAddData()
      initialData()
    } catch (e) {
      console.error(e)
    }
    initialData()
    handleClose()
  }

  const handleSave = async (e) => {
    e.preventDefault()
    if (!validateForm()) return
    try {
      const client_id = clientId ? clientId : selectedClient?._id
      await shippingPriceService.create({
        type: type,
        pricing: priceDetails,
        client_id: client_id
      })
      onAddData && onAddData()
      handleClose()
      initialData()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose()
        initialData()
      }}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle
          id="form-dialog-title"
          style={{
            fontFamily: 'Poppins',
            fontSize: 24,
            fontWeight: 500,
            color: '#000000'
          }}
        >
          {selectedShipmentPrice ? 'Edit' : 'Create'} Shipment Options
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <CustomTypography color="#11193F" fontSize="14px" fontWeight={400}>
              Type
            </CustomTypography>
            <TextField
              autoFocus
              size="small"
              placeholder="Enter Type"
              type="text"
              value={type}
              onChange={(e) => setType(e.target.value)}
              fullWidth
              margin="dense"
              error={!!formErrors.type}
              helperText={formErrors.type}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <div style={{ display: 'flex' }}>
              <CustomTypography color="#11193F" fontSize="14px" fontWeight={400} style={{ marginTop: 1 }}>
                Price
              </CustomTypography>
              <IconButton onClick={addPriceDetail}>
                <AddIcon color="primary" />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            {priceDetails?.map((detail, index) => (
              <Card key={detail._id} style={{ marginBottom: 16, backgroundColor: '#EEEEEE' }}>
                <CardContent>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton
                      style={{
                        color: '#D13438',
                        padding: 0,
                        background: '#D1343833',
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 10,
                        boxShadow: '0px 0px 4px 0px #00000040'
                      }}
                      aria-label="delete"
                      onClick={() => removePriceDetail(index)}
                    >
                      <img style={{ height: 15, width: 15 }} alt="delete" src="/delete.png" />
                    </IconButton>
                  </div>
                  <Grid container spacing={2} gap={3} justifyContent={'center'}>
                    <Grid item xs={12} sm={2}>
                      <CustomTypography color="#11193F" fontSize="14px" fontWeight={400}>
                        {'Min Weight (Kg)'}
                      </CustomTypography>
                      <TextField
                        type="number"
                        style={{ backgroundColor: '#FFFFFF' }}
                        size="small"
                        value={detail.min_weight}
                        onChange={(e) => handlePriceDetailChange(index, 'min_weight', e.target.value)}
                        inputProps={{ min: 0, step: 0.1 }}
                        fullWidth
                        error={!!formErrors[`_${index}`]}
                      />
                      {formErrors[`min_weight_${index}`] && (
                        <FormHelperText error>{formErrors[`min_weight_${index}`]}</FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <CustomTypography color="#11193F" fontSize="14px" fontWeight={400}>
                        {'Max Weight (Kg)'}
                      </CustomTypography>
                      <TextField
                        type="number"
                        style={{ backgroundColor: '#FFFFFF' }}
                        size="small"
                        value={detail.max_weight}
                        onChange={(e) => handlePriceDetailChange(index, 'max_weight', e.target.value)}
                        disabled={detail.min_weight == null}
                        inputProps={{
                          min: detail.min_weight,
                          step: 0.1 // Allow decimal input by setting the step to 0.1
                        }}
                        fullWidth
                        error={!!formErrors[`max_weight_${index}`]}
                      />
                      {formErrors[`max_weight_${index}`] && (
                        <FormHelperText error>{formErrors[`max_weight_${index}`]}</FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <CustomTypography color="#11193F" fontSize="14px" fontWeight={400}>
                        Min Days
                      </CustomTypography>
                      <TextField
                        type="number"
                        style={{ backgroundColor: '#FFFFFF' }}
                        size="small"
                        value={detail.min_days}
                        onChange={(e) => handlePriceDetailChange(index, 'min_days', e.target.value)}
                        inputProps={{ min: 0 }}
                        fullWidth
                        error={!!formErrors[`min_days_${index}`]}
                      />
                      {formErrors[`min_days_${index}`] && (
                        <FormHelperText error>{formErrors[`min_days_${index}`]}</FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <CustomTypography color="#11193F" fontSize="14px" fontWeight={400}>
                        Max Days
                      </CustomTypography>
                      <TextField
                        type="number"
                        style={{ backgroundColor: '#FFFFFF' }}
                        size="small"
                        value={detail.max_days}
                        onChange={(e) => handlePriceDetailChange(index, 'max_days', e.target.value)}
                        disabled={detail.min_days == null}
                        inputProps={{ min: detail.min_days }}
                        fullWidth
                        error={!!formErrors[`max_days_${index}`]}
                      />
                      {formErrors[`max_days_${index}`] && (
                        <FormHelperText error>{formErrors[`max_days_${index}`]}</FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <CustomTypography color="#11193F" fontSize="14px" fontWeight={400}>
                        {'Price (USD)'}
                      </CustomTypography>
                      <TextField
                        type="number"
                        style={{ backgroundColor: '#FFFFFF' }}
                        size="small"
                        value={detail.price}
                        onChange={(e) => handlePriceDetailChange(index, 'price', e.target.value)}
                        inputProps={{ min: 0 }}
                        fullWidth
                        error={!!formErrors[`price_${index}`]}
                      />
                      {formErrors[`price_${index}`] && (
                        <FormHelperText error>{formErrors[`price_${index}`]}</FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
          </Grid>
          {user.role === 'admin' ? (
            <Grid item xs={12} sm={12}>
              <CustomTypography
                style={{
                  fontSize: 14,
                  fontFamily: 'Poppins',
                  fontWeight: 400,
                  color: '#11193F'
                }}
              >
                Choose Client
              </CustomTypography>
              {/* <Autocomplete
                size="small"
                value={selectedClient?.name ? selectedClient : { name: '' }}
                onChange={(event, newValue) => {
                  setSelectedClient(newValue)
                  if (onSelectClient) onSelectClient(newValue)
                }}
                options={clientData}
                getOptionLabel={(option) => option?.business_name || option?.name || ''}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography variant="body1">{option?.business_name || option?.name}</Typography>
                  </li>
                )}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      placeholder="Select Client"
                      margin="dense"
                      error={!!formErrors.selectedClient}
                    />
                    {!!formErrors.selectedClient && (
                      <FormHelperText error>{formErrors.selectedClient}</FormHelperText>
                    )}
                  </>
                )}
              /> */}
              <ClientAutocomplete
                selectedClient={selectedClient}
                onClientChange={(client) => {
                  setSelectedClient(client)
                  onSelectClient && onSelectClient(client)
                }}
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
        <Button
          style={{
            fontFamily: 'Poppins',
            fontSize: 14,
            fontWeight: 600,
            color: '#007DFF',
            textTransform: 'none'
          }}
          variant="outlined"
          onClick={() => {
            handleClose()
            initialData()
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => (selectedShipmentPrice ? handleEditData(e) : handleSave(e))}
          color="primary"
          variant="contained"
          style={{
            color: '#FFFFFF',
            fontFamily: 'Poppins',
            fontSize: 14,
            fontWeight: 600,
            width: 100,
            textTransform: 'none'
          }}
        >
          {selectedShipmentPrice ? 'Save' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ShipmentPriceModal

import * as React from 'react'
import styles from './partner.module.css'

const PartnerComponent = () => {
  return (
    <>
      <div className={styles.div}>
        <div className={styles.div2}>
          <div className={styles.div3}>
            <div className={styles.div4}>
              <div className={styles.column}>
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/005dc2f6f0c6bb6b7f9bee5adce23ac2e9d14d3b1f1cad4e8c2a64a1734b240f?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/005dc2f6f0c6bb6b7f9bee5adce23ac2e9d14d3b1f1cad4e8c2a64a1734b240f?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/005dc2f6f0c6bb6b7f9bee5adce23ac2e9d14d3b1f1cad4e8c2a64a1734b240f?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/005dc2f6f0c6bb6b7f9bee5adce23ac2e9d14d3b1f1cad4e8c2a64a1734b240f?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/005dc2f6f0c6bb6b7f9bee5adce23ac2e9d14d3b1f1cad4e8c2a64a1734b240f?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/005dc2f6f0c6bb6b7f9bee5adce23ac2e9d14d3b1f1cad4e8c2a64a1734b240f?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/005dc2f6f0c6bb6b7f9bee5adce23ac2e9d14d3b1f1cad4e8c2a64a1734b240f?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/005dc2f6f0c6bb6b7f9bee5adce23ac2e9d14d3b1f1cad4e8c2a64a1734b240f?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
                  className={styles.img}
                />
              </div>
              <div className={styles.column2}>
                <div className={styles.div5}>
                  <div className={styles.theDropSkipAdvantage}>
                    <span style={{ fontSize: 25, textAlign: 'left', marginLeft: -30, fontFamily: 'Poppins' }}>
                      {/* <span style="font-family: Poppins, sans-serif; font-size: 32px; line-height: 38px; letter-spacing: -0.64px; color: rgba(255,255,255,1);"> */}
                      The DropSkip advantage:
                    </span>
                    <ul
                      style={{
                        textAlign: 'left',
                        width: '100%',
                        fontFamily: 'Poppins',
                        listStyleType: 'disc'
                      }}
                    >
                      <li style={{ fontSize: 20, fontWeight: '400' }}>Revolutionize your cash flow</li>
                      <li style={{ fontSize: 20, fontWeight: '400' }}>
                        Save &gt;25% on your fulfillment/shipping costs
                      </li>
                      <li style={{ fontSize: 20, fontWeight: '400' }}>2x, 3x or more your inventory turns</li>
                      <li style={{ fontSize: 20, fontWeight: '400' }}>
                        Eliminate nearly all overstocks and stock-outs
                      </li>
                    </ul>
                  </div>
                  <div className={styles.button}>Let’s do the math for your business</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.div6}>
          <div className={styles.div7}>
            <div className={styles.aPartnerYouCanTrust}>A Partner You Can Trust</div>
            <div className={styles.step}>
              <div className={styles.step1}>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/b6000630ae6076349a51b82c4d61ed6737502761a8d9fac810ab509755ac9710?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
                  className={styles.img2}
                />
                <div className={styles.title}>
                  <div className={styles.heading}>We been doing this for decades</div>
                  <div className={styles.text}>
                    Our team has a combined 75+ years experience in DTC logistics, as both brand operators and
                    logistics providers
                  </div>
                </div>
              </div>
              <div className={styles.step2}>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/4c2cf52206b565ee1d43942b88e09ae81b2da47ae595a0035e3697c919e592e1?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
                  className={styles.img3}
                />
                <div className={styles.title}>
                  <div className={styles.heading}>We’ll built global networks.</div>
                  <div className={styles.text}>
                    Our team has managed logistics networks with millions of transactions, working with the
                    largest US retail brands
                  </div>
                </div>
              </div>
              <div className={styles.step3}>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/124b3febccda8dae7935ca97428f16d4d8be6781eb380994b9d24fd47711d363?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
                  className={styles.img4}
                />
                <div className={styles.title}>
                  <div className={styles.heading}>We’ve made it easy to get started.</div>
                  <div className={styles.text}>
                    Change is hard. We’ve build a proprietary tech stack to help you try DropSkipping before
                    jumping in.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default PartnerComponent

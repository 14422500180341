// AppContext.js
import { AppBar } from '@mui/material'
import React, { createContext, useState } from 'react'

const AppContext = createContext({})

export const AppProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [showSideBar, setShowSideBar] = useState(false)
  const [currentMenu, setCurrentMenu] = useState({ title: 'Dashboard' })
  const [currentSubMenu, setCurrentSubMenu] = useState({ title: '' })
  const [user, setUser] = useState(null)

  return (
    <AppContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        setShowSideBar,
        showSideBar,
        currentMenu,
        setCurrentMenu,
        currentSubMenu,
        setCurrentSubMenu,
        user,
        setUser
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContext

import * as React from 'react'
import styles from './inventory.module.css'

const Inventory = ({ onClickNext }) => {
  return (
    <>
      <div style={{ backgroundColor: '#1A2631', width: '100vw', minHeight: '100%' }}>
        <div className={styles.lowerDiv}>
          <div className={styles.step2CarryALotLessInventory}>Carry a lot less inventory</div>
          <div className={styles.div4}>
            <img loading="lazy" src="./inventory.png" className={styles.img2} />
            <span className={styles.traditionalText}>
              In traditional ecommerce logistics, you have to predict what your customers will buy, how much,
              and—most consequentially—where the product should be stored to ensure fast, low-cost shipping.
              With today’s volatile and unpredictable customer demand, that level of precision just isn’t
              attainable for most brands.
            </span>
          </div>
        </div>
        <div style={{ padding: 50, display: 'flex', justifyContent: 'center', width: '100%' }}>
          <img
            onClick={onClickNext}
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/849434da7d42a72630c928d730448e74d80c2e2b5d8fba205dcaed8d99a66786?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
            className={styles.img3}
          />
        </div>
      </div>
    </>
  )
}

export default Inventory

import React, { useEffect, useState } from 'react'
import { TextField, InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { isMobile } from '../../globalStyles/mobile'

const SearchBar = ({
  onSearchData,
  searchIconColor = 'inherit',
  backgroundColor = '#F7FAFC',
  borderColor = '#D9D9D9'
}) => {
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    const handler = setTimeout(() => {
      onSearchData(searchTerm)
    }, 500)

    return () => {
      clearTimeout(handler)
    }
  }, [searchTerm])

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  return (
    <TextField
      onChange={handleSearchChange}
      autoComplete="off"
      sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: '12px',
          border: `1px solid ${borderColor}`,
          minWidth: isMobile() ? '90vw' : '25vw',
          background: backgroundColor,
          height: 40
        }
      }}
      variant="outlined"
      placeholder="Search here..."
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon style={{ color: searchIconColor }} />
          </InputAdornment>
        ),
        sx: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent'
          },
          borderRadius: '12px'
        }
      }}
    />
  )
}

export default SearchBar

import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Autocomplete
} from '@mui/material'
import CustomTypography from '../../globalStyles/fonts'
import CloseIcon from '@mui/icons-material/Close'
import { weightPackageService } from '../../utils/services/weightPackageService'
import { wmsService } from '../../utils/services/wmsProvider'
import ClientAutocomplete from '../clientAutocomplete'
import InputWithUnit from '../Input-with-unit'
import { toast } from 'react-toastify'

const WeightPackageModal = ({ open, handleClose, onAddData, selectedWeightPackage }) => {
  const [dimensions, setDimensions] = useState({
    width: '',
    length: '',
    height: '',
    width_unit: '',
    length_unit: '',
    height_unit: ''
  })
  const [weightPackageData, setWeightPackageData] = useState({
    minWeight: '',
    maxWeight: '',
    pkgWeight: '',
    minWeightUnit: '',
    maxWeightUnit: '',
    pkgWeightUnit: ''
  })
  const [warehouses, setWarehouses] = useState([])
  const [selectedClient, setSelectedClient] = useState({
    name: '',
    business_name: '',
    _id: ''
  })
  const [selectedWarehouse, setSelectedWarehouse] = useState(null)
  const [formErrors, setFormErrors] = useState({})

  const validateForm = () => {
    const errors = {}
    if (!dimensions.width) {
      errors.width = 'Width is required'
    } else if (dimensions.width < 0) {
      errors.width = 'Width must be greater than 0'
    }
    if (dimensions?.length_unit < 0) {
      errors.length_unit = 'Length unit is required'
    }
    if (!dimensions.width_unit) {
      errors.width_unit = 'width unit is required'
    }
    if (!dimensions?.height_unit) {
      errors.height_unit = 'height unit is required'
    }
    if (!dimensions.length) {
      errors.length = 'Length is required'
    } else if (dimensions.length < 0) {
      errors.length = 'Length must be greater than 0'
    }
    if (!dimensions.length_unit) {
      errors.length_unit = 'Length unit is required'
    }
    if (!dimensions.height) {
      errors.height = 'Height is required'
    } else if (dimensions.height < 0) {
      errors.height = 'Height must be greater than 0'
    }
    if (weightPackageData?.minWeight < 0) {
      errors.minWeight = 'Min Weight must be greater than 0'
    }
    if (weightPackageData?.maxWeight < 0) {
      errors.maxWeight = 'Max Weight must be greater than 0'
    }
    if (!weightPackageData?.maxWeight) {
      errors.maxWeight = 'Max Weight is required'
    }
    if (!weightPackageData?.minWeight) {
      errors.minWeight = 'Min Weight is required'
    }
    if (
      weightPackageData?.minWeight >= 0 &&
      weightPackageData?.maxWeight >= 0 &&
      weightPackageData?.minWeight > weightPackageData?.maxWeight
    ) {
      errors.minWeight = 'Min Weight must be less than Max Weight'
      errors.maxWeight = 'Max Weight must be greater than Min Weight'
    }
    if (!weightPackageData?.pkgWeight) {
      errors.pkgWeight = 'Package weight is required'
    } else if (weightPackageData?.pkgWeight < 0) {
      errors.pkgWeight = 'Package weight must be greater than 0'
    }
    if (!weightPackageData.pkgWeightUnit) {
      errors.pkgWeightUnit = 'Package weight unit is required'
    }
    if (!weightPackageData.minWeightUnit) {
      errors.minWeightUnit = 'minimun weight unit is required'
    }
    if (!weightPackageData.maxWeightUnit) {
      errors.maxWeightUnit = 'maximun weight unit is required'
    }
    if (!selectedClient.business_name) {
      errors.client = 'Client is required'
    }
    if (!selectedWarehouse) {
      errors.warehouse = 'Warehouse is required'
    }
    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const fetchWarehouses = async (clientId) => {
    if (clientId) {
      try {
        const response = await wmsService.fetch(clientId, 15, 0)
        setWarehouses(response.data)
      } catch (error) {
        console.error('Fetch failed:', error)
      }
    }
  }

  useEffect(() => {
    if (selectedClient) {
      fetchWarehouses(selectedClient._id ?? selectedClient)
    }
  }, [selectedClient])

  useEffect(() => {
    if (selectedWeightPackage) {
      setDimensions({
        ...(selectedWeightPackage?.dimension || {
          width: '',
          length: '',
          height: ''
        }),
        width_unit: selectedWeightPackage?.dimension?.width_unit || '',
        height_unit: selectedWeightPackage?.dimension?.height_unit || '',
        length_unit: selectedWeightPackage?.dimension?.length_unit || ''
      })
      setWeightPackageData({
        ...weightPackageData,
        minWeight: selectedWeightPackage?.min_weight || '',
        maxWeight: selectedWeightPackage?.max_weight || '',
        pkgWeight: selectedWeightPackage?.pkg_weight || '',
        pkgWeightUnit: selectedWeightPackage?.pkg_weight_unit || '',
        minWeightUnit: selectedWeightPackage?.min_weight_unit || '',
        maxWeightUnit: selectedWeightPackage?.max_weight_unit || ''
      })
      setSelectedClient({
        name: selectedWeightPackage?.client_id?.name || '',
        _id: selectedWeightPackage?.client_id?._id || '',
        business_name: selectedWeightPackage?.client_id?.business_name || ''
      })
      setSelectedWarehouse(selectedWeightPackage?.warehouse || { provider: '', _id: '' })
    } else {
      initialData()
    }
  }, [selectedWeightPackage])

  const initialData = () => {
    setDimensions({ width: '', length: '', height: '', width_unit: '', length_unit: '', height_unit: '' })
    setWeightPackageData({
      minWeight: '',
      maxWeight: '',
      pkgWeight: '',
      minWeightUnit: '',
      maxWeightUnit: '',
      pkgWeightUnit: ''
    })
    setSelectedClient({
      name: '',
      business_name: '',
      _id: ''
    })
    setSelectedWarehouse(null)
    setFormErrors({})
  }

  const handleEditData = async (e) => {
    e.preventDefault()
    if (!validateForm()) return
    try {
      await weightPackageService.edit(selectedWeightPackage._id, {
        dimension: dimensions,
        min_weight: weightPackageData?.minWeight,
        min_weight_unit: weightPackageData?.minWeightUnit,
        max_weight: weightPackageData?.maxWeight,
        max_weight_unit: weightPackageData?.maxWeightUnit,
        pkg_weight: weightPackageData?.pkgWeight,
        pkg_weight_unit: weightPackageData?.pkgWeightUnit,
        client_id: localStorage.getItem('client_id') || selectedClient._id,
        warehouse: selectedWarehouse._id
      })
      onAddData && onAddData()
      initialData()
    } catch (e) {
      console.error(e)
    }
    handleClose()
  }

  const handleSave = async (e) => {
    e.preventDefault()
    if (!validateForm()) return
    try {
      await weightPackageService.create({
        dimension: dimensions,
        min_weight: weightPackageData?.minWeight,
        min_weight_unit: weightPackageData?.minWeightUnit,
        max_weight: weightPackageData?.maxWeight,
        max_weight_unit: weightPackageData?.maxWeightUnit,
        pkg_weight: weightPackageData?.pkgWeight,
        pkg_weight_unit: weightPackageData?.pkgWeightUnit,
        client_id: localStorage.getItem('client_id') || selectedClient._id,
        warehouse: selectedWarehouse._id
      })
      onAddData && onAddData()
      initialData()
    } catch (e) {
      console.error(e)
    }
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose()
        initialData()
      }}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle id="form-dialog-title">
          {selectedWeightPackage ? 'Edit' : 'Create'} Weight Package
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          {localStorage.getItem('client_id') ? (
            <></>
          ) : (
            <Grid item xs={12} sm={6}>
              <CustomTypography color="#11193F" fontSize="14px" fontWeight={400}>
                Client
              </CustomTypography>
              <ClientAutocomplete
                selectedClient={selectedClient}
                onClientChange={(client) => {
                  setSelectedClient(client)
                  // onSelectClient && onSelectClient(client);
                }}
                errorMessage={formErrors.client}
              />
            </Grid>
          )}
          <Grid item xs={6} sm={12}>
            <CustomTypography color="#11193F" fontSize="14px" fontWeight={400}>
              Warehouse
            </CustomTypography>
            <Autocomplete
              disabled={!selectedClient?._id || !selectedClient}
              size="small"
              options={warehouses}
              getOptionLabel={(option) => option.provider}
              value={selectedWarehouse}
              onChange={(event, newValue) => {
                setSelectedWarehouse(newValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Warehouse"
                  margin="dense"
                  error={!!formErrors.warehouse}
                  helperText={formErrors.warehouse}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWithUnit
              unitValue={dimensions?.width_unit}
              onChangeUnit={(e) => setDimensions({ ...dimensions, width_unit: e })}
              inputValue={dimensions.width}
              onChangeText={(e) => setDimensions({ ...dimensions, width: e })}
              unitType={'distance'}
              fieldName={'Width'}
              numberType
              errorMessage={formErrors.width}
              unitError={formErrors.width_unit}
            ></InputWithUnit>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWithUnit
              unitValue={dimensions?.length_unit}
              onChangeUnit={(e) => setDimensions({ ...dimensions, length_unit: e })}
              inputValue={dimensions.length}
              onChangeText={(e) => setDimensions({ ...dimensions, length: e })}
              unitType={'distance'}
              fieldName={'Length'}
              numberType
              errorMessage={formErrors.length}
              unitError={formErrors.length_unit}
            ></InputWithUnit>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWithUnit
              unitValue={dimensions?.height_unit}
              onChangeUnit={(e) => setDimensions({ ...dimensions, height_unit: e })}
              inputValue={dimensions.height}
              onChangeText={(e) => setDimensions({ ...dimensions, height: e })}
              unitType={'distance'}
              fieldName={'Height'}
              numberType
              errorMessage={formErrors.height}
              unitError={formErrors.height_unit}
            ></InputWithUnit>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWithUnit
              unitValue={weightPackageData?.minWeightUnit}
              onChangeUnit={(e) => setWeightPackageData({ ...weightPackageData, minWeightUnit: e })}
              inputValue={weightPackageData?.minWeight}
              onChangeText={(e) => setWeightPackageData({ ...weightPackageData, minWeight: e })}
              unitType={'weight'}
              fieldName={'Min Weight'}
              numberType
              errorMessage={formErrors.minWeight}
              unitError={formErrors.minWeightUnit}
            ></InputWithUnit>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWithUnit
              unitValue={weightPackageData?.maxWeightUnit}
              onChangeUnit={(e) => setWeightPackageData({ ...weightPackageData, maxWeightUnit: e })}
              inputValue={weightPackageData?.maxWeight}
              onChangeText={(e) => setWeightPackageData({ ...weightPackageData, maxWeight: e })}
              unitType={'weight'}
              fieldName={'Max Weight'}
              numberType={true}
              errorMessage={formErrors.maxWeight}
              unitError={formErrors.maxWeightUnit}
            ></InputWithUnit>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWithUnit
              unitValue={weightPackageData?.pkgWeightUnit}
              onChangeUnit={(e) => setWeightPackageData({ ...weightPackageData, pkgWeightUnit: e })}
              inputValue={weightPackageData?.pkgWeight}
              onChangeText={(e) => setWeightPackageData({ ...weightPackageData, pkgWeight: e })}
              unitType={'weight'}
              fieldName={'Package Weight'}
              numberType={true}
              errorMessage={formErrors.pkgWeight}
              unitError={formErrors.pkgWeightUnit}
            ></InputWithUnit>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
        <Button
          variant="outlined"
          onClick={() => {
            handleClose()
            initialData()
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => (selectedWeightPackage ? handleEditData(e) : handleSave(e))}
          color="primary"
          variant="contained"
        >
          {selectedWeightPackage ? 'Save' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default WeightPackageModal

import * as React from 'react'
import styles from './drop-skip.module.css'

const DropSkipScreen = ({ onClickNext }) => {
  const text =
    'Keep inventory closer to your suppliers to free up cash flow, lower order costs, and become more agile.'

  const [displayedText, setDisplayedText] = React.useState('')
  const [index, setIndex] = React.useState(0)

  React.useEffect(() => {
    if (index < text.length) {
      const timeout = setTimeout(() => {
        setDisplayedText((prev) => prev + text.charAt(index))
        setIndex(index + 1)
      }, 10) // Adjust the speed here (in milliseconds)

      return () => clearTimeout(timeout)
    }
  }, [index, text])

  return (
    <>
      {/* <img sty alt="image" src="./Hero_Section.png"></img> */}
      <div className={styles.heroSection}>
        <div className={styles.div}>
          <div className={styles.title}>
            <span className={styles.textStyle}>DropSkip</span>
            <span className={styles.textStyle}>™️ makes DTC</span>{' '}
            <span className={styles.profitableText}>Profitable</span>{' '}
            <span className={styles.textStyle}>again.</span>
          </div>
          <div className="animated-text">
            <p
              style={{
                width: '50vw',
                fontFamily: 'Poppins',
                marginTop: '5vh',
                color: '#1E1E1E',
                fontSize: 24,
                animation: 'fadeIn 2s ease-in-out',
                minHeight: 80
              }}
            >
              {displayedText}
            </p>
          </div>
          <div style={{ display: 'flex', width: '100vw', minHeight: 100 }}>
            <img
              style={{ width: '100%', height: 350, objectFit: 'cover', paddingBottom: 25 }}
              src="./dashboardImage.png"
              alt="dashboard"
            ></img>
          </div>
          <div className={styles.learnMore}>
            <p>Learn More</p>
            <img
              onClick={onClickNext}
              style={{ cursor: 'pointer' }}
              className={styles.img}
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/b4cdc6f67dccd27b0c7ac88e7d7c45a88d945beddef7f5e1a5087490baafe24f?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default DropSkipScreen

import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { orderService } from '../../utils/services/orderService'
import CustomTypography from '../../globalStyles/fonts'
import CheckIcon from '@mui/icons-material/Check'
import styles from './order-tracking.module.css'
import AppContext from '../../components/context/context'
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
export default function OrderTrackingHistory() {
  const { id } = useParams()
  const navigate = useNavigate()
  const currentUser = JSON.parse(localStorage.getItem('user'))

  const dummyTrackingDetails = [
    {
      name: 'Ordered',
      sl: 1,
      reached: false,
      subSteps: [],
      details: {
        text: 'Your order has been placed successfully.',
        timeStamp: '16 Aug  2024, 11:37'
      }
    },
    {
      name: 'Accepted',
      sl: 2,
      reached: false,
      subSteps: [],
      details: {
        text: 'Your order has been accepted and is being processed.',
        timeStamp: '16 Aug  2024, 11:37'
      }
    },
    {
      name: 'Confirmed',
      sl: 3,
      reached: false,
      subSteps: [],
      details: {
        text: 'Your order has been confirmed. We’re getting it ready.',
        timeStamp: '16 Aug  2024, 11:37'
      }
    },
    {
      name: 'Fulfilled',
      sl: 4,
      reached: false,
      subSteps: [],
      details: {
        text: 'Your order has been fulfilled and is ready for the next steps.',
        timeStamp: '16 Aug  2024, 11:37'
      }
    },
    { name: 'Shipped', sl: 5, reached: false, subSteps: [] },
    { name: 'In-transit', sl: 6, reached: false, subSteps: [] },
    { name: 'Out for Delivery', sl: 7, reached: false, subSteps: [] },
    { name: 'Delivered', sl: 8, reached: false, subSteps: [] },
    { name: 'Cancelled', sl: 9, reached: false, subSteps: [] }
  ]

  const [selectedOrderData, setSelectedOrderData] = useState(null)
  const [trackData, setTrackData] = useState(dummyTrackingDetails)
  const [currentProgress, setCurrentProgress] = useState(null)
  const [orderTrackData, setOrderTrackData] = useState([])
  const [activeStep, setActiveStep] = useState(null)
  const [drawerOpen, setDrawerOpen] = useState(false)

  const handleStepClick = (stepIndex) => {
    setActiveStep(stepIndex)
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  const setTrackingData = () => {
    const isCancelled = trackData.find((x) => x.name === 'Cancelled')?.reached
    const isConfirmed = trackData.find((x) => x.name === 'Confirmed')?.reached
    const orderTrack = isCancelled
      ? dummyTrackingDetails
      : isConfirmed
      ? dummyTrackingDetails.slice(0, -1)
      : dummyTrackingDetails
    let progress = orderTrack.find((x) => x.name === selectedOrderData?.status)
    setCurrentProgress(progress?.sl)
    setTrackData(
      orderTrack.map((item) => ({
        ...item,
        reached: orderTrackData[item.name]?.length ? true : false,
        subSteps: orderTrackData[item.name]
      }))
    )
  }
  console.log(currentUser)
  const fetchOrder = async () => {
    if (!currentUser?._id) {
      try {
        const order = await orderService.fetchOrderTrailWithoutAuth(id)
        setSelectedOrderData(order.data)
        setOrderTrackData(
          order.data.reduce((acc, item) => {
            const { status } = item
            if (!acc[status]) {
              acc[status] = []
            }
            acc[status].push(item)
            return acc
          }, {})
        )
      } catch (error) {
        console.error('Fetch failed:', error)
      }
    } else {
      try {
        const order = await orderService.fetchOrderTrail(id)
        setSelectedOrderData(order.data)
        setOrderTrackData(
          order.data.reduce((acc, item) => {
            const { status } = item
            if (!acc[status]) {
              acc[status] = []
            }
            acc[status].push(item)
            return acc
          }, {})
        )
      } catch (error) {
        console.error('Fetch failed:', error)
      }
    }
  }
  useEffect(() => {
    if (id) {
      fetchOrder()
    }
  }, [id])

  useEffect(() => {
    if (selectedOrderData) {
      setTrackingData()
    }
  }, [selectedOrderData])
  let orderedDate = new Date(trackData.find((x) => x.name === 'Ordered').subSteps[0]?.createdAt)

  // Add 10 days to the date
  let futureDate = new Date(orderedDate)
  futureDate.setDate(futureDate.getDate() + 10)

  // Format the future date
  let formattedFutureDate = futureDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })
  console.log(trackData)
  console.log(selectedOrderData)
  return (
    <div
      className="scrollableDiv"
      style={{
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'scroll',
        width: '100%',
        height: '100%',
        position: 'relative',
        scrollbarWidth: 'none', // For Firefox
        msOverflowStyle: 'none',
        padding: 20 // F
      }}
    >
      <div className={styles.container}>
        {/* Vertical line */}

        <div
          className={styles.leftContainer}
          style={{
            marginLeft: '30px',
            padding: 15,
            maxHeight: currentUser?._id ? '98%' : '85%',
            display: 'flex',
            flexDirection: 'column',
            borderWidth: 2,
            borderRadius: 8,
            backgroundColor: '#FFF'
          }}
        >
          <header className={styles.trackingHeader}>
            <h1 className={styles.trackingNumber}>
              Tracking Number : <span>{`  #123456789`}</span>
            </h1>
          </header>
          <div className={styles.statusBadge}>
            <div className={styles.badgeContent}>Pending</div>
          </div>
          <section className={styles.orderInfo}>
            <div className={styles.orderDetails}>
              <div className={styles.orderDateWrapper}>
                <span className={styles.orderDateLabel}>Order date:</span>
                <time className={styles.orderDate} dateTime="2024-08-16">
                  {new Date(
                    trackData.find((x) => x.name === 'Ordered').subSteps[0]?.createdAt
                  ).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                  })}
                </time>
              </div>
              <div className={styles.separator} />
              <div className={styles.estimatedDelivery}>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/edeb9666b51e5d1346fc333efedcfbe5d10307d28faf755e4223736077f68f2a?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
                  alt=""
                  className={styles.icon}
                />
                <span
                  className={styles.estimatedDeliveryText}
                >{`Estimated delivery: ${formattedFutureDate}`}</span>
              </div>
            </div>
          </section>
          <div
            style={{
              width: '100%',
              marginTop: 30,
              maxHeight: '99%',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto'
            }}
          >
            {trackData.map((step, index) => (
              <div
                onClick={() => handleStepClick(index)}
                key={step.sl}
                style={{
                  marginBottom: '10px',
                  cursor: 'pointer',
                  position: 'relative',
                  backgroundColor: index === activeStep ? '#E4FFE8' : ''
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '50%',
                      backgroundColor: step.reached ? 'green' : 'white',
                      marginRight: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderWidth: 1,
                      border: !step.reached ? '1px solid #D9D9D9' : ''
                    }}
                  >
                    {step.reached && <CheckIcon style={{ color: '#fff', fontSize: '16px' }} />}
                  </div>
                  <span style={{ color: step.reached ? '#06B217' : '#333333', fontWeight: '600' }}>
                    {step.name}
                  </span>
                </div>
                <div style={{ display: 'flex' }}>
                  {index === trackData?.length - 1 ? (
                    <></>
                  ) : (
                    <div
                      style={{
                        height: 50,
                        marginLeft: 12,
                        marginTop: 5,
                        borderLeft: step.reached ? '1px dotted #06B217' : '1px dotted #757477',
                        width: 2
                      }}
                    ></div>
                  )}
                  <div
                    style={{
                      paddingLeft: '20px',
                      textAlign: 'left',
                      maxHeight: '100px',
                      overflowY: 'auto',
                      marginTop: '8px'
                    }}
                  >
                    {step.details && (
                      <>
                        <CustomTypography style={{ fontSize: 10 }} variant="body2">
                          {step?.details?.text}
                        </CustomTypography>
                        <CustomTypography style={{ marginTop: 1, fontSize: 10 }} variant="caption">
                          {step?.subSteps[0] ? new Date(step?.subSteps[0]?.createdAt).toLocaleString() : ''}
                        </CustomTypography>
                      </>
                    )}
                  </div>
                </div>
                {/* Order details always visible */}
              </div>
            ))}
          </div>
        </div>

        {/* Drawer to display sub-step details */}
        {drawerOpen ? (
          <div
            className={styles.rightContainer}
            style={{
              height: '60%',
              marginLeft: 10,
              borderWidth: 2,
              borderRadius: 8
            }}
          >
            <div
              style={{ position: 'absolute', top: currentUser?._id ? 10 : 0, right: 10, cursor: 'pointer' }}
              onClick={handleDrawerClose}
            >
              <CloseIcon></CloseIcon>
            </div>
            {activeStep !== null && (
              <div style={{ padding: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: 20 }}>
                  <div
                    style={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '50%',
                      backgroundColor: trackData[activeStep].reached ? 'green' : 'white',
                      marginRight: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderWidth: 1,
                      border: !trackData[activeStep].reached ? '1px solid #D9D9D9' : ''
                    }}
                  >
                    {trackData[activeStep].reached && (
                      <CheckIcon style={{ color: '#fff', fontSize: '16px' }} />
                    )}
                  </div>
                  <span
                    style={{
                      color: trackData[activeStep].reached ? '#06B217' : '#333333',
                      fontWeight: '600'
                    }}
                  >
                    {trackData[activeStep].name}
                  </span>
                </div>
                {/* <h6>{trackData[activeStep].name} - Details</h6> */}
                {trackData[activeStep].subSteps?.length > 0 ? (
                  trackData[activeStep].subSteps?.map((step, index) => (
                    <div
                      key={step.sl}
                      style={{
                        marginBottom: '5px',
                        position: 'relative',
                        marginLeft: 40
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          style={{
                            width: '24px',
                            height: '24px',
                            borderRadius: '50%',
                            marginRight: '8px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderWidth: 1,
                            border: !step.reached ? '1px solid #D9D9D9' : ''
                          }}
                        >
                          <div
                            style={{ height: 12, width: 12, borderRadius: 50, backgroundColor: '#06B217' }}
                          ></div>
                        </div>
                        <span style={{ color: step.reached ? '#06B217' : '#333333', fontWeight: '600' }}>
                          {step.order_number}
                        </span>
                      </div>
                      <div style={{ display: 'flex' }}>
                        {index === trackData[activeStep].subSteps?.length - 1 ? (
                          <></>
                        ) : (
                          <div
                            style={{
                              height: 50,
                              marginLeft: 12,
                              marginTop: 5,
                              borderLeft: '1px dotted #06B217',
                              width: 2
                            }}
                          ></div>
                        )}
                        <div
                          style={{
                            paddingLeft: '20px',
                            textAlign: 'left',
                            maxHeight: '100px',
                            overflowY: 'auto',
                            marginTop: '8px'
                          }}
                        >
                          <CustomTypography style={{ fontSize: 10 }} variant="body2">
                            {new Date(step.createdAt).toLocaleString()}
                          </CustomTypography>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No substeps available</p>
                )}
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
        {drawerOpen ? (
          <Dialog
            className={styles.showForMobile}
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>
              Tracking Details
              <IconButton
                aria-label="close"
                onClick={() => setDrawerOpen(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              {activeStep !== null && (
                <div
                  style={{
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                    maxHeight: 500
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', padding: 20 }}>
                    <div
                      style={{
                        width: '24px',
                        height: '24px',
                        borderRadius: '50%',
                        backgroundColor: trackData[activeStep].reached ? 'green' : 'white',
                        marginRight: '8px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderWidth: 1,
                        border: !trackData[activeStep].reached ? '1px solid #D9D9D9' : ''
                      }}
                    >
                      {trackData[activeStep].reached && (
                        <CheckIcon style={{ color: '#fff', fontSize: '16px' }} />
                      )}
                    </div>
                    <span
                      style={{
                        color: trackData[activeStep].reached ? '#06B217' : '#333333',
                        fontWeight: '600'
                      }}
                    >
                      {trackData[activeStep].name}
                    </span>
                  </div>
                  {trackData[activeStep].subSteps?.length > 0 ? (
                    trackData[activeStep].subSteps?.map((step, index) => (
                      <div
                        key={step.sl}
                        style={{
                          marginBottom: '5px',
                          position: 'relative',
                          marginLeft: 40
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div
                            style={{
                              width: '24px',
                              height: '24px',
                              borderRadius: '50%',
                              marginRight: '8px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderWidth: 1,
                              border: !step.reached ? '1px solid #D9D9D9' : ''
                            }}
                          >
                            <div
                              style={{
                                height: 12,
                                width: 12,
                                borderRadius: 50,
                                backgroundColor: '#06B217'
                              }}
                            ></div>
                          </div>
                          <span style={{ color: step.reached ? '#06B217' : '#333333', fontWeight: '600' }}>
                            {step.order_number}
                          </span>
                        </div>
                        <div style={{ display: 'flex' }}>
                          {index === trackData[activeStep].subSteps?.length - 1 ? (
                            <></>
                          ) : (
                            <div
                              style={{
                                height: 50,
                                marginLeft: 12,
                                marginTop: 5,
                                borderLeft: '1px dotted #06B217',
                                width: 2
                              }}
                            ></div>
                          )}
                          <div
                            style={{
                              paddingLeft: '20px',
                              textAlign: 'left',
                              maxHeight: '100px',
                              overflowY: 'auto',
                              marginTop: '8px'
                            }}
                          >
                            <CustomTypography style={{ fontSize: 10 }} variant="body2">
                              {new Date(step.createdAt).toLocaleString()}
                            </CustomTypography>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No substeps available</p>
                  )}
                </div>
              )}
            </DialogContent>
          </Dialog>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

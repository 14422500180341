import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { portLookupService } from "../../utils/services/portLookupService";
import PortLookupTable from "../../components/port-lookup-data-table";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
} from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { isMobile } from "../../globalStyles/mobile";

const PortLookup = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [portLookupData, setPortLookupData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [showPagination, setShowPagination] = useState(false);
  const [dataCount, setDataCount] = useState(rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleSearch = async (order = sortOrder, filter = selectedFilter) => {
    if (searchQuery) {
      setLoading(true);
      setShowPagination(true);
      try {
        const clientId = localStorage.getItem("client_id");
        const response = await portLookupService.fetch(
          searchQuery,
          filter,
          order === "asc" ? 1 : -1,
          rowsPerPage,
          page * rowsPerPage,
          clientId
        );
        if (response) {
          if (response.total === 0) {
            setDataCount(0);
            toast.warning(`No data found for ${searchQuery} zip code`, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setPortLookupData([]);
          } else {
            setDataCount(response.total);
            setPortLookupData(response.data);
          }
        }
      } catch (error) {
        console.error("Error found while searching zip code", error);
      }
      setLoading(false);
    } else {
      toast.warning(`Please enter the Zip Code`, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    if (searchQuery) {
      handleSearch(sortOrder, selectedFilter);
    }
  }, [rowsPerPage, page]);

  const handleSortFilter = (sortField, sortOrder) => {
    setSelectedFilter(sortField);
    setSortOrder(sortOrder);
    handleSearch(sortOrder, sortField);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingTop: 20,
          width: "100%",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <TextField
          sx={{ width: "70%" }}
          size="small"
          label="Enter the zip code"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Button
          sx={{ marginInline: 2 }}
          variant="contained"
          color="primary"
          onClick={() => handleSearch()}
        >
          Search
        </Button>
      </div>
      <div style={{ padding: 15, marginTop: 10 }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
              color: "white",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : portLookupData?.length !== 0 ? (
          <>
          {isMobile()?(
  <div style={{display:"flex",width:"100%"}}>
            <FormControl size="small" style={{ minWidth: 100, marginLeft: 5 }}>
              <InputLabel>Filter by</InputLabel>
              <Select
                value={selectedFilter}
                onChange={(event) => handleSortFilter(event.target.value, sortOrder)}
                label="Filter by"
              >
                <MenuItem value="service_day">Service Day</MenuItem>
                <MenuItem value="price">Price</MenuItem>
              </Select>
            </FormControl>

            <FormControl size="small" style={{ minWidth: 150, marginLeft: 5 }}>
              <InputLabel>Sort Order</InputLabel>
              <Select
                value={sortOrder}
                onChange={(event) =>
                  handleSortFilter(selectedFilter, event.target.value)
                }
                label="Sort Order"
              >
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </FormControl>
            </div>
          ):(
            <></>
          )}
        
            <PortLookupTable
              data={portLookupData}
              sortFilter={handleSortFilter}
              selectedFilter={selectedFilter}
              sortOrder={sortOrder}
            />
            {showPagination && (
              <TablePagination
                component="div"
                count={dataCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={() => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      onClick={(event) => handleChangePage(event, page - 1)}
                      disabled={page === 0}
                      aria-label="previous page"
                    >
                      <KeyboardArrowLeft />
                    </IconButton>
                    <IconButton
                      onClick={(event) => handleChangePage(event, page + 1)}
                      disabled={dataCount <= rowsPerPage * (page + 1)}
                      aria-label="next page"
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                  </div>
                )}
                style={{
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  backgroundColor: "#ffffff",
                  padding: "2px",
                  borderTop: "1px solid #dddddd",
                }}
              />
            )}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <p style={{ fontSize: 25, fontWeight: "bold", color: "grey" }}>
              Enter the zip code to find the nearest port
            </p>
          </div>
        )}
        <ToastContainer />
      </div>
    </div>
  );
};

export default PortLookup;

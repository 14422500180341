import * as React from 'react'
import styles from './agile.module.css'

const AIDrivenAgile = ({ onClickNext }) => {
  return (
    <>
      <div className={styles.div}>
        <div className={styles.div2}>
          <div className={styles.step3UseAiDrivenTechToBecomeAgile}>
            {/* <span style="font-size: 36px; line-height: 40px; color: rgba(255,234,0,1);">STEP 3: </span> */}
            <span>Use AI-driven tech to become agile</span>
            {/* <span style="font-size: 36px;">Use AI-driven tech to become agile</span> */}
          </div>
          <div className={styles.enterprise}>
            Enterprise systems vendors like ERPs and OMSs have been caught flat-footed. There isn’t a great
            software solution to manage this new flow—figuring out how much to manufacture; how to route your
            shipments across air, middle and last mile carriers (while managing visibility and billing across
            dozens of vendors); and most importantly—how to make the experience seamless for consumers.
            <br />
            <br />
            {/* <span style="font-weight: 600; color: rgba(255,255,255,1);"></span> */}
            <span style={{ fontWeight: 'bold' }}>
              That is...until we built our own. We’ve made these purpose-built, AI-driven tools free for any
              client to use.
            </span>
          </div>
          <div className={styles.div3}>
            <div className={styles.div4}>
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/5c696513d18ccacaedb1cf11b41946840cf40a6802e241b71436fb6b586d66b5?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c696513d18ccacaedb1cf11b41946840cf40a6802e241b71436fb6b586d66b5?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c696513d18ccacaedb1cf11b41946840cf40a6802e241b71436fb6b586d66b5?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c696513d18ccacaedb1cf11b41946840cf40a6802e241b71436fb6b586d66b5?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c696513d18ccacaedb1cf11b41946840cf40a6802e241b71436fb6b586d66b5?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c696513d18ccacaedb1cf11b41946840cf40a6802e241b71436fb6b586d66b5?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c696513d18ccacaedb1cf11b41946840cf40a6802e241b71436fb6b586d66b5?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5c696513d18ccacaedb1cf11b41946840cf40a6802e241b71436fb6b586d66b5?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
                className={styles.img}
              />
              <div className={styles.inventoryPlanning}>Inventory Planning</div>
            </div>
            <div className={styles.div5}>
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/07f73cea1cd48a3a9a5ec9a20fc0a193d6795f744f5dd1c21a0484428cd68c58?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/07f73cea1cd48a3a9a5ec9a20fc0a193d6795f744f5dd1c21a0484428cd68c58?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/07f73cea1cd48a3a9a5ec9a20fc0a193d6795f744f5dd1c21a0484428cd68c58?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/07f73cea1cd48a3a9a5ec9a20fc0a193d6795f744f5dd1c21a0484428cd68c58?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/07f73cea1cd48a3a9a5ec9a20fc0a193d6795f744f5dd1c21a0484428cd68c58?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/07f73cea1cd48a3a9a5ec9a20fc0a193d6795f744f5dd1c21a0484428cd68c58?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/07f73cea1cd48a3a9a5ec9a20fc0a193d6795f744f5dd1c21a0484428cd68c58?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/07f73cea1cd48a3a9a5ec9a20fc0a193d6795f744f5dd1c21a0484428cd68c58?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
                className={styles.img2}
              />
              <div className={styles.orderOrchestration}>Order Orchestration</div>
            </div>
            <div className={styles.div6}>
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/7d302f469c995f7b92562222b9283f654720a91fa004e4b5c679fc076a684fc9?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/7d302f469c995f7b92562222b9283f654720a91fa004e4b5c679fc076a684fc9?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7d302f469c995f7b92562222b9283f654720a91fa004e4b5c679fc076a684fc9?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/7d302f469c995f7b92562222b9283f654720a91fa004e4b5c679fc076a684fc9?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/7d302f469c995f7b92562222b9283f654720a91fa004e4b5c679fc076a684fc9?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7d302f469c995f7b92562222b9283f654720a91fa004e4b5c679fc076a684fc9?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/7d302f469c995f7b92562222b9283f654720a91fa004e4b5c679fc076a684fc9?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/7d302f469c995f7b92562222b9283f654720a91fa004e4b5c679fc076a684fc9?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
                className={styles.img3}
              />
              <div className={styles.controlTower}>Control Tower</div>
            </div>
          </div>
          <div className={styles.button}>Learn More about our tech</div>
          <div style={{ padding: 20, display: 'flex', justifyContent: 'center', width: '100%' }}>
            <img
              onClick={onClickNext}
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/849434da7d42a72630c928d730448e74d80c2e2b5d8fba205dcaed8d99a66786?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
              className={styles.arrow}
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default AIDrivenAgile

import React, { useCallback, useContext, useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import { rateCardService } from '../../utils/services/rateCardService'
import { zoneService } from '../../utils/services/zoneService'
import { carrierServiceManagement } from '../../utils/services/serviceManagement'
import debounce from 'lodash.debounce'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { clientService } from '../../utils/services/clientService'
import { Divider, Grid, IconButton, Typography } from '@mui/material'
import AppContext from '../context/context'
import CloseIcon from '@mui/icons-material/Close'
import ClientAutocomplete from '../clientAutocomplete'

const RateCardModal = ({ open, handleClose, onAddSuccess, selectedRateCard, onSelectClient }) => {
  const [serviceType, setServiceType] = useState({ name: '' })
  const [weight, setWeight] = useState('')
  const [price, setPrice] = useState('')
  const [zone, setZone] = useState({ name: '' })
  const [zones, setZones] = useState([])
  const [serviceTypes, setServiceTypes] = useState([])
  const [formErrors, setFormErrors] = useState({})
  const [clientData, setClientData] = useState([])
  const [selectedClient, setSelectedClient] = useState({ name: '', _id: '' })
  const { user } = useContext(AppContext)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [serviceSearchValue, setServiceSearchValue] = useState('')
  const [zoneSearchValue, setZoneSearchValue] = useState('')

  const fetchClientData = async () => {
    try {
      const response = await clientService.fetch()
      setClientData(response.data)
    } catch (error) {
      console.error('Fetch failed:', error)
      // Handle fetch failure
    }
  }
  useEffect(() => {
    fetchClientData()
  }, [])
  const initialdata = () => {
    setPrice('')
    setWeight('')
    setServiceType({ name: '' })
    setFormErrors({})
    setSelectedClient({ name: '' })
    setZone({ name: '' })
  }
  const clientId = localStorage.getItem('client_id')

  const validateForm = () => {
    const errors = {}
    if (!serviceType.name) {
      errors.serviceType = 'Service Type is required'
    }
    if (!weight) {
      errors.weight = 'Weight is required'
    } else if (isNaN(weight)) {
      errors.weight = 'Weight  must be a number'
    } else if (weight < 0) {
      errors.weight = 'Weight  must be greater than 0'
    }
    if (!price) {
      errors.price = 'Price is required'
    } else if (isNaN(price)) {
      errors.price = 'Price  must be a number'
    } else if (price < 0) {
      errors.price = 'Price  must be a greater than 0'
    }
    if (!zone.name) {
      errors.zone = 'Zone is required'
    }
    if (!clientId && !selectedClient?.name) {
      errors.selectedClient = 'Please select Client'
    }

    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }
  const fetchServiceData = useCallback(
    debounce(async (searchQuery = '') => {
      setLoading(true)
      try {
        let allServices = []
        let pageNumber = 0
        let hasMoreData = true

        while (hasMoreData) {
          const response = await carrierServiceManagement.fetch(10, pageNumber * 10, clientId, searchQuery)

          if (response.data.length > 0) {
            allServices = [...allServices, ...response.data]
            pageNumber++
          } else {
            hasMoreData = false
          }
        }

        setServiceTypes(allServices)
        setTotal(allServices.length)
      } catch (error) {
        console.error('Fetch failed:', error)
      } finally {
        setLoading(false)
      }
    }, 300),
    [clientId]
  )
  const fetchZoneData = useCallback(
    debounce(async (searchQuery = '') => {
      setLoading(true)
      try {
        let allZones = []
        let pageNumber = 0
        let hasMoreData = true

        while (hasMoreData) {
          const response = await zoneService.fetch(10, pageNumber * 10, clientId, searchQuery)

          if (response.data.length > 0) {
            allZones = [...allZones, ...response.data]
            pageNumber++
          } else {
            hasMoreData = false
          }
        }

        setZones(allZones)
        setTotal(allZones.length)
      } catch (error) {
        console.error('Fetch failed:', error)
      } finally {
        setLoading(false)
      }
    }, 300),
    [clientId]
  )

  useEffect(() => {
    fetchServiceData(serviceSearchValue, page)
  }, [serviceSearchValue, page, fetchServiceData])

  useEffect(() => {
    fetchZoneData(zoneSearchValue, page)
  }, [zoneSearchValue, page, fetchZoneData])

  useEffect(() => {
    if (open) {
      setPage(0)
      setZones([])
      fetchZoneData(zoneSearchValue, 0)
    }
  }, [zoneSearchValue, open, fetchZoneData])

  useEffect(() => {
    if (open) {
      setPage(0)
      setServiceTypes([])
      fetchServiceData(serviceSearchValue, 0)
    }
  }, [serviceSearchValue, open, fetchServiceData])

  const handleScroll = (event, type) => {
    if (event?.target) {
      const bottom =
        event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) ||
        event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) - 1 ||
        event.target.scrollHeight === Math.ceil(event.target.scrollTop + event.target.clientHeight) + 1
      if (bottom && (type === 'service' ? serviceTypes.length < total : zones.length < total) && !loading) {
        setPage((prevPage) => prevPage + 1)
      }
    }
  }

  const handleSave = async (e) => {
    e.preventDefault()
    if (!validateForm()) return
    try {
      const client_id = clientId ? clientId : selectedClient?._id
      const response = await rateCardService.create({
        service_type: serviceType._id,
        weight: Number(weight),
        price: Number(price),
        zone: zone._id,
        client_id: client_id
      })
      onAddSuccess && onAddSuccess()

      handleClose()
      initialdata()
    } catch (error) {
      console.error('Delete failed:', error)
    }
  }
  const handleEditData = async (e) => {
    e.preventDefault()
    if (!validateForm()) return
    try {
      const client_id = clientId ? clientId : selectedClient?._id
      const response = await rateCardService.edit(
        {
          weight: Number(weight),
          price: Number(price),
          zone: zone._id ? zone._id : selectedRateCard.zone?._id,
          service_type: serviceType._id ? serviceType._id : selectedRateCard.service_type?._id,
          client_id: client_id
        },
        selectedRateCard._id
      )
      onAddSuccess && onAddSuccess()
      initialdata()
    } catch (error) {
      console.error('Delete failed:', error)
    }
    initialdata()
    handleClose()
  }
  useEffect(() => {
    if (selectedRateCard) {
      setPrice(selectedRateCard?.price)
      setWeight(selectedRateCard?.weight)
      setZone({ name: selectedRateCard?.zone?.name })
      setServiceType({ name: selectedRateCard?.service_type?.name })
      setSelectedClient({
        name: selectedRateCard?.client_id?.business_name || selectedRateCard?.client_id?.name,
        _id: selectedRateCard?.client_id?._id
      })
    }
  }, [selectedRateCard])

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose()
        initialdata()
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle
          id="form-dialog-title"
          style={{
            fontFamily: 'Poppins',
            fontSize: 24,
            fontWeight: 500,
            color: '#000000'
          }}
        >{`${selectedRateCard ? 'Ëdit' : 'Create'} Rate Card Data`}</DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography
              style={{
                fontSize: 14,
                fontFamily: 'Poppins',
                fontWeight: 400,
                color: '#11193F'
              }}
            >
              Choose Service Type
            </Typography>
            <Autocomplete
              renderOption={(props, option) => (
                <div {...props}>{`${option.name} (${option.carrier_id?.name})`}</div>
              )}
              size="small"
              // sx={{ marginBottom: 2, marginTop: 1 }}
              options={serviceTypes}
              value={serviceType?.name ? serviceType : { name: '' }}
              getOptionLabel={(option) => option.name} // Access the "name" key
              onChange={(event, newValue) => setServiceType(newValue)}
              ListboxProps={{
                onScroll: handleScroll
              }}
              onInputChange={(event, newInputValue) => {
                setServiceSearchValue(newInputValue)
                if (!newInputValue) {
                  fetchServiceData('', 0)
                }
              }}
              onOpen={() => {
                fetchServiceData('', 0)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Service"
                  // margin="dense"
                  error={!!formErrors.service_type}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              style={{
                fontSize: 14,
                fontFamily: 'Poppins',
                fontWeight: 400,
                color: '#11193F'
              }}
            >
              Weight
            </Typography>
            <TextField
              type="number"
              //  sx={{ marginBottom: 2 }}
              placeholder="Enter Weight"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              fullWidth
              size="small"
              inputProps={{ min: 0 }}
              error={!!formErrors.weight}
              helperText={formErrors.weight}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              style={{
                fontSize: 14,
                fontFamily: 'Poppins',
                fontWeight: 400,
                color: '#11193F'
              }}
            >
              Price
            </Typography>
            <TextField
              type="number"
              //sx={{ marginBottom: 2 }}
              placeholder="Enter Price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              fullWidth
              size="small"
              inputProps={{ min: 0 }}
              error={!!formErrors.price}
              helperText={formErrors.price}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              style={{
                fontSize: 14,
                fontFamily: 'Poppins',
                fontWeight: 400,
                color: '#11193F'
              }}
            >
              Choose Zone
            </Typography>
            <Autocomplete
              renderOption={(props, option) => <div {...props}>{option.name}</div>}
              size="small"
              options={zones}
              value={zone || null}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => setZone(newValue)}
              ListboxProps={{
                onScroll: (event) => handleScroll(event, 'zone')
              }}
              onInputChange={(event, newInputValue) => {
                setZoneSearchValue(newInputValue)
                if (!newInputValue) {
                  fetchZoneData('', 0) // Fetch all zones when input is cleared
                }
              }}
              onOpen={() => {
                fetchZoneData('', 0)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Zone"
                  error={!!formErrors.zone}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>
          {user.role === 'admin' ? (
            <Grid item xs={12} sm={6}>
              <Typography
                // style={{
                //   fontSize: 14,
                //   fontFamily: "Poppins",
                //   fontWeight: 400,
                //   color: "#11193F",
                // }}
                className="form-lable-style"
              >
                Choose Client
              </Typography>
              {/* <Autocomplete
                //  sx={{ marginBottom: 2 }}
                size="small"
                value={selectedClient?.name ? selectedClient : { name: "" }}
                onChange={(event, newValue) => {
                  setSelectedClient(newValue);
                  onSelectClient && onSelectClient(newValue);
                }}
                options={clientData}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography variant="body1">{option.name}</Typography>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Client"
                    margin="dense"
                    error={!!formErrors.selectedClient}
                    helperText={formErrors.selectedClient}
                  />
                )}
              /> */}
              <ClientAutocomplete
                selectedClient={selectedClient}
                onClientChange={(client) => {
                  setSelectedClient(client)
                  onSelectClient && onSelectClient(client)
                }}
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
        <Button
          style={{
            fontFamily: 'Poppins',
            fontSize: 14,
            fontWeight: 600,
            color: '#007DFF',
            textTransform: 'none'
          }}
          variant="outlined"
          onClick={() => {
            handleClose()
            initialdata()
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => (selectedRateCard ? handleEditData(e) : handleSave(e))}
          color="primary"
          variant="contained"
          style={{
            color: '#FFFFFF',
            fontFamily: 'Poppins',
            fontSize: 14,
            fontWeight: 600,
            width: 100,
            textTransform: 'none'
          }}
        >
          {selectedRateCard ? 'Save' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RateCardModal

import React, { useState, useEffect, useCallback } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import debounce from "lodash.debounce";
import { supplierService } from "../../utils/services/supplierService"; 

const SupplierAutocomplete = ({
  selectedSupplier,
  onSupplierChange,
  width = 250,
  label = "Select Supplier",
  clientId,
}) => {
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [supplierSearchValue, setSupplierSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchSupplierData = useCallback(
    debounce(async (searchQuery, pageNumber) => {
      setLoading(true);
      try {
        const response = await supplierService.fetch(
          10,
          pageNumber * 10,
          clientId,
          searchQuery
        );
        if (pageNumber === 0) {
          setAllSuppliers(response.data);
        } else {
          setAllSuppliers((prevSuppliers) => [
            ...prevSuppliers,
            ...response.data,
          ]);
        }
        setTotal(response.total||0);
      } catch (error) {
        console.error("Fetch failed:", error);
      } finally {
        setLoading(false);
      }
    }, 300),
    [clientId]
  );

  useEffect(() => {
    fetchSupplierData(supplierSearchValue, page);
  }, [supplierSearchValue, page, fetchSupplierData]);

  useEffect(() => {
    if (supplierSearchValue === "") {
      fetchSupplierData("", 0); // Fetch all suppliers when search value is empty
    }
  }, [supplierSearchValue, fetchSupplierData]);


  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight ===
      Math.ceil(event.target.scrollTop + event.target.clientHeight);
    if (bottom && allSuppliers.length < total && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };
  const handleSupplierChange = (event, value) => {
    if (onSupplierChange) {
      onSupplierChange(value);
    }
    // Reset the search value to show all suppliers
    setSupplierSearchValue("");
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSupplierSearchValue(value);
    setPage(0);
    setAllSuppliers([]);
  };

  return (
    <Autocomplete
      ListboxProps={{
        onScroll: handleScroll,
      }}
      size="small"
      id="supplier-select"
      options={allSuppliers}
      getOptionLabel={(option) => option.name || ""}
      value={selectedSupplier || null}
      onChange={handleSupplierChange}
      renderInput={(params) => (
        <TextField
          {...params}
           margin="dense"
          label={label}
          variant="outlined"
          onChange={handleSearchChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      style={{ minWidth: width, marginRight: 10 }}
    />
  );
};

export default SupplierAutocomplete;

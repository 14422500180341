export const distanceUnits = [
  { name: 'centimeter', abbreviation: 'cm' },
  { name: 'decimeter', abbreviation: 'dm' },
  { name: 'meter', abbreviation: 'm' },
  { name: 'decameter', abbreviation: 'dam' },
  { name: 'hectometer', abbreviation: 'hm' },
  { name: 'kilometer', abbreviation: 'km' },
  { name: 'inch', abbreviation: 'in' },
  { name: 'foot', abbreviation: 'ft' },
  { name: 'yard', abbreviation: 'yd' },
  { name: 'mile', abbreviation: 'mi' },
  { name: 'nautical mile', abbreviation: 'nmi' }
]

import React from 'react'
import {
  Grid,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  IconButton,
  Dialog
} from '@mui/material'
import 'react-quill/dist/quill.snow.css'
import CloseIcon from '@mui/icons-material/Close'
import CustomTypography from '../../globalStyles/fonts'
import { clientService } from '../../utils/services/clientService'
import { toast } from 'react-toastify'

const ClientDetailsModal = ({ client, open, onClose, onSuccess }) => {
  const [contactCode, mobileNumber] = (() => {
    const parts = (client?.contact || '').split('-')
    return parts.length > 1 ? [parts[0], parts[1]] : ['', parts[0]]
  })()
  const sendThankYouMail = async (e) => {
    e.preventDefault()
    try {
      let response = await clientService.approveClient({
        email: client.email,
        customerName: client.name,
        clientId: client?._id
      })
      if (response) {
        toast.success('Thank you mail sent successfully')
        onSuccess && onSuccess()
        onClose && onClose()
      }
    } catch (e) {
      console.error(e)
      throw e
    }
  }
  const sendRejectMail = async (e) => {
    e.preventDefault()
    try {
      let response = await clientService.rejectClient({
        email: client.email,
        customerName: client.name,
        clientId: client?._id
      })
      if (response) {
        toast.success('Reject mail sent successfully')
        onSuccess && onSuccess()
      }
    } catch (e) {
      console.error(e)
      throw e
    }
  }
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>{'Client Details'}</DialogTitle>
          {!['Pending'].includes(client?.status) ? (
            <></>
          ) : (
            <div style={{ marginTop: 10 }}>
              <Button onClick={sendRejectMail} style={{ backgroundColor: 'red' }} variant="contained">
                Sorry
              </Button>
              <Button
                onClick={sendThankYouMail}
                style={{ marginLeft: 5, backgroundColor: '#28A745' }}
                variant="contained"
              >
                Thank you
              </Button>
              <IconButton>
                <CloseIcon onClick={onClose} />
              </IconButton>
            </div>
          )}
        </div>
        <Divider style={{ marginBottom: '10px' }} />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomTypography color={'#11193F'} fontSize={14} fontWeight={400}>
                Client Name
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={client?.name || ''}
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTypography color={'#11193F'} fontSize={14} fontWeight={400}>
                Email
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={client?.email || ''}
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <CustomTypography color={'#11193F'} fontSize={14} fontWeight={400}>
                Country Code
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={contactCode || ''}
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomTypography color={'#11193F'} fontSize={14} fontWeight={400}>
                Mobile Number
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={mobileNumber || ''}
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTypography color={'#11193F'} fontSize={14} fontWeight={400}>
                Business Name
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={client?.business_name || ''}
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTypography color={'#11193F'} fontSize={14} fontWeight={400}>
                Country
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={client?.country || ''}
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTypography color={'#11193F'} fontSize={14} fontWeight={400}>
                Company Website
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={client?.company_website || ''}
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTypography color={'#11193F'} fontSize={14} fontWeight={400}>
                Order Volume per month
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={client?.order_volume || ''}
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTypography color={'#11193F'} fontSize={14} fontWeight={400}>
                Product Source
              </CustomTypography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={client?.product_source?.join(', ') || ''}
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <CustomTypography color={'#11193F'} fontSize={16} fontWeight={400}>
                Describe your best product
              </CustomTypography>
              <textarea
                style={{ color: '#000', backgroundColor: '#f0f0f0' }}
                disabled={true}
                value={client?.product_description}
                rows="4"
                cols="50"
                // placeholder="Type here..."
              ></textarea>
              {/* <TextArea
                size="middle"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={client?.product_description}
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              /> */}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          <Button onClick={onClose} variant="outlined" style={{ textTransform: 'none' }}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default ClientDetailsModal

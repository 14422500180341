import React, { useEffect, useState, useCallback } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { rateLookupService } from "../../utils/services/rateLookupService";
import RateLookupTable from "../../components/rate-lookup-table";
import {
  Box,
  CircularProgress,
  TablePagination,
  IconButton,
} from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Breadcrumbs from "../../components/breadcrumbs";

const RateLookup = () => {
  const [zipCode, setZipCode] = useState("");
  const [searchWeight, setSearchWeight] = useState("");
  const [rateLookupData, setRateLookupData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [showPagination, setShowPagination] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [zone, setZone] = useState("");
  const [selectedPort, setSelectedPort] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = async (
    port = selectedPort,
    filter = selectedFilter,
    order = sortOrder
  ) => {
    if (searchWeight && zipCode) {
      setShowPagination(true);
      setLoading(true);
      try {
        const clientId = localStorage.getItem("client_id");
        const response = await rateLookupService.fetch(
          {
            weight: Number(searchWeight),
            destination: zipCode,
            limit: rowsPerPage,
            skip: page * rowsPerPage,
          },
          port,
          filter === "price" ? order : null,
          filter === "service_day" ? order : null,
          clientId
        );

        setLoading(false);
        if (response) {
          if (response?.length === 0) {
            toast.warning(`No data found for ${zipCode} zip code`, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
          setRateLookupData(response);
          setDataCount(response.length);
        }
      } catch (error) {
        console.error("Error found while searching zip code", error);
        setLoading(false);
      }
    } else {
      toast.warning(`Please enter the fields`, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };

  const handleSortFilter = (sortField, sortOrder, port) => {
    setSelectedFilter(sortField);
    setSortOrder(sortOrder);
    handleSearch(port, sortField, sortOrder);
  };

  return (
    <>
    
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: 20,
            width: "100%",
            justifyContent: "flex-end",
            gap: 10,
          }}
        >
          <TextField
            sx={{ width: "40%", marginLeft: 3 }}
            size="small"
            label="Enter the zip code"
            variant="outlined"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
          />
          <TextField
            sx={{ width: "40%" }}
            size="small"
            label="Enter the weight"
            variant="outlined"
            type="number"
            value={searchWeight}
            onChange={(e) => setSearchWeight(e.target.value)}
          />
          <Button
            sx={{ marginInline: 2 }}
            variant="contained"
            color="primary"
            onClick={() => handleSearch()}
          >
            Search
          </Button>
        </div>
        <div style={{ padding: 15 }}>
          {rateLookupData?.length ? (
            <RateLookupTable
              data={rateLookupData}
              onFilterPort={handleSearch}
              sortFilter={handleSortFilter}
            />
          ) : loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <p style={{ fontSize: 25, fontWeight: "bold", color: "grey" }}>
                Enter the weight and zip code to find the reduced rate
              </p>
            </div>
          )}
          <ToastContainer />
        </div>
        {showPagination && (
          <TablePagination
            slotProps={{
              actions: {
                nextButton: {
                  disabled: dataCount < rowsPerPage,
                },
              },
            }}
            nextIconButton={
              <IconButton>
                <KeyboardArrowRight />
              </IconButton>
            }
            backIconButton={
              <IconButton>
                <KeyboardArrowLeft />
              </IconButton>
            }
            rowsPerPageOptions={[15, 25, 45, 75, 100]}
            component="div"
            count={dataCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "#ffffff",
              padding: "2px",
              borderTop: "1px solid #dddddd",
            }}
          />
        )}
      </div>
    </>
  );
};

export default RateLookup;

import React, { useState, useEffect, useCallback } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import debounce from "lodash/debounce";
import { zoneService } from "../../utils/services/zoneService"; 

const ZoneAutocomplete = ({
  onZoneChange,
  selectedZone,
  clientId,
  width = 200,
  label,
}) => {
  const [zones, setZones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [zoneSearchValue, setZoneSearchValue] = useState("");
  const [page, setPage] = useState(0);

  const fetchZoneData = useCallback(
    debounce(async (searchQuery, pageNumber) => {
      setLoading(true);
      try {
        const response = await zoneService.fetch(
          10,
          pageNumber * 10,
          clientId,
          searchQuery
        );

        if (pageNumber === 0) {
          setZones(response.data);
        } else {
          setZones((prevData) => [...prevData, ...response.data]);
        }
        setTotal(response.total || 0);
      } catch (error) {
        console.error("Fetch failed:", error);
      } finally {
        setLoading(false);
      }
    }, 300),
    [clientId]
  );

  useEffect(() => {
    fetchZoneData(zoneSearchValue, page);
  }, [zoneSearchValue, page, fetchZoneData]);

  useEffect(() => {
    if (zoneSearchValue === "") {
      fetchZoneData("", 0); 
    }
  }, [zoneSearchValue, fetchZoneData]);

  const handleZoneSearchChange = (event) => {
    const value = event.target.value;
    setZoneSearchValue(value);
    setPage(0);
    setZones([]); 
  };

  const handleZoneFilterValue = (event, value) => {
    if (onZoneChange) {
      onZoneChange(value);
    }
    setZoneSearchValue("");
    setPage(0); 
  };

  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight ===
      Math.ceil(event.target.scrollTop + event.target.clientHeight);
    if (bottom && zones.length < total && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <Autocomplete
      fullWidth
      size="small"
      renderOption={(props, option) => <div {...props}>{option.name}</div>}
      options={zones}
      value={selectedZone?.name ? selectedZone : null}
      getOptionLabel={(option) => option.name || ""}
      onChange={handleZoneFilterValue}
      ListboxProps={{
        onScroll: handleScroll,
      }}
      onInputChange={handleZoneSearchChange}
      onOpen={() => {
        fetchZoneData("", 0);
      }}
      isOptionEqualToValue={(option, value) => option.name === value?.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin="dense"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          style={{ width }}
        />
      )}
      style={{ maxWidth: width }}
    />
  );
};

export default ZoneAutocomplete;

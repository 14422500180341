import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Box,
} from "@mui/material";

function ImportProductModal({
  open,
  setOpen,
  selectedOption,
  setSelectedOption,
  handleConfirm,
  handleDiscardAll
}) {

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          padding: 10,
          borderRadius: 10,
          width: 400,
        },
      }}
      open={open}
    >
      <div style={{ marginLeft: 10, marginTop: 10, fontFamily: "Poppins" }}>
        <div style={{ fontWeight: "bold", fontSize: 16 }}>Import Product</div>
        <div style={{ fontSize: 14 }}>
          Select products you wish to sync and display
        </div>
      </div>

      <DialogContent>
        <FormControl component="fieldset">
          <RadioGroup
            style={{ fontFamily: "Poppins" }}
            value={selectedOption}
            onChange={handleOptionChange}
          >
            <FormControlLabel
              value="all"
              control={<Radio />}
              label="All Product"
            />
            <FormControlLabel
              value="selected"
              control={<Radio />}
              label="Only Selected Ones"
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDiscardAll} variant="outlined">
          Discard All
        </Button>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImportProductModal;

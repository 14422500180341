import * as React from 'react'
import styles from './logistics.module.css'

const LogisticsScreen = ({ onClickNext }) => {
  return (
    <>
      <div className={styles.div}>
        <div className={styles.directToConsumerDtcLogisticsIsBeingDisrupted}>
          Direct-to-consumer (DTC) logistics is being disrupted.
        </div>
        <div className={styles.div2}>
          <div className={styles.div3}>
            <div className={styles.column}>
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c88e8eb21998efb8b10fa35fb0fff302123061a95e039c495567a7cce7656545?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c88e8eb21998efb8b10fa35fb0fff302123061a95e039c495567a7cce7656545?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c88e8eb21998efb8b10fa35fb0fff302123061a95e039c495567a7cce7656545?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c88e8eb21998efb8b10fa35fb0fff302123061a95e039c495567a7cce7656545?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c88e8eb21998efb8b10fa35fb0fff302123061a95e039c495567a7cce7656545?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c88e8eb21998efb8b10fa35fb0fff302123061a95e039c495567a7cce7656545?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c88e8eb21998efb8b10fa35fb0fff302123061a95e039c495567a7cce7656545?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c88e8eb21998efb8b10fa35fb0fff302123061a95e039c495567a7cce7656545?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
                className={styles.img}
              />
            </div>
            <div className={styles.column2}>
              <div className={styles.theAstromical}>
                <span style={{ fontWeight: 'bold' }}>
                  The astronomical growth of low-cost marketplaces like Shein and Temu are only the tip of the
                  iceberg.
                </span>{' '}
                Thanks to favorable duty regulations (Section 321, et al) many US brands are fulfilling their
                DTC orders directly from warehouses in Asia (China, Vietnam, India), Mexico, and Canada.{' '}
                <br />
                <br />
                This trend has become so profitable and advantageous for brands that even Amazon{' '}
                <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                  recently entered the game
                </span>
                ...except they can’t integrate it into their core marketplace because it doesn’t work with
                Prime delivery. <br />
                <br />
                <span style={{ fontWeight: 'bold', textAlign: 'left' }}>Game on.</span>
              </div>
            </div>
          </div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/c00163d9fe2f05b967b699bcc66178a8861f6c7f366cc68f562014d4411b922c?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
          className={styles.img2}
        />
        <div className={styles.learnHowDropSkipRedefinesTheDtcLogisticsModel}>
          Learn how DropSkip <span style={{ color: '#FFEA00' }}>REDEFINES</span> THE DTC LOGISTICS MODEL
        </div>
        <img
          onClick={onClickNext}
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/849434da7d42a72630c928d730448e74d80c2e2b5d8fba205dcaed8d99a66786?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
          className={styles.img3}
        />
        <div style={{ paddingBottom: 30 }}></div>
      </div>
    </>
  )
}
export default LogisticsScreen

export const weightUnits = [
  { name: 'milligram', abbreviation: 'mg' },
  { name: 'centigram', abbreviation: 'cg' },
  { name: 'decigram', abbreviation: 'dg' },
  { name: 'gram', abbreviation: 'g' },
  { name: 'dekagram', abbreviation: 'dag' },
  { name: 'hectogram', abbreviation: 'hg' },
  { name: 'kilogram', abbreviation: 'kg' },
  { name: 'metric ton', abbreviation: 't' },
  { name: 'ounce', abbreviation: 'oz' },
  { name: 'pound', abbreviation: 'lb' },
  { name: 'stone', abbreviation: 'st' },
  { name: 'short ton', abbreviation: 'ton' },
  { name: 'long ton', abbreviation: 'ton' }
]

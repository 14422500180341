import React, { useState, useEffect, useContext } from 'react'
import { clientService } from '../../utils/services/clientService'
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { shippingCostService } from '../../utils/services/shippingCostService'
import CustomTypography from '../../globalStyles/fonts'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AppContext from '../context/context'

const EstimateModal = ({ open, handleClose }) => {
  const [clientData, setClientData] = useState([])
  const [weight, setWeight] = useState('')
  const [selectedClient, setSelectedClient] = useState({ name: '' })
  const [filteredData, setFilteredData] = useState([])
  const [errors, setErrors] = useState({})

  const { user } = useContext(AppContext)

  const fetchClientData = async () => {
    try {
      const response = await clientService.fetch()
      setClientData(response.data)
    } catch (error) {
      console.error('Fetch failed:', error)
      // Handle fetch failure
    }
  }

  useEffect(() => {
    fetchClientData()
  }, [])

  const initialData = () => {
    setWeight('')
    setSelectedClient({ name: '' })
    setFilteredData([])
    setErrors({})
  }
  const validateForm = () => {
    let formErrors = {}
    if (!selectedClient || !selectedClient._id) {
      formErrors.client = 'Please select a client.'
    }
    if (!weight) {
      formErrors.weight = 'Please enter the weight.'
    }
    return formErrors
  }
  const handleEstimate = async () => {
    const formErrors = validateForm()
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors)
      return
    }
    try {
      const response = await shippingCostService.fetch('', '', selectedClient._id)
      const services = response.data
      const filtered = services.filter(
        (service) => weight >= service.pricing.min_weight && weight <= service.pricing.max_weight
      )
      setFilteredData(filtered)
      setErrors({})

      if (filtered.length === 0) {
        toast.info(`No data is available for the given client`)
      }
    } catch (error) {
      console.error('Fetch failed:', error)
      // Handle fetch failure
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          handleClose()
          initialData()
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle
            id="form-dialog-title"
            style={{
              fontFamily: 'Poppins',
              fontSize: 24,
              fontWeight: 500,
              color: '#000000'
            }}
          >
            {'Estimate Price (USD)'}
          </DialogTitle>
          <IconButton
            onClick={() => {
              handleClose()
              initialData()
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Divider style={{ marginBottom: 10 }} />

        <DialogContent>
          <Grid container spacing={2}>
            {user?.role == 'admin' ? (
              <Grid item xs={12} sm={6}>
                <CustomTypography
                  style={{
                    fontSize: 14,
                    fontFamily: 'Poppins',
                    fontWeight: 400,
                    color: '#11193F'
                  }}
                >
                  Choose Client
                </CustomTypography>
                <Autocomplete
                  size="small"
                  value={selectedClient?.name ? selectedClient : { name: '' }}
                  onChange={(event, newValue) => {
                    setSelectedClient(newValue)
                  }}
                  options={clientData}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Typography variant="body1">{option.name}</Typography>
                    </li>
                  )}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        placeholder="Select Client"
                        margin="dense"
                        error={!!errors.client}
                        helperText={errors.client}
                      />
                    </>
                  )}
                />
              </Grid>
            ) : (
              <></>
            )}

            <Grid item xs={12} sm={user?.role == 'admin' ? 6 : 12}>
              <Typography
                style={{
                  fontSize: 14,
                  fontFamily: 'Poppins',
                  fontWeight: 400,
                  color: '#11193F'
                }}
              >
                {'Weight (Kg)'}
              </Typography>
              <TextField
                type="number"
                placeholder="Enter Weight"
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
                fullWidth
                size="small"
                inputProps={{ min: 0 }}
                margin="dense"
                sx={{ marginTop: 1 }}
                error={!!errors.weight}
                helperText={errors.weight}
              />
            </Grid>
          </Grid>

          {filteredData.length > 0 ? (
            <div style={{ marginTop: 20 }}>
              <Typography variant="h6">Estimated Price Details</Typography>
              <TableContainer
                sx={{
                  maxHeight: '400px',
                  overflowY: 'auto',
                  '&::WebkitScrollbar': {
                    width: '5px'
                  },
                  '&::WebkitScrollbar-track': {
                    backgroundColor: '#D9D9D9',
                    borderRadius: 14
                  },
                  '&::WebkitScrollbar-thumb': {
                    backgroundColor: '#273894',
                    borderRadius: '6px',
                    '&:hover': {
                      backgroundColor: '#555'
                    }
                  },
                  marginTop: 2
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          backgroundColor: '#EEEEEE',
                          borderRight: '1px solid #9F9F9F',
                          width: '25%',
                          border: '1px solid #9F9F9F',
                          borderRadius: 0,
                          textAlign: 'center'
                        }}
                      >
                        <CustomTypography fontSize={14}>Service Type</CustomTypography>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: '#EEEEEE',
                          borderRight: '1px solid #9F9F9F',
                          width: '25%',
                          border: '1px solid #9F9F9F',
                          textAlign: 'center'
                        }}
                      >
                        <CustomTypography fontSize={14}>{'Price (USD)'}</CustomTypography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData.map((service, index) => (
                      <TableRow key={index}>
                        <TableCell
                          style={{
                            borderRight: '1px solid #9F9F9F',
                            border: '1px solid #9F9F9F',
                            maxWidth: 350,
                            textAlign: 'center'
                          }}
                        >
                          <CustomTypography multiline fontSize={14}>
                            {service.shipment_service.type}
                          </CustomTypography>
                        </TableCell>
                        <TableCell
                          style={{
                            borderRight: '1px solid #9F9F9F',
                            border: '1px solid #9F9F9F',
                            textAlign: 'center'
                          }}
                        >
                          <CustomTypography multiline fontSize={14}>
                            {service.pricing.price}
                          </CustomTypography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : null}
        </DialogContent>

        <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
          <Button
            style={{
              fontFamily: 'Poppins',
              fontSize: 14,
              fontWeight: 600,
              color: '#007DFF',
              textTransform: 'none'
            }}
            variant="outlined"
            onClick={() => {
              handleClose()
              initialData()
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleEstimate}
            color="primary"
            variant="contained"
            style={{
              color: '#FFFFFF',
              fontFamily: 'Poppins',
              fontSize: 14,
              fontWeight: 600,
              width: 100,
              textTransform: 'none'
            }}
          >
            Estimate
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </div>
  )
}

export default EstimateModal

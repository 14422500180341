// axiosInstance.js
import axios from "axios";
import config from "../config";

const axiosInstance = axios.create({
  baseURL: config.baseURL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Get the token from local storage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle 401 Unauthorized errors
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      // Handle unauthorized access
      console.log("Unauthorized access", error);
      localStorage.clear();
      if (window?.location?.pathname !== "/login") {
        window.location.href = "/login";
      }
    } else if (status === 404) {
      // Handle not found errors
      console.log("Post not found");
    } else {
      // Handle other errors
      console.error("An error occurred:", error);
    }

    return Promise.reject(error);
  }
);
// Utility function to handle unauthorized access

export default axiosInstance;

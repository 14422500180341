import { useLocation } from 'react-router-dom'

const getCurrentPageObject = (data, currentPath) => {
  // Helper function to recursively search in subNav
  const findPageObject = (items) => {
    for (const item of items) {
      if (item.path && item.path === currentPath) {
        return item
      }

      // If subNav exists, recursively search
      if (item.subNav) {
        const found = findPageObject(item.subNav)
        if (found) {
          return found
        }
      }
    }
    return null
  }

  return findPageObject(data)
}

const useCurrentPage = (data) => {
  const location = useLocation()
  const currentPath = location.pathname

  const currentPageObject = getCurrentPageObject(data, currentPath)
  console.log(currentPageObject)
  return currentPageObject
}

export default useCurrentPage

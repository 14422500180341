import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import CloseIcon from "@mui/icons-material/Close";
import AppContext from "../context/context";
import { toast } from "react-toastify";
import { customProviderService } from "../../utils/services/customProviderService";

const CustomProviderModal = ({
  open,
  handleClose,
  onAddData,
  selectedCustomProvider,
}) => {
  const [customProviderDetails, setCustomProviderDetails] = useState({
    address: "",
    name: "",
    accountNo: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const { user } = useContext(AppContext);
  const clientId = localStorage.getItem("client_id");

  useEffect(() => {
    if (selectedCustomProvider) {
      setCustomProviderDetails({
        address: selectedCustomProvider?.address || "",
        name: selectedCustomProvider?.name ||"",
        accountNo: selectedCustomProvider?.accountNo||"",
      });
    } else {
      initialData();
    }
  }, [selectedCustomProvider]);

  const initialData = () => {
    setCustomProviderDetails({
      address: "",
      name: "",
      accountNo: "",
    });

    setFormErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomProviderDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditData = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      await customProviderService.edit(selectedCustomProvider._id, {
        ...customProviderDetails,
      });
      onAddData && onAddData();
      toast.success("Custom Provider updated successfully!");
      initialData();
    } catch (e) {
      toast.error("Failed to update Custom Provider.");
      console.error(e);
    }
    handleClose();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      await customProviderService.create({
        ...customProviderDetails,
      });

      onAddData && onAddData();
      toast.success("Custom Provider created successfully!");
      handleClose();
      initialData();
    } catch (e) {
      toast.error("Failed to create Custom Provider.");
      console.error(e);
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!customProviderDetails.address) {
      errors.address = "address is required.";
    }
    if (!customProviderDetails.name) {
      errors.name = " Name is required.";
    }
    if (!customProviderDetails.accountNo) {
      errors.accountNo = "Account No is required.";
    } else if (customProviderDetails.accountNo < 1) {
      errors.accountNo = "Account No Should not be negative";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        initialData();
      }}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle
          id="form-dialog-title"
          style={{
            fontFamily: "Poppins",
            fontSize: 24,
            fontWeight: 500,
            color: "#000000",
          }}
        >
          {selectedCustomProvider ? "Edit" : "Create"} Custom Provider
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Name
            </CustomTypography>
            <TextField
              name="name"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={customProviderDetails.name}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              error={!!formErrors.name}
              helperText={formErrors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Account No
            </CustomTypography>
            <TextField
              name="accountNo"
              type="number"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={customProviderDetails.accountNo}
              onChange={handleInputChange}
              inputProps={{ min: 0 }}
              fullWidth
              margin="dense"
              error={!!formErrors.accountNo}
              helperText={formErrors.accountNo}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Address
            </CustomTypography>
            <TextField
              name="address"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={customProviderDetails.address}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              error={!!formErrors.address}
              helperText={formErrors.address}
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
        <Button
          style={{
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
            color: "#007DFF",
            textTransform: "none",
          }}
          variant="outlined"
          onClick={() => {
            handleClose();
            initialData();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={(e) =>
            selectedCustomProvider ? handleEditData(e) : handleSave(e)
          }
          color="primary"
          variant="contained"
          style={{
            color: "#FFFFFF",
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
            width: 100,
            textTransform: "none",
          }}
        >
          {selectedCustomProvider ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomProviderModal;

import React, { useEffect, useState, useContext } from "react";
import DashboardContainer from "../../components/dashboard-container";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Checkbox,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TablePagination,
  IconButton,
} from "@mui/material";
import { productService } from "../../utils/services/productService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomTypography from "../../globalStyles/fonts";
import { useNavigate, useParams } from "react-router-dom";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

export default function ImportProduct({}) {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [dataCount, setDataCount] = useState(0);
  const defaultImage = "/product.png";
  const navigate = useNavigate();

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const fetchTempProductData = async () => {
    try {
      const skip = page * rowsPerPage;
      const response = await productService.fetchTempProduct(
        rowsPerPage,
        skip,
        id,
        searchValue
      );
      setData(response.data);
      const preSelectedItems = response.data
        .filter((product) => product.exists)
        .map((product) => product._id);
      setSelectedItems(preSelectedItems);
      setDataCount(response.total);
    } catch (error) {
      console.error("Fetch failed:", error);
    }
  };

  useEffect(() => {
    fetchTempProductData();
  }, [page, rowsPerPage, searchValue]);

  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_product.png"
        alt="No data available"
        style={{ objectFit: "cover", height: "300px" }}
      />
    </div>
  );

  const importProduct = async () => {
    setLoading(true);
    try {
      const response = await productService.importAllProduct({
        ids: selectedItems,
      });
      toast.success("Imported Successfully");
      navigate(`/product`);
    } catch (error) {
      console.error("Fetch failed:", error);
    } finally {
      setLoading(false);
    }
  };
  const discardAll = async () => {
    setLoading(true);
    try {
      const response = await productService.discardAllTempProduct({
        ids: data.map((x) => x._id),
      });
      toast.success("Discarded Successfully");
      navigate(`/product`);
    } catch (error) {
      console.error("Fetch failed:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleCheckboxClick = (event, id) => {
    const selectedIndex = selectedItems.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, id);
    } else {
      newSelected = selectedItems.filter((itemId) => itemId !== id);
    }

    setSelectedItems(newSelected);
  };

  const isSelected = (id) => selectedItems.includes(id);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const allSelected = data.map((product) => product._id);
      setSelectedItems(allSelected);
    } else {
      setSelectedItems([]);
    }
  };

  return (
    <>
      <DashboardContainer
        onSearchData={(x) => {
          if (x.trim()) {
            setSearchValue(x);
          } else {
            setSearchValue("");
          }
        }}
        hideUploadButton={true}
        importProduct
        title={"Import"}
        onClickDiscardAll={discardAll}
        onClickAddButton={importProduct}
        searchBarPlaceholder={"Search by Category/Name"}
      >
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
              color: "white",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        )}
        <div
          style={{
            width: "100%",
            overflow: "hidden",
            paddingBottom: 100,
          }}
        >
          <TableContainer
            sx={{
              maxHeight: "60vh",
              overflowY: "auto",
              paddingInline: 0.4,
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                borderCollapse: "separate",
                borderSpacing: "0 10px",
                paddingBottom: 10,
              }}
            >
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderRadius: "8px",
                    boxShadow: "0px 0px 4px 0px #00000040",
                  }}
                >
                  <TableCell
                    style={{
                      backgroundColor: "#EEEEEE",
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius: "8px",
                      borderBottom: "none",
                      padding: 5,
                    }}
                  >
                    <Checkbox
                      indeterminate={
                        selectedItems.length > 0 &&
                        selectedItems.length < data.length
                      }
                      checked={
                        data.length > 0 && selectedItems.length === data.length
                      }
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#EEEEEE",
                      borderBottom: "none",
                      padding: 0,
                    }}
                  >
                    <CustomTypography
                      fontSize="14px"
                      title={true}
                      color="#000"
                      fontWeight={600}
                    >
                      Product
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#EEEEEE",
                      borderBottom: "none",
                      padding: 0,
                    }}
                  >
                    <CustomTypography
                      fontSize="14px"
                      title={true}
                      color="#000"
                      fontWeight={600}
                    >
                      {"Price (USD)"}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#EEEEEE",
                      borderBottom: "none",
                      padding: 0,
                    }}
                  >
                    <CustomTypography
                      fontSize="14px"
                      title={true}
                      color="#000"
                      fontWeight={600}
                    >
                      Weight
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#EEEEEE",
                      borderBottom: "none",
                      padding: 0,
                    }}
                  >
                    <CustomTypography
                      fontSize="14px"
                      title={true}
                      color="#000"
                      fontWeight={600}
                    >
                      SKU
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#EEEEEE",
                      borderBottom: "none",
                      padding: 0,
                    }}
                  >
                    <CustomTypography
                      fontSize="14px"
                      title={true}
                      color="#000"
                      fontWeight={600}
                    >
                      Category
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#EEEEEE",
                      borderBottom: "none",
                      padding: 0,
                    }}
                  >
                    <CustomTypography
                      fontSize="14px"
                      title={true}
                      color="#000"
                      fontWeight={600}
                    >
                      Status
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#EEEEEE",
                      borderBottom: "none",
                      padding: 0,
                    }}
                  >
                    <CustomTypography
                      fontSize="14px"
                      title={true}
                      color="#000"
                      fontWeight={600}
                    >
                      Supplier
                    </CustomTypography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} style={{ borderBottom: "none" }}>
                      <NoDataImage />
                    </TableCell>
                  </TableRow>
                ) : (
                  data?.map((product, index) => {
                    const isItemSelected = isSelected(product._id);
                    return (
                      <TableRow
                        key={index}
                        style={{
                          marginBottom: 10,
                          backgroundColor: "#fff",
                          borderRadius: 8,
                          boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
                          border: "none",
                          padding: 0,
                          "&:last-child td, &:last-child th": {
                            border: "none",
                          },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            borderBottom: "none",
                            borderTopLeftRadius: "8px",
                            borderBottomLeftRadius: "8px",
                            padding: 5,
                          }}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) =>
                              handleCheckboxClick(event, product._id)
                            }
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            borderBottom: "none",
                            display: "flex",
                            alignItems: "center",
                            padding: 0,
                            // width: 200,
                            maxWidth: "150px",
                          }}
                        >
                          <img
                            src={
                              product.images?.find((x) => x.position == 1)
                                ?.src || defaultImage
                            }
                            alt={product.name}
                            style={{
                              width: 30,
                              height: 30,
                              objectFit: "cover",
                              marginRight: 10,
                              marginTop: 10,
                            }}
                          />

                          <CustomTypography
                            style={{ marginTop: 1 }}
                            fontSize="13px"
                            color="#333333"
                            fontWeight={400}
                          >
                            {product.name}
                          </CustomTypography>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ borderBottom: "none", padding: 0 }}
                        >
                          <CustomTypography
                            fontSize="13px"
                            color="#333333"
                            fontWeight={400}
                          >
                            {product.details?.variants[0]?.price}
                          </CustomTypography>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ borderBottom: "none", padding: 0 }}
                        >
                          <CustomTypography
                            fontSize="13px"
                            color="#333333"
                            fontWeight={400}
                          >
                            {product.details?.variants[0]?.weight}
                          </CustomTypography>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ borderBottom: "none", padding: 0 }}
                        >
                          <CustomTypography
                            fontSize="13px"
                            color="#333333"
                            fontWeight={400}
                          >
                            {product.details?.variants[0]?.sku}
                          </CustomTypography>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ borderBottom: "none", padding: 0 }}
                        >
                          <CustomTypography
                            fontSize="13px"
                            color="#333333"
                            fontWeight={400}
                          >
                            {product.product_type}
                          </CustomTypography>
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          style={{ borderBottom: "none", padding: 0 }}
                        >
                          {product.details.status === "active" ||
                          product.details.status === "Active" ? (
                            <CustomTypography
                              fontSize="12px"
                              // color="#007613"
                              fontWeight={400}
                            >
                              <span
                                style={{
                                  // backgroundColor: "#CBFFD3",
                                  padding: "5px 10px",
                                  // borderColor: "#007613",
                                  display: "inline-block",
                                  // borderWidth: 2,
                                }}
                              >
                                {product.details?.status}
                              </span>
                            </CustomTypography>
                          ) : (
                            <CustomTypography
                              fontSize="12px"
                              // color="#626262"
                              fontWeight={400}
                            >
                              <span
                                style={{
                                  // backgroundColor: "#E1E1E1",
                                  padding: "5px 10px",
                                  // borderColor: "#747474",
                                  display: "inline-block",
                                  // borderWidth: 2,
                                }}
                              >
                                {product.details?.status}
                              </span>
                            </CustomTypography>
                          )}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ borderBottom: "none", padding: 0 }}
                        >
                          <CustomTypography
                            fontSize="13px"
                            color="#333333"
                            fontWeight={400}
                          >
                            {product.details?.vendor}
                          </CustomTypography>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <TablePagination
          slotProps={{
            actions: {
              nextButton: {
                disabled: dataCount <= rowsPerPage * (page + 1),
              },
            },
          }}
          nextIconButton={
            <IconButton>
              <KeyboardArrowRight />
            </IconButton>
          }
          backIconButton={
            <IconButton>
              <KeyboardArrowLeft />
            </IconButton>
          }
          rowsPerPageOptions={[15, 25, 45, 75, 100]}
          component="div"
          count={dataCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#ffffff",
            padding: "2px",
            borderTop: "1px solid #dddddd",
          }}
        />
        <ToastContainer />
      </DashboardContainer>
    </>
  );
}

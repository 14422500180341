import * as React from 'react'
import styles from './footer.module.css'

const Footer = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.div}>
          <div className={styles.div2}>
            <div className={styles.column}>
              <div className={styles.div3}>
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/22b3c1dd6981d54ea468af96a27727d3479a8b21e50fcd55e5a6cec2ebfb7226?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/22b3c1dd6981d54ea468af96a27727d3479a8b21e50fcd55e5a6cec2ebfb7226?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/22b3c1dd6981d54ea468af96a27727d3479a8b21e50fcd55e5a6cec2ebfb7226?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/22b3c1dd6981d54ea468af96a27727d3479a8b21e50fcd55e5a6cec2ebfb7226?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/22b3c1dd6981d54ea468af96a27727d3479a8b21e50fcd55e5a6cec2ebfb7226?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/22b3c1dd6981d54ea468af96a27727d3479a8b21e50fcd55e5a6cec2ebfb7226?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/22b3c1dd6981d54ea468af96a27727d3479a8b21e50fcd55e5a6cec2ebfb7226?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/22b3c1dd6981d54ea468af96a27727d3479a8b21e50fcd55e5a6cec2ebfb7226?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
                  className={styles.img}
                />
                {/* <div className={styles.description}>
                  Lörem ipsum od ohet dilogi. Bell trabel, samuligt, ohöbel utom diska. Jinesade bel när feras
                  redorade i belogi. FAR paratyp i muvåning, och pesask vyfisat. Viktiga poddradio har un mad
                  och inde.{' '}
                </div> */}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/redefinedlogistics/"
                >
                  <img loading="lazy" src="./LinkedIn.png" className={styles.img2} />
                </a>
              </div>
            </div>
            <div className={styles.column2}>
              <div className={styles.div4}>
                <div className={styles.div5}>
                  {/* <div className={styles.column3}>
                    <div className={styles.div6}>
                      <div className={styles.pages}>Pages</div>
                      <div className={styles.homeItWorkPricingBlogDemo}>
                        <div>Home it work</div>
                        <br />
                        <div>Pricing</div>
                        <br />
                        <div> Blog</div>
                        <br />
                        <div>Demo</div>
                      </div>
                    </div>
                  </div> */}
                  <div className={styles.column4}>
                    <div className={styles.div7}>
                      <div className={styles.service}>Service</div>
                      <div className={styles.shopifyWordPressUiUxDesign}>
                        <div>Dropskip</div>

                        <br />
                        <div>Technology</div>

                        {/* <br />
                        <div>UI/UX Design</div> */}
                      </div>
                    </div>
                  </div>
                  <div className={styles.column5}>
                    <div className={styles.div8}>
                      <div className={styles.contact}>Contact</div>
                      <div className={styles.div9}>
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/78fd92d7b7e14478fc4a9ea8b975f95561303208b1056481a0b3b18b15b41cc9?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
                          className={styles.img3}
                        />
                        <div className={styles.number}>(406) 555-0120</div>
                      </div>
                      <div className={styles.div10}>
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/fd667b1a7e236562afcd6289e131637733b3a7fed51d6c32520e2a18b08be4b6?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
                          className={styles.img4}
                        />
                        <div className={styles.mangcoding123GmailCom}>mangcoding123@gmail.com</div>
                      </div>
                      <div className={styles.div11}>
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/959062b7689ab72483054f9d052bc8d0c558fe20f8de9c3f972de19aa5de5932?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
                          className={styles.img5}
                        />
                        <div style={{ textAlign: 'left' }}>
                          2972 Westheimer Rd. Santa Ana, Illinois 85486{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: '70vw', height: 2, backgroundColor: '#FFF', marginTop: 50 }}></div>
        {/* <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/4aa890031d4339bf4902de8dae757569e72b75ca596b50751caf5d4f34c9e14e?placeholderIfAbsent=true&apiKey=9630cf04d98747ca9cd138c94d8172e2"
          className={styles.img6}
        /> */}
        <div className={styles.AllRightsReserved}>© 2024 Redefined Logistics | All rights reserved.</div>
      </div>
    </>
  )
}
export default Footer

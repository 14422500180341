import React, { useEffect, useState, useContext } from "react";
import DashboardContainer from "../../components/dashboard-container";
import AddModal from "../../components/add-modal";
import { zoneService } from "../../utils/services/zoneService";
import ZoneListContainer from "../../components/zone-port-list-container";
import TablePagination from "@mui/material/TablePagination";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Autocomplete,
  Box,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { IconButton } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import AppContext from "../../components/context/context";
import { clientService } from "../../utils/services/clientService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Zone() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [name, setName] = useState("");
  const [zoneData, setZoneData] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [editedName, setEditedName] = useState("");
  const [zoneNumber, setZoneNumber] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [dataCount, setDataCount] = useState(rowsPerPage);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState({ name: "" });
  const [clientFilter, setClientFilter] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [clientData, setClientData] = useState([]);
  const [viewMode, setViewMode] = useState("list");

  const clientId = localStorage.getItem("client_id");
  const { user } = useContext(AppContext);
  const fetchClientData = async () => {
    try {
      const response = await clientService.fetch();
      setClientData(response.data);
    } catch (error) {
      console.error("Fetch failed:", error);
      // Handle fetch failure
    }
  };
  useEffect(() => {
    fetchClientData();
  }, []);

  const handleClientFilterChange = (event, newValue) => {
    setClientFilter(newValue);
    setPage(0);
  };

  const validateForm = () => {
    const errors = {};
    if (!name) {
      errors.name = "Zone name is required";
    }
    if (!zoneNumber) {
      errors.zoneNumber = "Zone number is required";
    } else if (zoneNumber < 0) {
      errors.zoneNumber = "Zone number should not be negative";
    }
    // if (!clientId && !selectedClient?.name) {
    //   errors.selectedClient = "Please select Client";
    // }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleEditClick = (e) => {
    setSelectedZone(e);
    setEditedName(e.name);
    setZoneNumber(e.zone_number);
    //  setSelectedClient({ name: e.client_id?.name, _id: e.client_id._id });
  };

  const fetchZoneData = async () => {
    setLoading(true);
    try {
      const skip = page * rowsPerPage;
      const response = await zoneService.fetch(
        rowsPerPage,
        skip,
        clientId ? clientId : clientFilter?._id,
        searchValue
      );
      setZoneData(response.data);
      setDataCount(response?.total);
    } catch (error) {
      console.error("Fetch failed:", error);
      // Handle fetch failure
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchZoneData();
  }, [page, rowsPerPage, clientFilter, searchValue]);

  const createZone = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      setLoading(true);
      const client_id = clientId ? clientId : selectedClient?._id;

      const response = await zoneService.create(name, zoneNumber, client_id);
      toast.success("Zone Created Successfully!");
      fetchZoneData();
      setShowAddModal(false);
      setName("");
      setZoneNumber("");
    } catch (error) {
      console.error("Create failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const onDeleteSuccess = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await zoneService.delete(selectedZone._id);
      fetchZoneData();
      toast.success("Zone Deleted successfully!");
    } catch (error) {
      console.error("Delete failed:", error);
      // Handle delete failure
    } finally {
      setLoading(false);
    }
  };

  const validateEditForm = () => {
    const errors = {};
    if (!editedName) {
      errors.name = "Zone name is required";
    }
    if (!zoneNumber) {
      errors.zoneNumber = "Zone number is required";
    } else if (zoneNumber < 0) {
      errors.zoneNumber = "Zone number should not be negative";
    }
    // if (!clientId && !selectedClient) {
    //   errors.client = "Please select Client";
    // }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleEditData = async (e) => {
    e.preventDefault();
    if (!validateEditForm()) return;
    try {
      setLoading(true);
      const client_id = clientId ? clientId : selectedClient?._id;
      const response = await zoneService.edit(
        editedName,
        zoneNumber,
        client_id,
        selectedZone._id
      );
      toast.success("Zone Edited successfully!");
      fetchZoneData();
      setFormErrors({});
      setOpenModal(false);
    } catch (error) {
      console.error("Edit failed:", error);
    } finally {
      setLoading(false);
    }
  };
  const NoDataImage = () => (
    <div
      style={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        src="no_data_zone.png"
        alt="No data available"
        style={{ objectFit: "cover", height: "340px" }}
      />
    </div>
  );

  return (
    <DashboardContainer
      onSearchData={(p) => {
        setSearchValue(p);
        setPage(0);
      }}
      onClickAddButton={() => {
        setShowAddModal(true);
        setFormErrors({});
        setZoneNumber("");
      }}
      hideUploadButton={true}
      header={"Zone List"}
      onSelectClient={(e) => {
        setClientFilter(e);
      }}
      selectedClient={selectedClient?.name}
      title={" + Add Zone"}
      setViewMode={setViewMode}
      hideFilterClient
    >
      <AddModal
        onSelectClient={(e) => setSelectedClient(e)}
        onChangeZoneNumber={(e) => setZoneNumber(e)}
        zone
        title={"Zone"}
        onClickAdd={(e) => {
          createZone(e);
        }}
        onChangeName={(e) => setName(e)}
        handleClose={() => {
          setShowAddModal(false);
          setEditedName("");
          setName("");
          setZoneNumber("");
          setFormErrors({});
          setSelectedClient("");
        }}
        open={showAddModal}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      ></AddModal>
      {/* <Container sx={{ minWidth: "100%" }}>
        {user.role === "admin" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              marginRight: 15,
            }}
          >
            <Autocomplete
              getOptionLabel={(option) => option.name}
              value={clientFilter}
              onChange={handleClientFilterChange}
              options={clientData}
              renderInput={(params) => (
                <TextField {...params} label="Select Client" size="small" />
              )}
              style={{
                marginBottom: "16px",
                marginInline: 5,
                marginTop: 10,
                minWidth: 200,
              }}
            />
          </div>
        ) : (
          <></>
        )}
      </Container> */}
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            color: "white",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      {zoneData.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <NoDataImage />
        </div>
      ) : (
        <>
          <ZoneListContainer
            onSelectClient={(e) => {
              setSelectedClient(e);
            }}
            selectedClient={selectedClient?.name}
            onChangeZoneNumber={(e) => setZoneNumber(e)}
            zoneNum={zoneNumber}
            zone
            handleAddData={(e) => handleEditData(e)}
            onDeleteData={(e) => onDeleteSuccess(e)}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            openModal={openModal}
            setOpenModal={setOpenModal}
            handleDeleteClick={(e) => setSelectedZone(e)}
            selectedData={selectedZone}
            listName={"Zone"}
            setValue={(e) => setEditedName(e)}
            value={editedName}
            handleEditClick={(e) => handleEditClick(e)}
            data={zoneData}
            onFilterClient={(e) => setClientFilter(e)}
            viewMode={viewMode}
            deleteTitle={`Are you sure you want to delete ${selectedZone?.name} ?`}
          ></ZoneListContainer>
        </>
      )}
      <TablePagination
        slotProps={{
          actions: {
            nextButton: {
              disabled: dataCount < rowsPerPage ? true : false,
            },
          },
        }}
        nextIconButton={
          <IconButton>
            <KeyboardArrowRight />
          </IconButton>
        }
        backIconButton={
          <IconButton>
            <KeyboardArrowLeft />
          </IconButton>
        }
        rowsPerPageOptions={[15, 25, 45, 75, 100]}
        component="div"
        count={dataCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#ffffff",
          padding: "2px",
          borderTop: "1px solid #dddddd",
        }}
      />
      <ToastContainer />
    </DashboardContainer>
  );
}

import React, { useEffect, useState } from 'react'
import {
  Grid,
  TextField,
  Typography,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  IconButton,
  Autocomplete,
  Tooltip,
  FormControl,
  FormHelperText
} from '@mui/material'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import CancelIcon from '@mui/icons-material/Cancel'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import config from '../../config'
import CloseIcon from '@mui/icons-material/Close'
import axios from 'axios'
import { set } from 'date-fns'
import { wmsService } from '../../utils/services/wmsProvider'
import { toast } from 'react-toastify'
import CustomTypography from '../../globalStyles/fonts'
import AddPurchaseOrderModal from '../po-modal'
import { purchaseOrderService } from '../../utils/services/purchaseOrderService'
import { isMobile } from '../../globalStyles/mobile'

const defaultImage = '/product.png'

const ProductDetailsModal = ({ product, open, onClose }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [provider, setProvider] = useState('')
  const [stockData, setStockData] = useState({
    ItemNumber: '',
    PKG: '',
    Unit: 'CTN',
    InnerPKG: '',
    InnerUnit: '',
    UnitCurrency: '',
    Description: ''
  })
  const [openPoModal, setOpenPoModal] = useState(false)
  const [errors, setErrors] = useState({})
  const [poData, setPoData] = useState([])
  const handleRowClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index)
  }
  let dummyTrackingDetails = [
    { label: 'Created', sl: 1 },
    { label: 'Accepted', sl: 2 },
    { label: 'Ready to Ship', sl: 3 },
    { label: 'Shipped', sl: 4 },
    { label: 'Received', sl: 5 },
    { label: 'Stocked', sl: 6 }
  ]
  const [expandedRow, setExpandedRow] = useState(null)
  // const rows = [
  //   {
  //     po: '12345',
  //     supplier: 'Supplier A',
  //     date: '2024-07-23',
  //     status: 'Shipped',
  //     action: 'View'
  //   },
  //   {
  //     po: '67890',
  //     supplier: 'Supplier B',
  //     date: '2024-07-22',
  //     status: 'Shipped',
  //     action: 'View'
  //   },
  //   {
  //     po: '54321',
  //     supplier: 'Supplier C',
  //     date: '2024-07-21',
  //     status: 'Shipped',
  //     action: 'View'
  //   }
  // ]

  const fetchProductPo = async () => {
    try {
      let response = await purchaseOrderService.fetch('', '', product._id)
      setPoData(response.data)
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(() => {
    if (product) {
      fetchProductPo()
    }
  }, [product])
  const [wmsProviders, setWMSProviders] = useState([])
  useEffect(() => {
    if (product) fetchWMSProviders()
  }, [product])
  const fetchWMSProviders = async () => {
    try {
      let res = await wmsService.fetch(product.client_id._id)
      setWMSProviders(res.data)
    } catch (e) {
      console.error(e)
    }
  }

  const fetchImage = (image) => {
    if (image && image.image) {
      return `${config.baseURL}/fetch-file/${image.image}`
    }
    return defaultImage
  }

  const handleOpenDialog = (variant) => {
    setStockData({
      ItemNumber: variant.sku,
      PKG: '',
      Unit: 'CTN',
      InnerPKG: '',
      InnerUnit: '',
      UnitCurrency: product.currency || 'USD',
      Description: product.description
    })
    setProvider(null)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setStockData({})
    setErrors({})
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setStockData((prevData) => ({
      ...prevData,
      [name]: value
    }))
  }

  const validate = () => {
    const newErrors = {}
    if (!stockData.PKG) newErrors.PKG = 'PKG is required.'
    else if (stockData.PKG < 0) newErrors.PKG = 'PKG must be greater than 0.'
    if (!stockData.InnerPKG) newErrors.InnerPKG = 'Inner PKG is required.'
    else if (stockData.InnerPKG < 0) newErrors.InnerPKG = 'Inner PKG must be greater than 0.'
    if (!stockData.InnerUnit) newErrors.InnerUnit = 'Inner Unit is required.'
    else if (stockData.InnerUnit < 0) newErrors.InnerUnit = 'Inner Unit must be greater than 0'
    if (!stockData.UnitPrice) newErrors.UnitPrice = 'Unit Price is required.'
    else if (stockData.UnitPrice < 0) newErrors.UnitPrice = 'Unit Price must be greater than 0'
    if (!provider) newErrors.provider = 'Provider is required.'
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleAddOrUpdateStock = async () => {
    if (validate()) {
      try {
        await axios.post(`${config.warehouseBaseURL}/stock-in`, {
          client_id: product.client_id,
          stockData,
          provider
        })
        handleCloseDialog()
        toast.success('Stock-in request added successfully!')
      } catch (error) {
        console.error('Error saving stock:', error)
      }
    }
  }
  const checkIfAllDataPresent = (variant) => {
    if (
      variant.width &&
      variant.width_unit &&
      variant.height &&
      variant.height_unit &&
      variant.weight &&
      variant.weight_unit &&
      variant.length &&
      variant.length_unit
    ) {
      return false
    } else {
      return true
    }
  }
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>{'Product Details'}</DialogTitle>
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider style={{ marginBottom: 10 }} />
        <DialogContent
          sx={{
            overflowY: 'auto',
            '&::WebkitScrollbar': {
              width: '7px'
            },
            '&::WebkitScrollbar-track': {
              backgroundColor: '#D9D9D9',
              borderRadius: 14
            },
            '&::WebkitScrollbar-thumb': {
              backgroundColor: '#273894',
              borderRadius: '6px',
              '&:hover': {
                backgroundColor: '#555'
              }
            }
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography className="form-lable-style">Title</Typography>
              <TextField
                size="small"
                autoFocus
                margin="dense"
                variant="outlined"
                type="text"
                fullWidth
                value={product?.name || ''}
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="form-lable-style">Category</Typography>
              <TextField
                size="small"
                margin="dense"
                placeholder="Type"
                type="text"
                fullWidth
                value={product?.product_type || ''}
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography className="form-lable-style">Description</Typography>
              <ReactQuill
                theme="snow"
                value={product?.description || ''}
                readOnly
                style={{
                  height: isMobile() ? 70 : '100px',
                  marginBottom: '50px'
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} sx={{ marginTop: isMobile() ? 5 : 0 }}>
              <Typography className="form-lable-style">Media</Typography>
              <Box
                sx={{
                  width: '100%',
                  borderWidth: 1,
                  minHeight: 100,
                  borderColor: '#D1D1D1',
                  borderRadius: 4,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mt: { xs: 2, sm: 1 }
                }}
              >
                {product?.images?.length > 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      mt: 2,
                      m: 3,
                      overflowX: 'auto',
                      maxHeight: 250,
                      border: '1px solid #000',
                      padding: '10px',
                      borderRadius: 1,
                      minWidth: { xs: 200, sm: 300 },
                      justifyContent: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                        mt: 2
                      }}
                    >
                      {product?.images?.map((image, index) => (
                        <Box
                          key={index}
                          display="flex"
                          alignItems="center"
                          mb={2}
                          mr={2}
                          p={1}
                          border={1}
                          borderRadius={4}
                          height={150}
                          ml={2}
                          flexDirection="row"
                          flexWrap="wrap"
                        >
                          <img
                            src={fetchImage(image)}
                            alt={`Uploaded Image ${index}`}
                            style={{
                              maxWidth: '100px',
                              maxHeight: '100px',
                              marginRight: '10px'
                            }}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography className="form-lable-style">Currency</Typography>
              <TextField
                size="small"
                margin="dense"
                placeholder="Currency"
                type="text"
                fullWidth
                value={product?.currency || ''}
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography className="form-lable-style">Status</Typography>
              <TextField
                size="small"
                margin="dense"
                placeholder="Status"
                type="text"
                fullWidth
                value={product?.status || ''}
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography className="form-lable-style">Vendor</Typography>
              <TextField
                size="small"
                margin="dense"
                placeholder="Enter Vendor"
                type="text"
                fullWidth
                value={product?.vendor?.name || ''}
                InputProps={{
                  readOnly: true,
                  style: { color: '#000', backgroundColor: '#f0f0f0' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography className="form-lable-style">PO Status</Typography>
              <TableContainer
                sx={{
                  backgroundColor: '#EEEEEE',
                  width: '100%',
                  display: 'flex',
                  marginTop: 1
                }}
                component={Paper}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <CustomTypography fontSize={15} fontWeight={'600'}>
                          Po#
                        </CustomTypography>
                      </TableCell>
                      <TableCell>
                        <CustomTypography fontSize={15} fontWeight={'600'}>
                          Supplier Name
                        </CustomTypography>
                      </TableCell>
                      <TableCell>
                        <CustomTypography fontSize={15} fontWeight={'600'}>
                          Date
                        </CustomTypography>
                      </TableCell>
                      <TableCell>
                        <CustomTypography fontSize={15} fontWeight={'600'}>
                          Status
                        </CustomTypography>
                      </TableCell>
                      <TableCell>
                        <CustomTypography style={{ marginLeft: 2 }} fontSize={15} fontWeight={'600'}>
                          Action
                        </CustomTypography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {poData.map((row, index) => (
                      <React.Fragment key={index}>
                        <TableRow hover onClick={() => handleRowClick(index)}>
                          <TableCell>{row.po_id}</TableCell>
                          <TableCell>{product?.vendor?.name}</TableCell>
                          <TableCell>{row.date}</TableCell>
                          <TableCell>
                            Created
                            {row.status}
                          </TableCell>
                          <TableCell>
                            <Tooltip title="View">
                              <IconButton
                                color="primary"
                                onClick={(e) => {
                                  e.stopPropagation()
                                }}
                                sx={{
                                  backgroundColor: '#C3E1FF',
                                  width: 32,
                                  height: 32,
                                  borderRadius: 2,
                                  marginLeft: 1
                                }}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit">
                              <IconButton
                                style={{
                                  color: '#FEC50F',
                                  padding: 0,
                                  background: '#FEC50F33',
                                  height: 30,
                                  width: 30,
                                  borderRadius: 5,
                                  boxShadow: '0px 0px 4px 0px #00000040',
                                  marginTop: 3,
                                  marginLeft: 10
                                }}
                                aria-label="edit"
                                onClick={(e) => {
                                  e.stopPropagation()
                                }}
                              >
                                <BorderColorIcon sx={{ height: 20, width: 20 }} />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Cancel">
                              <IconButton
                                style={{
                                  color: '#D13438',
                                  padding: 0,
                                  background: '#D1343833',
                                  height: 30,
                                  width: 30,
                                  borderRadius: 5,
                                  marginLeft: 10,
                                  boxShadow: '0px 0px 4px 0px #00000040',
                                  marginTop: 3
                                }}
                                aria-label="delete"
                                onClick={(e) => {
                                  e.stopPropagation()
                                }}
                              >
                                <CancelIcon></CancelIcon>
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
                              <Box sx={{ height: 100 }} margin={1}>
                                <div
                                  style={{
                                    height: '100%',
                                    flexDirection: 'row',
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                  }}
                                >
                                  {dummyTrackingDetails?.map((item, index) => {
                                    return (
                                      <div
                                        style={{
                                          flexDirection: 'row',
                                          display: 'flex',
                                          width: '100%',
                                          position: 'relative'
                                        }}
                                      >
                                        <div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                4 >= item?.sl
                                                  ? row.status === 'Cancelled'
                                                    ? 'red'
                                                    : 'green'
                                                  : '#AAA7A7',
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              height: 30,
                                              width: 30,
                                              borderRadius: 100
                                            }}
                                          >
                                            <div style={{ color: '#FFF' }}>{index + 1}</div>
                                          </div>
                                          <div>
                                            <span
                                              style={{
                                                position: 'absolute',
                                                left: -15
                                              }}
                                            >
                                              {item?.label}
                                            </span>
                                          </div>
                                          <div>
                                            <span
                                              style={{
                                                position: 'absolute',
                                                left: -15,
                                                marginTop: 20
                                              }}
                                            >
                                              {item?.date}
                                            </span>
                                          </div>
                                        </div>
                                        {index + 1 === dummyTrackingDetails?.length ? (
                                          <></>
                                        ) : (
                                          <div
                                            style={{
                                              height: 1,
                                              width: '82%',
                                              position: 'absolute',
                                              left: '20%',
                                              top: 15,
                                              border:
                                                4 >= item?.sl
                                                  ? `1px dotted ${
                                                      row.status === 'Cancelled' ? 'red' : 'green'
                                                    }`
                                                  : '1px dotted #C0C3D7'
                                            }}
                                          ></div>
                                        )}
                                      </div>
                                    )
                                  })}
                                </div>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography className="form-lable-style">Variants</Typography>
              {product?.variants?.map((variant, index) => (
                <Card key={index} style={{ marginBottom: 16, backgroundColor: '#EEEEEE' }}>
                  <CardContent>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 20
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setOpenPoModal(true)
                        }}
                      >
                        + Create PO
                      </Button>
                      <Tooltip
                        PopperProps={{
                          modifiers: [
                            {
                              name: 'arrow',
                              enabled: true
                            },
                            {
                              name: 'offset',
                              options: {
                                offset: [0, 8]
                              }
                            }
                          ]
                        }}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: '#f5f5f5', // Tooltip background color
                              color: 'red', // Tooltip text color
                              fontSize: '14px', // Tooltip font size
                              border: '1px solid #ccc', // Optional: Border for the tooltip
                              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Optional: Shadow
                              borderRadius: '8px', // Optional: Rounded corners
                              padding: '10px',
                              width: 200,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center' // Optional: Padding
                            }
                          },
                          arrow: {
                            sx: {
                              color: '#f5f5f5' // Arrow color should match the tooltip background
                            }
                          }
                        }}
                        title={checkIfAllDataPresent(variant) ? 'Please fill all the fields' : ''}
                      >
                        <span>
                          <Button
                            disabled={checkIfAllDataPresent(variant)}
                            variant="contained"
                            color="primary"
                            onClick={() => handleOpenDialog(variant)}
                          >
                            + Create ASN
                          </Button>
                        </span>
                      </Tooltip>
                    </div>

                    <Grid sx={{ marginTop: 2 }} container spacing={2}>
                      <Grid item xs={12} sm={3}>
                        <Typography className="form-lable-style">Size</Typography>
                        <TextField
                          type="text"
                          size="small"
                          placeholder="Size"
                          value={variant?.option || ''}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            style: {
                              color: '#000',
                              backgroundColor: '#f0f0f0'
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Typography className="form-lable-style">Price</Typography>
                        <TextField
                          type="number"
                          size="small"
                          placeholder="Price"
                          value={variant?.price || ''}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            style: {
                              color: '#000',
                              backgroundColor: '#f0f0f0'
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Typography className="form-lable-style">Weight</Typography>
                        <TextField
                          type="number"
                          size="small"
                          placeholder="Weight"
                          value={variant.weight || ''}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            style: {
                              color: '#000',
                              backgroundColor: '#f0f0f0'
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Typography className="form-lable-style">Weight Unit</Typography>
                        <TextField
                          type="text"
                          size="small"
                          placeholder="Enter Weight Unit"
                          value={variant?.weight_unit || ''}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            style: {
                              color: '#000',
                              backgroundColor: '#f0f0f0'
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Typography className="form-lable-style">Height</Typography>
                        <TextField
                          type="number"
                          size="small"
                          placeholder="Height"
                          value={variant.height || ''}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            style: {
                              color: '#000',
                              backgroundColor: '#f0f0f0'
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Typography className="form-lable-style">Height Unit</Typography>
                        <TextField
                          type="text"
                          size="small"
                          placeholder="Enter height Unit"
                          value={variant?.height_unit || ''}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            style: {
                              color: '#000',
                              backgroundColor: '#f0f0f0'
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Typography className="form-lable-style">Width</Typography>
                        <TextField
                          type="number"
                          size="small"
                          placeholder="Width"
                          value={variant.width || ''}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            style: {
                              color: '#000',
                              backgroundColor: '#f0f0f0'
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Typography className="form-lable-style">Width Unit</Typography>
                        <TextField
                          type="text"
                          size="small"
                          placeholder="Enter width Unit"
                          value={variant?.width_unit || ''}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            style: {
                              color: '#000',
                              backgroundColor: '#f0f0f0'
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Typography className="form-lable-style">Length</Typography>
                        <TextField
                          type="number"
                          size="small"
                          placeholder="Length"
                          value={variant.length || ''}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            style: {
                              color: '#000',
                              backgroundColor: '#f0f0f0'
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Typography className="form-lable-style">Length Unit</Typography>
                        <TextField
                          type="text"
                          size="small"
                          placeholder="Enter length Unit"
                          value={variant?.length_unit || ''}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            style: {
                              color: '#000',
                              backgroundColor: '#f0f0f0'
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Typography className="form-lable-style">SKU</Typography>
                        <TextField
                          type="text"
                          size="small"
                          placeholder="Enter SKU"
                          value={variant?.sku || ''}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            style: {
                              color: '#000',
                              backgroundColor: '#f0f0f0'
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Typography className="form-lable-style">Inventory</Typography>
                        <TextField
                          type="number"
                          size="small"
                          placeholder="Enter Inventory"
                          value={variant?.inventory || ''}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            style: {
                              color: '#000',
                              backgroundColor: '#f0f0f0'
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))}
              <Grid item xs={12} sm={12}>
                <Typography className="form-lable-style">Client</Typography>
                <TextField
                  type="text"
                  size="small"
                  margin="dense"
                  placeholder="Client Name"
                  value={product?.client_id?.name || ''}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    style: { color: '#000', backgroundColor: '#f0f0f0' }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: 10 }}>
          <Button onClick={onClose} variant="outlined" style={{ textTransform: 'none' }}>
            Cancel
          </Button>
        </DialogActions>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{'Add Stock'}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <TextField
              name="ItemNumber"
              label="Item Number"
              value={stockData.ItemNumber}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              disabled
            />
            <FormControl fullWidth margin="dense">
              <TextField
                name="PKG"
                label="PKG"
                value={stockData.PKG}
                onChange={handleInputChange}
                type="number"
                error={!!errors.PKG}
                inputProps={{ min: 0 }}
              />
              {errors.PKG && <FormHelperText error>{errors.PKG}</FormHelperText>}
            </FormControl>
            <TextField
              name="Unit"
              label="Unit"
              value={stockData.Unit}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
              disabled
            />
            <FormControl fullWidth margin="dense">
              <TextField
                name="InnerPKG"
                label="Inner PKG"
                value={stockData.InnerPKG}
                onChange={handleInputChange}
                type="number"
                error={!!errors.InnerPKG}
                inputProps={{ min: 0 }}
              />
              {errors.InnerPKG && <FormHelperText error>{errors.InnerPKG}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth margin="dense">
              <TextField
                name="InnerUnit"
                label="Inner Unit"
                value={stockData.InnerUnit}
                onChange={handleInputChange}
                type="number"
                error={!!errors.InnerUnit}
                inputProps={{ min: 0 }}
              />
              {errors.InnerUnit && <FormHelperText error>{errors.InnerUnit}</FormHelperText>}
            </FormControl>
            <TextField
              name="UnitCurrency"
              label="Unit Currency"
              value={stockData.UnitCurrency}
              onChange={handleInputChange}
              disabled
              fullWidth
              margin="dense"
            />
            <FormControl fullWidth margin="dense">
              <TextField
                name="UnitPrice"
                label="Unit Price"
                value={stockData.UnitPrice}
                onChange={handleInputChange}
                fullWidth
                margin="dense"
                type="number"
                error={!!errors.UnitPrice}
                inputProps={{ min: 0 }}
              />
              {errors.UnitPrice && <FormHelperText error>{errors.UnitPrice}</FormHelperText>}
            </FormControl>
            <TextField
              name="Description"
              label="Description"
              value={stockData.Description}
              onChange={handleInputChange}
              fullWidth
              margin="dense"
            />
            <FormControl fullWidth margin="dense">
              <Autocomplete
                size="small"
                value={provider}
                onChange={(e, v) => setProvider(v)}
                options={wmsProviders.map((x) => x.provider)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Provider"
                    placeholder="Select Provider"
                    error={!!errors.provider}
                    helperText={errors.provider}
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleAddOrUpdateStock} variant="contained" color="primary">
              {'Add Stock'}
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
      <AddPurchaseOrderModal
        product={product}
        onClose={() => setOpenPoModal(false)}
        open={openPoModal}
      ></AddPurchaseOrderModal>
    </>
  )
}

export default ProductDetailsModal

import React, { useEffect, useState, useContext } from "react";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
import CustomTypography from "../../globalStyles/fonts";
import CloseIcon from "@mui/icons-material/Close";
import AppContext from "../context/context";
import { partnerService } from "../../utils/services/partnerService";

const PartnerModal = ({
  open,
  handleClose,
  onAddData,
  selectedPartner,
}) => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const { user } = useContext(AppContext);
  const clientId = localStorage.getItem("client_id");

 
  const validateForm = () => {
    const errors = {};
    if (!name) {
      errors.name = "Name is required";
    }
    if (!password) {
      errors.password = "Password is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (selectedPartner) {
      setName(selectedPartner?.username || "");

      setPassword(selectedPartner?.password || "");
    } else {
      initialData();
    }
  }, [selectedPartner]);

  const initialData = () => {
    setName("");
    setPassword("");
    setFormErrors({});
  };

 

  const handleEditData = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      await partnerService.edit(selectedPartner._id, {
        username: name,
        password: password,
      });
      onAddData && onAddData();
      initialData();
    } catch (e) {
      console.error(e);
    }
    initialData();
    handleClose();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
    
      await partnerService.create({
        username: name,
        password: password,
      });
      onAddData && onAddData();
      handleClose();
      initialData();
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        initialData();
      }}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle
          id="form-dialog-title"
          style={{
            fontFamily: "Poppins",
            fontSize: 24,
            fontWeight: 500,
            color: "#000000",
          }}
        >
          {selectedPartner ? "Edit" : "Create"} Partner
        </DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              User Name
            </CustomTypography>
            <TextField
              name="name"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={name}
              onChange={(e) => setName(e.target.value)}
              inputProps={{ min: 0 }}
              fullWidth
              margin="dense"
              error={!!formErrors.name}
            />
            {!!formErrors.name && (
              <FormHelperText error>{formErrors.name}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTypography
              style={{
                fontSize: 14,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: "#11193F",
              }}
            >
              Password
            </CustomTypography>
            <TextField
              name="password"
              type="text"
              style={{ backgroundColor: "#FFFFFF" }}
              size="small"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              inputProps={{ min: 0 }}
              fullWidth
              margin="dense"
              error={!!formErrors.password}
            />
            {!!formErrors.password && (
              <FormHelperText error>{formErrors.password}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 15, paddingRight: 15 }}>
        <Button
          style={{
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
            color: "#007DFF",
            textTransform: "none",
          }}
          variant="outlined"
          onClick={() => {
            handleClose();
            initialData();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={(e) =>
            selectedPartner ? handleEditData(e) : handleSave(e)
          }
          color="primary"
          variant="contained"
          style={{
            color: "#FFFFFF",
            fontFamily: "Poppins",
            fontSize: 14,
            fontWeight: 600,
            width: 100,
            textTransform: "none",
          }}
        >
          {selectedPartner ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PartnerModal;

import React, { useEffect, useState } from 'react'
import ShipmentPriceModal from '../../components/shipmentPriceModal'
import DashboardContainer from '../../components/dashboard-container'
import { shippingPriceService } from '../../utils/services/shippingPriceService'
import ShipmentPriceListView from '../../components/shipment-price-list'
import { Box, CircularProgress } from '@mui/material'

const ShipmentOptions = () => {
  const [selectedShipmentPrice, setSelectedShipmentPrice] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [shipmentPriceList, setShipmentPriceList] = useState([])
  const [viewMode, setViewMode] = useState('list')
  const [searchValue, setSearchValue] = useState('')
  const [clientFilter, setClientFilter] = useState(null)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(15)
  const [dataCount, setDataCount] = useState(rowsPerPage)
  const [loading, setLoading] = useState(false)
  const clientId = localStorage.getItem('client_id')

  const fetchShipmentPrice = async () => {
    setLoading(true)
    try {
      const shipmentPrice = await shippingPriceService.fetch(
        rowsPerPage,
        page * rowsPerPage,
        clientId ? clientId : clientFilter?._id,
        searchValue
      )
      setShipmentPriceList(shipmentPrice?.data)
      setLoading(false)
      setDataCount(shipmentPrice?.total)
    } catch (error) {
      console.error('Fetch failed:', error)
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchShipmentPrice()
  }, [page, rowsPerPage, clientFilter, searchValue])

  return (
    <DashboardContainer
      hideUploadButton
      header={'Shipment Options List'}
      title={'+ Add Shipment Options'}
      onClickAddButton={() => setOpenModal(true)}
      shipmentPrice
      setViewMode={setViewMode}
      onSearchData={(x) => {
        if (x.trim()) {
          setSearchValue(x)
          setPage(0)
        } else {
          setSearchValue('')
        }
      }}
      onSelectClient={(e) => setClientFilter(e)}
    >
      <ShipmentPriceModal
        selectedShipmentPrice={selectedShipmentPrice}
        handleClose={() => {
          setOpenModal(false)
          setSelectedShipmentPrice(null)
        }}
        open={openModal}
        onAddData={fetchShipmentPrice}
      />
      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
            color: 'white'
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <ShipmentPriceListView
        dataCount={dataCount}
        page={page}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setPage={setPage}
        onDeleteData={fetchShipmentPrice}
        onEditData={(e) => {
          setSelectedShipmentPrice(e)
          setOpenModal(true)
        }}
        data={shipmentPriceList}
        viewMode={viewMode}
        selectedShipmentPrice={selectedShipmentPrice}
        setSelectedShipmentPrice={setSelectedShipmentPrice}
      />
    </DashboardContainer>
  )
}

export default ShipmentOptions

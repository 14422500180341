import React, { useState } from 'react'
import dayjs from 'dayjs'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Collapse,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  useTheme,
  Card,
  CardContent,
  Button
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import OrderDetailsModal from '../orderDetailsModal.js'
import FilterIcon from '../../globalStyles/icons/filter-icon.js'
import CustomTypography from '../../globalStyles/fonts/index.js'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteConfirmationModal from '../deleteConfirmationModal/index.js'
import { orderService } from '../../utils/services/orderService.js'
import OrderTrackingIcon from '../../globalStyles/icons/order-tracking-icon.js'
import { Link, useNavigate } from 'react-router-dom'
import AppContext from '../context/context.js'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { isMobile } from '../../globalStyles/mobile/index.js'
import WarehouseIcon from '@mui/icons-material/Warehouse'
import { warehouseOrderService } from '../../utils/services/warehouseOrderService.js'
import WarehouseOrderList from '../warehouse-order-list/index.js'
import WarehouseList from '../warehouse-order-list/index.js'
const OrderList = ({ data, onStatusFilterChange, style = {}, fetchOrderData }) => {
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [expandedRow, setExpandedRow] = useState(null)
  const [trackingDetails, setTrackingDetails] = useState({})
  const [errorData, setErrorData] = useState({})
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [filterApplied, setFilterApplied] = useState(false)
  const [edit, setEdit] = useState(false)
  const [showCancelOrderConfirmModal, setShowCancelOrderConfirmModal] = useState(false)
  const [orderTrail, setOrderTrail] = useState([])
  const [showWarehouseDataModal, setShowWarehouseDataModal] = useState(false)
  const theme = useTheme()
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleFilterClose = (status) => {
    setAnchorEl(null)
    setSelectedStatus(status)
    const filterValue = status === 'All' ? '' : status
    onStatusFilterChange(filterValue)
    setStartDate(null)
    setEndDate(null)
    setFilterApplied(true)
  }

  const handleViewClick = (order, action) => {
    setSelectedOrder(order)
    setModalOpen(true)
    if (action == 'edit') {
      setEdit(true)
    }
  }
  let dummyTrackingDetails = [
    { label: 'Ordered', sl: 1 },
    { label: 'Accepted', sl: 2 },
    { label: 'Confirmed', sl: 3 },
    { label: 'Fulfilled', sl: 4 },
    { label: 'Shipped', sl: 5 },
    { label: 'In-transit', sl: 6 },
    { label: 'Out for Delivery', sl: 7 },
    { label: 'Delivered', sl: 8 }
  ]
  const handleRowClick = async (order) => {
    const orderId = order.order_id
    if (expandedRow === orderId) {
      setExpandedRow(null)
    } else {
      if (!trackingDetails[orderId]) {
        // Simulate fetching tracking details with dummy data

        if (order.error) {
          setErrorData({
            error: true,
            message: order.message
          })
        } else {
          setErrorData({})
          setTrackingDetails((prevDetails) => ({
            ...prevDetails,
            [orderId]: dummyTrackingDetails
          }))
        }
        // Replace with actual call to orderService.trackOrder(orderId);
      }
      setExpandedRow(orderId)
    }
  }
  console.log({ ...selectedOrder })
  const cancelOrder = async () => {
    try {
      const response = await orderService.cancel({
        ...selectedOrder
      })
      if (response.status === 'Cancelled') {
        console.log(response.status)
        toast.success('Order cancelled successfully')
      }
      setShowCancelOrderConfirmModal(false)
      fetchOrderData()
    } catch (error) {
      console.error('Error canceling order:', error)
    }
  }

  const fetchOrderTrail = async (id) => {
    const response = await orderService.fetchOrderTrail(id)
    setOrderTrail(response.data)
  }
  console.log(orderTrail)
  const getFormattedDate = (date) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    const d = new Date(date)
    const hours = String(d.getHours()).padStart(2, '0')
    const minutes = String(d.getMinutes()).padStart(2, '0')
    const time = `${hours}:${minutes}`
    const dayName = days[d.getDay()]
    const day = d.getDate()
    const monthName = months[d.getMonth()]
    const dateStr = `${dayName} ${day} ${monthName}`

    return { time, dateStr }
  }
  const MobileCardView = ({ data }) => {
    return (
      <Box sx={{ padding: 2 }}>
        {data?.map((row, index) => (
          <Card key={index} sx={{ marginBottom: 2, cursor: 'pointer' }} onClick={() => {}}>
            <CardContent>
              <div style={{ width: '100%' }}>
                <Typography variant="h6" gutterBottom>
                  Order ID: {row.order_id}
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  marginLeft={2}
                  justifyContent="flex-start"
                >
                  {' '}
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography>Client : {row.client_id.name}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography>
                      Customer : {`${row.customer?.first_name || ''} ${row.customer?.last_name || ''}`}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography>Order Date : {dayjs(row.order_date).format('YYYY-MM-DD')}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography>Shipment ID : {new Set(row?.shipments?.map((x) => x.shipment))}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography>Total Amount : ${row.total_line_items_price}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography>Status : {row.status}</Typography>
                  </Box>
                  <Box sx={{ marginTop: 2 }}></Box>
                </Box>
                <Box mt={2} justifyContent={'center'} display="flex">
                  <Tooltip title="Warehouse Order">
                    <IconButton
                      color="primary"
                      onClick={(e) => {}}
                      sx={{
                        backgroundColor: '#FFDEC3',
                        width: 32,
                        height: 32,
                        borderRadius: 2
                      }}
                    >
                      <WarehouseIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Track">
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        localStorage.setItem('path', `/track-order/${row?._id}`)
                        navigate(`/track-order/${row?._id}`)
                      }}
                      sx={{
                        backgroundColor: '#FFDEC3',
                        width: 32,
                        height: 32,
                        borderRadius: 2
                      }}
                    >
                      <OrderTrackingIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="View">
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleViewClick(row, 'view')
                      }}
                      sx={{
                        backgroundColor: '#C3E1FF',
                        width: 32,
                        height: 32,
                        borderRadius: 2,
                        marginLeft: 1
                      }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit">
                    <IconButton
                      style={{
                        color: '#FEC50F',
                        padding: 0,
                        background: '#FEC50F33',
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        boxShadow: '0px 0px 4px 0px #00000040',
                        marginTop: 3,
                        marginLeft: 10
                      }}
                      aria-label="edit"
                      onClick={(e) => {
                        e.stopPropagation()

                        handleViewClick(row, 'edit')
                      }}
                    >
                      <BorderColorIcon sx={{ height: 20, width: 20 }} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Cancel">
                    <IconButton
                      disabled={!['Ordered', 'Accepted'].includes(row.status)}
                      style={{
                        color: ['Ordered', 'Accepted'].includes(row.status) ? '#D13438' : 'grey',
                        padding: 0,
                        background: ['Ordered', 'Accepted'].includes(row.status) ? '#D1343833' : '#FFF',
                        height: 30,
                        width: 30,
                        borderRadius: 5,
                        marginLeft: 10,
                        boxShadow: '0px 0px 4px 0px #00000040',
                        marginTop: 3
                      }}
                      aria-label="delete"
                      onClick={(e) => {
                        e.stopPropagation()
                        setSelectedOrder(row)
                        setShowCancelOrderConfirmModal(true)
                      }}
                    >
                      <CancelIcon></CancelIcon>
                    </IconButton>
                  </Tooltip>
                </Box>
              </div>
            </CardContent>
          </Card>
        ))}
      </Box>
    )
  }
  return (
    <>
      <div
        style={{
          width: '100%',
          overflow: isMobile() ? 'auto' : 'hidden',
          height: window.innerHeight < 650 ? '80vh' : 'auto'
        }}
      >
        {isMobile() ? (
          <MobileCardView data={data} />
        ) : (
          <TableContainer
            // className="custom-container"
            sx={{
              height: '65vh',
              overflowY: 'scroll',
              paddingInline: 0.4,
              paddingBottom: 5,
              paddingRight: '16px',
              paddingBottom: window.innerHeight < 650 ? 8 : 1,
              ...style
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{ borderCollapse: 'separate', borderSpacing: '0 6px' }}
              sx={{ tableLayout: 'fixed' }}
            >
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: '#EEEEEE',
                    borderRadius: '8px',
                    boxShadow: '0px 0px 4px 0px #00000040'
                  }}
                >
                  <TableCell
                    style={{
                      backgroundColor: '#EEEEEE',
                      borderTopLeftRadius: '8px',
                      borderBottomLeftRadius: '8px',
                      borderBottom: 'none',
                      textAlign: 'center',
                      padding: 10
                    }}
                  >
                    <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                      Order ID
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: '#EEEEEE',
                      borderBottom: 'none',
                      textAlign: 'center',
                      padding: 5
                    }}
                  >
                    <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                      Client Name
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: '#EEEEEE',
                      borderBottom: 'none',
                      textAlign: 'center',
                      padding: 5
                    }}
                  >
                    <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                      Customer Name
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: '#EEEEEE',
                      borderBottom: 'none',
                      textAlign: 'center',
                      padding: 5
                    }}
                  >
                    <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                      Order Date
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: '#EEEEEE',
                      borderBottom: 'none',
                      textAlign: 'center',
                      padding: 5
                    }}
                  >
                    <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                      Shipment ID
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: '#EEEEEE',
                      borderBottom: 'none',
                      textAlign: 'center',
                      padding: 5
                    }}
                  >
                    <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                      {'Total Amount(USD)'}
                    </CustomTypography>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: '#EEEEEE',
                      borderBottom: 'none',
                      padding: 5
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                        Status
                      </CustomTypography>
                      <IconButton aria-label="filter" size="small" onClick={handleFilterClick}>
                        <FilterIcon />
                      </IconButton>
                      <Menu anchorEl={anchorEl} open={open} onClose={() => handleFilterClose(selectedStatus)}>
                        {[
                          'All',
                          'Ordered',
                          'Accepted',
                          'Confirmed',
                          'Fulfilled',
                          'Shipped',
                          'Delivered',
                          'Cancelled'
                        ]?.map((x) => (
                          <MenuItem onClick={() => handleFilterClose(x)}>{x}</MenuItem>
                        ))}
                      </Menu>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: '#EEEEEE',
                      borderTopRightRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderBottom: 'none',
                      textAlign: 'center',
                      padding: 5,
                      width: 200
                    }}
                  >
                    <CustomTypography fontSize="14px" title={true} color="#000" fontWeight={600}>
                      Actions
                    </CustomTypography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row, index) => {
                  if (row.status === 'Cancelled') {
                    dummyTrackingDetails = [
                      { label: 'Ordered', sl: 1 },
                      { label: 'Cancelled', sl: 2 }
                    ]
                  } else {
                    dummyTrackingDetails = [
                      { label: 'Ordered', sl: 1 },
                      { label: 'Accepted', sl: 2 },
                      { label: 'Confirmed', sl: 3 },
                      { label: 'Fulfilled', sl: 4 },
                      { label: 'Shipped', sl: 5 },
                      { label: 'In-transit', sl: 6 },
                      { label: 'Out for Delivery', sl: 7 },
                      { label: 'Delivered', sl: 8 }
                    ]
                  }
                  let progress = dummyTrackingDetails.find((x) => x.label === row.status)
                  return (
                    <React.Fragment key={index}>
                      <TableRow
                        style={{
                          marginBottom: 10,
                          backgroundColor: '#fff',
                          borderRadius: 8,
                          boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          handleRowClick(row)
                          fetchOrderTrail(row._id)
                        }}
                      >
                        <TableCell
                          style={{
                            borderBottom: 'none',
                            borderRadius: '8px 0 0 8px',
                            cursor: 'pointer',
                            textAlign: 'center',
                            padding: 10
                          }}
                        >
                          <CustomTypography
                            fontSize="13px"
                            color={row.error ? '#ba090c' : '#333333'}
                            fontWeight={400}
                          >
                            {row.order_id}
                          </CustomTypography>
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: 'none',
                            cursor: 'pointer',
                            textAlign: 'center',
                            padding: 10
                          }}
                        >
                          <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                            {row.client_id.name}
                          </CustomTypography>
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: 'none',
                            textAlign: 'center',
                            padding: 10
                          }}
                        >
                          <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                            {`${row.customer?.first_name || ''} ${row.customer?.last_name || ''}`}
                          </CustomTypography>
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: 'none',
                            textAlign: 'center',
                            padding: 10
                          }}
                        >
                          <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                            {dayjs(row.order_date).format('YYYY-MM-DD')}
                          </CustomTypography>
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: 'none',
                            textAlign: 'center',
                            padding: 10
                          }}
                        >
                          <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                            {new Set(row?.shipments?.map((x) => x.shipment))}
                          </CustomTypography>
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: 'none',
                            textAlign: 'center',
                            padding: 0
                          }}
                        >
                          <CustomTypography fontSize="13px" color="#333333" fontWeight={400}>
                            {row.total_line_items_price}
                          </CustomTypography>
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: 'none',
                            textAlign: 'center',
                            padding: 10
                          }}
                        >
                          {row.status ? (
                            <CustomTypography fontSize="13px" fontWeight={400}>
                              <span
                                style={{
                                  // backgroundColor: '#CBFFD3',
                                  padding: '5px 10px',
                                  // borderColor: '#007613',
                                  display: 'inline-block'
                                  // borderWidth: 2,
                                }}
                              >
                                {row.status}
                              </span>
                            </CustomTypography>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell
                          style={{
                            borderBottom: 'none',
                            borderRadius: '0 8px 8px 0',
                            textAlign: 'center',
                            padding: 0
                          }}
                        >
                          <Tooltip title="Warehouse Order">
                            <IconButton
                              color="primary"
                              onClick={(e) => {
                                e.stopPropagation()
                                setShowWarehouseDataModal(true)
                                setSelectedOrder(row)
                              }}
                              sx={{
                                backgroundColor: '#FFDEC3',
                                width: 32,
                                height: 32,
                                borderRadius: 2,
                                marginRight: 1
                              }}
                            >
                              <WarehouseIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Track">
                            <IconButton
                              color="primary"
                              onClick={(e) => {
                                localStorage.setItem('path', `/track-order/${row?._id}`)
                                navigate(`/track-order/${row?._id}`)
                              }}
                              sx={{
                                backgroundColor: '#FFDEC3',
                                width: 32,
                                height: 32,
                                borderRadius: 2,
                                padding: 0
                              }}
                            >
                              <OrderTrackingIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="View">
                            <IconButton
                              color="primary"
                              onClick={(e) => {
                                e.stopPropagation()
                                handleViewClick(row, 'view')
                              }}
                              sx={{
                                backgroundColor: '#C3E1FF',
                                width: 32,
                                height: 32,
                                borderRadius: 2,
                                marginLeft: 1,
                                padding: 0
                              }}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit">
                            <IconButton
                              style={{
                                color: '#FEC50F',
                                padding: 0,
                                background: '#FEC50F33',
                                height: 30,
                                width: 30,
                                borderRadius: 5,
                                boxShadow: '0px 0px 4px 0px #00000040',
                                marginTop: 3,
                                marginLeft: 10
                              }}
                              aria-label="edit"
                              onClick={(e) => {
                                e.stopPropagation()

                                handleViewClick(row, 'edit')
                              }}
                            >
                              <BorderColorIcon sx={{ height: 20, width: 20 }} />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title={['Ordered', 'Accepted'].includes(row.status) ? 'Cancel' : ''}>
                            <IconButton
                              // disabled={
                              //   !["Ordered", "Accepted"].includes(row.status)
                              // }
                              style={{
                                color: ['Ordered', 'Accepted'].includes(row.status) ? '#D13438' : 'grey',
                                padding: 0,
                                background: ['Ordered', 'Accepted'].includes(row.status)
                                  ? '#D1343833'
                                  : '#FFF',
                                height: 30,
                                width: 30,
                                borderRadius: 5,
                                marginLeft: 10,
                                boxShadow: '0px 0px 4px 0px #00000040',
                                marginTop: 3,
                                cursor: !['Ordered', 'Accepted'].includes(row.status)
                                  ? 'not-allowed'
                                  : 'pointer'
                              }}
                              aria-label="delete"
                              onClick={(e) => {
                                e.stopPropagation()
                                console.log('huhuu')

                                if (['Ordered', 'Accepted'].includes(row.status)) {
                                  setSelectedOrder(row)
                                  setShowCancelOrderConfirmModal(true)
                                }
                              }}
                            >
                              <CancelIcon></CancelIcon>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={8} style={{ padding: 0, borderBottom: 'none' }}>
                          <Collapse in={expandedRow === row.order_id} timeout="auto" unmountOnExit>
                            <Box
                              marginBottom={6}
                              marginTop={1}
                              marginLeft={10}
                              sx={{
                                maxWidth: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                minHeight: 70
                              }}
                            >
                              <div
                                style={{
                                  height: '100%',
                                  flexDirection: 'row',
                                  display: 'flex',
                                  width: '100%',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}
                              >
                                {errorData.error && errorData.message.length ? (
                                  <Typography>{errorData.message}</Typography>
                                ) : (
                                  dummyTrackingDetails?.map((item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        style={{
                                          flexDirection: 'row',
                                          display: 'flex',
                                          width: '100%',
                                          position: 'relative'
                                        }}
                                      >
                                        <div>
                                          <div
                                            style={{
                                              backgroundColor:
                                                progress?.sl >= item?.sl
                                                  ? row.status === 'Cancelled'
                                                    ? 'red'
                                                    : 'green'
                                                  : '#AAA7A7',
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              height: 30,
                                              width: 30,
                                              borderRadius: 100
                                            }}
                                          >
                                            <div style={{ color: '#FFF' }}>{index + 1}</div>
                                          </div>
                                          <div>
                                            <span
                                              style={{
                                                position: 'absolute',
                                                left: -15
                                              }}
                                            >
                                              {item?.label}
                                            </span>
                                          </div>
                                          <div>
                                            <span
                                              style={{
                                                position: 'absolute',
                                                left: -5,
                                                marginTop: 20
                                              }}
                                            >
                                              {orderTrail.find((x) => x.status === item.label)
                                                ? getFormattedDate(
                                                    orderTrail.find((x) => x.status === item.label)?.createdAt
                                                  ).time
                                                : ''}
                                              <br />
                                              <span
                                                style={{
                                                  position: 'absolute',
                                                  right: -40,
                                                  minWidth: 100
                                                }}
                                              >
                                                {orderTrail.find((x) => x.status === item.label)
                                                  ? getFormattedDate(
                                                      orderTrail.find((x) => x.status === item.label)
                                                        ?.createdAt
                                                    ).dateStr
                                                  : ''}
                                              </span>
                                            </span>
                                          </div>
                                          {/* <div>
                                            <span
                                              style={{
                                                position: 'absolute',
                                                left: -10,
                                                marginTop: 20
                                              }}
                                            >
                                              {orderTrail.find((x) => x.status === item.label)
                                                ? getFormattedDate(
                                                    orderTrail.find((x) => x.status === item.label)?.createdAt
                                                  ).dateStr
                                                : ''}
                                            </span>
                                          </div> */}
                                        </div>
                                        {index + 1 === dummyTrackingDetails?.length ? (
                                          <></>
                                        ) : (
                                          <div
                                            style={{
                                              height: 1,
                                              width: '82%',
                                              position: 'absolute',
                                              left: '15%',
                                              top: 15,
                                              border:
                                                progress?.sl >= item?.sl
                                                  ? `1px dotted ${
                                                      row.status === 'Cancelled' ? 'red' : 'green'
                                                    }`
                                                  : '1px dotted #C0C3D7'
                                            }}
                                          ></div>
                                        )}
                                      </div>
                                    )
                                  })
                                )}
                              </div>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>

      <OrderDetailsModal
        open={modalOpen}
        edit={edit}
        fetchOrderData={fetchOrderData}
        onClose={() => {
          setModalOpen(false)
          setEdit(false)
        }}
        order={selectedOrder}
      />
      <DeleteConfirmationModal
        handleDelete={() => cancelOrder()}
        handleClose={() => setShowCancelOrderConfirmModal(false)}
        title={`Are you sure to cancel this order ${selectedOrder?.order_id}`}
        open={showCancelOrderConfirmModal}
        deleteButtonTitle={'Cancel Order'}
      ></DeleteConfirmationModal>
      <WarehouseList
        handleClose={() => setShowWarehouseDataModal(false)}
        openModal={showWarehouseDataModal}
        selectedOrder={selectedOrder}
      ></WarehouseList>
      <ToastContainer />
    </>
  )
}

export default OrderList
